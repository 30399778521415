import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import * as ControllerTratamientos from 'controllers/Tratamientos';
import { useAlert } from "react-alert";
import { evaluarForm } from "functions/formulario";

const useStyles = makeStyles(componentStyles);


function Tratamiento() {
  let { id } = useParams();
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const [tratamiento, setTratamiento] = React.useState([]);
  const [categorias, setCategorias] = React.useState([]);
  let [tratamientoActualizado, setTratamientoActualizado] = React.useState([]);
  const camposRequeridos = [{ id: 'nombre', nombre: 'Nombre' },
  { id: 'descripcion', nombre: 'Descripción' },
  { id: 'precio', nombre: 'Precio' },
  { id: 'idCategoria', nombre: 'Categoría' }];

  const peticionPut = async () => {
    const v = evaluarForm(tratamientoActualizado, camposRequeridos);
    if (v.resultado) {
      const r = await ControllerTratamientos.peticionPutTratamiento(id, tratamientoActualizado);
      if (!r.resultado) {
        alert.error("Error al modificar: " + r.mensaje);
        return;
      }
        alert.success(r.mensaje);
        history.push("/app/tratamientos/listado");
    } else {
      alert.error(v.mensaje);
    }
  }

  const peticionPost = async () => {
    const v = evaluarForm(tratamientoActualizado, camposRequeridos);
    if (v.resultado) {
      const r = await ControllerTratamientos.peticionPostTratamiento(tratamientoActualizado);
      if (!r.resultado) {
        alert.error("Error al guardar: " + r.mensaje);
        return;
      }
      alert.success(r.mensaje);
      history.push("/app/tratamientos/listado");
    } else {
      alert.error(v.mensaje);
    }
  }

  const guardarDatos = async e => {
    await setTratamientoActualizado({
      ...tratamientoActualizado,
      [e.target.name]: e.target.value
    });
  };

  async function obtenerCategorias() {
    const r = await ControllerTratamientos.peticionGetCategorias();
    if (!r.resultado) {
      alert.error("Error obteniendo categorias: " + r.mensaje);
      return;
    }
    setCategorias(r.data);
  }

  async function obtenerTratamiento() {
    if (id) {
      const r = await ControllerTratamientos.peticionGetTratamiento(id);
      if (!r.resultado) {
        alert.error("Error obteniendo tratamiento: " + r.mensaje);
        return;
      }
      setTratamiento(r.data);
      setTratamientoActualizado(r.data)
    }
  }

  useEffect(async () => {
    await obtenerCategorias();
    obtenerTratamiento();
  }, []);


  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>

          <Grid
            item
            xs={12}
            xl={8}
            justifyContent="center"
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Info de tratamiento
                      </Box>
                    </Grid>

                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <div className={classes.plLg4}>

                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Nombre*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="nombre"
                            key={tratamiento ? tratamiento.nombre : 'tratamiento.nombre'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={tratamiento ? tratamiento.nombre : ''}
                            inputProps={{maxLength: 30}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Categoría*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={tratamiento ? tratamiento.idCategoria : 0} name="idCategoria" key={tratamiento ? tratamiento.idCategoria : 'tratamiento.idCategoria'}
                            onChange={(e) => guardarDatos(e)}>
                            {categorias && categorias.map(c => {
                              return (
                                <MenuItem value={c.idCategoria} > {c.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Precio ($)*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="number"
                            name="precio"
                            key={tratamiento ? tratamiento.precio : 'tratamiento.precio'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={tratamiento ? tratamiento.precio : ''}
                            inputProps={{max: 9999999999}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel>Descripción*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            multiline
                            defaultValue={tratamiento ? tratamiento.descripcion : ''}
                            name="descripcion"
                            key={tratamiento ? tratamiento.descripcion : 'tratamiento.descripcion'}
                            onChange={(e) => guardarDatos(e)}
                            rows="4"
                            inputProps={{maxLength: 500}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/tratamientos/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => { id ? peticionPut() : peticionPost() }}
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default Tratamiento;
