import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import { estiloTabla } from "estilos/clases.js";
import * as ControllerPacientes from 'controllers/Pacientes';
import { useAlert } from "react-alert";
import Foto from "components/Formularios/Foto";
import { pasos } from "functions/pacientes";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuTabla from "components/Tabla/MenuTabla";
import Pasos from "components/Formularios/Pasos";
import { CardContent } from "@material-ui/core";
import AltaContacto from "./popup/altaContacto";
import AlertaDialogo from "components/Popups/AlertaDialogo";
import { b64aBlob } from "functions/formulario";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";

function PacienteContactos(props) {
  let { id } = useParams();
  const estilo = estiloTabla();
  const theme = useTheme();
  const alert = useAlert();
  const [itemsPasos, setItemsPasos] = React.useState([]);
  const itemActivo = 2;
  const [paciente, setPaciente] = React.useState({});
  const [contactos, setContactos] = React.useState();
  const [mostrarAltaContacto, setMostrarAltaContacto] = React.useState(false);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [idEliminar, setIdEliminar] = React.useState();
  const [avatar, setAvatar] = React.useState();

  const abrirPopUPAltaContacto = () => {
    setMostrarAltaContacto(true);
  };

  const cerrarPopUPAltaContacto = () => {
    setMostrarAltaContacto(false);
  };

  const peticionDelete = async (idContacto) => {
    cerrarModalEliminar();
    if (id) {
      const r = await ControllerPacientes.peticionDeleteContacto(id, idContacto);
      if (r.resultado) {
        obtenerContactos();
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const eliminarRegistro = (id) => {
    setIdEliminar(id);
    setModalEliminar(true);
  }

  const cerrarModalEliminar = () => {
    setIdEliminar();
    setModalEliminar(false);
  }

  const obtenerContactos = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetContactos(id);
      (r.resultado) ? setContactos(r.data) : alert.error(r.mensaje);
    }
  };

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
        obtenerContactos();
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerAvatar = async () => {
    const r = await ControllerPacientes.peticionGetPacienteAvatar(id);
    if (r.resultado) {
      if (r.data) {
        const blob = b64aBlob(r.data.base64 ? r.data.base64 : "");
        setAvatar(blob);
      }
    } else {
      alert.error(r.mensaje);
    }
  };

  const itemsMenu = [{ nombre: "Eliminar", "funcion": eliminarRegistro }];

  useLayoutEffect(() => {
    setItemsPasos(pasos(id));
    obtenerPaciente();
  }, []);

  useLayoutEffect(() => {
    if (paciente && paciente.idPaciente) {
      obtenerAvatar();
    }
  }, [paciente])

  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: estilo.containerRoot }}
      >
        <Grid container>
          <Foto avatar={avatar} />
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: estilo.gridItemRoot + " " + estilo.order2 }}
          >
            <Card
              classes={{
                root: estilo.cardRoot + " " + estilo.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {id && paciente && paciente.nombre1 ?
                          `Datos de ${paciente.nombre1} ${paciente.apellido1} (${paciente.documento})`
                          : "Datos de paciente nuevo"}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
              <Pasos items={itemsPasos} activo={itemActivo} />
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h4"
                        marginBottom="0!important"
                      >
                        Contactos de emergencia
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={abrirPopUPAltaContacto}
                        >
                          Agregar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
              ></CardHeader>
              <CardContent>
                <div className={estilo.plLg4}>
                  <TableContainer>
                    <Box
                      component={Table}
                      alignItems="center"
                      marginBottom="0!important"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}></TableCell>
                          <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                            Nombre
                          </TableCell>
                          <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                            Teléfono
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!contactos ? <TablaCarga espacio={3} /> :
                          contactos.length < 1 ? <SinRegistros espacio={3} /> :
                            contactos.map(contacto => {
                              return (
                                <TableRow>
                                  <TableCell
                                    classes={{ root: estilo.tableCellRoot }}
                                    align="right">
                                    <MenuTabla id={contacto.idContactoEmergencia} items={itemsMenu} />
                                  </TableCell>
                                  <TableCell classes={{ root: estilo.tableCellRoot }}>
                                    {contacto.nombre}
                                  </TableCell>
                                  <TableCell classes={{ root: estilo.tableCellRoot }}>
                                    {contacto.telefono}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                      </TableBody>
                    </Box>
                  </TableContainer>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/pacientes/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid >
      </Container >

      {/* PopUp Alta de contacto */}
      {mostrarAltaContacto && <AltaContacto id={id} mostrarAltaContacto={mostrarAltaContacto} cerrarPopUPAltaContacto={cerrarPopUPAltaContacto} obtenerContactos={obtenerContactos} />}

      {modalEliminar && <AlertaDialogo titulo={"Seguro desea eliminar el contacto seleccionado?"} id={idEliminar} funcionConfirmar={peticionDelete} abierto={modalEliminar} funcionCerrar={cerrarModalEliminar} />}
    </>
  );
}

export default PacienteContactos;
