import React from "react";
import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import { FormularioReserva } from "./FormularioReserva";
import { useSelector } from "react-redux";

export const CeldaDia = ({ onDoubleClick, ...restProps }) => {
  const datos = { fechaInicio: restProps.startDate, fechaFin: restProps.endDate };
  const [mostrar, setMostrar] = React.useState(false);
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.reserva : []);

  const funcionCerrar = () => {
    setMostrar(false);
  }

  const funcionAbrir = () => {
    setMostrar(true);
  }

  return <>
    <DayView.TimeTableCell onClick={ () => funcionAbrir() } {...restProps} />
    {mostrar && permisos && permisos.crear && <FormularioReserva mostrar={mostrar} datos={datos} cerrar={funcionCerrar}/>}
  </>;
};

export const CeldaMes = ({ onDoubleClick, ...restProps }) => {
  const datos = { fechaInicio: restProps.startDate, fechaFin: restProps.endDate };
  const [mostrar, setMostrar] = React.useState(false);

  const funcionCerrar = () => {
    setMostrar(false);
  }

  const funcionAbrir = () => {
    setMostrar(true);
  }

  return <>
    <DayView.TimeTableCell onClick={() => funcionAbrir()} {...restProps} />
    {mostrar && <FormularioReserva mostrar={mostrar} datos={datos} cerrar={funcionCerrar} />}
  </>;
};