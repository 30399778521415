import * as ControllerSistema from 'controllers/Sistema';
import * as ControllerRoles from 'controllers/Roles';
import * as ControllerUsuarios from 'controllers/Usuarios';

export  async function ObtenerPiezas() {
  const r = await ControllerSistema.peticionGetPiezas();
  if(r.resultado)
    return r.data;
  return [];
};

export async function ObtenerPaises() {
  const r = await ControllerSistema.peticionGetPaises();
  if(r.resultado)
    return r.data;
  return [];
};

export async function ObtenerDocumentos() {
  const r = await ControllerSistema.peticionGetDocumentos();
  if(r.resultado)
    return r.data;
  return [];
};

export async function ObtenerRoles() {
  const r = await ControllerRoles.peticionGetRoles();
  if(r.resultado)
    return r.data.registros;
  return [];
};

export async function ObtenerCaras() {
  const r = await ControllerSistema.peticionGetCaras();
  if(r.resultado)
    return r.data;
  return [];
};

export async function ObtenerTiposDocumentacion() {
  const r = await ControllerSistema.peticionGetTiposDocumentacion();
  if(r.resultado)
    return r.data;
  return [];
};

export async function ObtenerTiposUsuario() {
  const r = await ControllerUsuarios.peticionGetTiposUsuario();
  if(r.resultado)
    return r.data;
  return [];
};
