import React from "react";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogContentText, DialogTitle, Dialog, Typography } from '@material-ui/core';
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";

export default function CierreCaja({ id, mostrar, cerrar, valor }) {
    const alert = useAlert();
    const [cajaInicio, setCajaInicio] = React.useState(0);
    const [gastos, setGastos] = React.useState(0);
    const [resultado, setResultado] = React.useState("-");
    const [ingresos, setIngresos] = React.useState(valor * 1);

    const guardarDatos = async e => {
        const campoNombre = e.target.name;
        const campoValor = e.target.value;
        switch (campoNombre) {
            case "cajaInicio":
                await setCajaInicio(campoValor);
                break;
            case "gastos":
                await setGastos(campoValor);
                break;
            default:
                break;
        }
    };

    const calcularResto = () => {
        if (cajaInicio && gastos && ingresos && !isNaN(cajaInicio) && !isNaN(gastos) && !isNaN(ingresos)) {
            setResultado(cajaInicio - gastos + ingresos);
        } else {
            alert.error("Ingrese todos los datos solicitados");
            setResultado("-");
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            open={mostrar}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrar}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" disableTypography>
                <Typography variant="h2">Cierre de caja</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    A continuación ingrese los valores en pesos del día corriente
                </DialogContentText>
                <FormGroup>
                    <FormLabel>Dinero inicial:</FormLabel>
                    <FormControl
                        variant="filled"
                        component={Box}
                        width="80%"
                        marginBottom="1rem!important"
                    >
                        <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="number"
                            defaultValue="0"
                            name="cajaInicio"
                            key="cajaInicio"
                            onChange={guardarDatos}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Gastos:</FormLabel>
                    <FormControl
                        variant="filled"
                        component={Box}
                        width="80%"
                        marginBottom="1rem!important"
                    >
                        <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="number"
                            defaultValue="0"
                            name="gastos"
                            key="gastos"
                            onChange={guardarDatos}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Resultado:</FormLabel>
                    <Typography variant="h4">$ {resultado}</Typography>
                </FormGroup>

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button color="secondary" variant="contained" onClick={() => cerrar()}>
                        Cerrar
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => calcularResto()} >
                        Calcular
                    </Button>
                </Box>

            </DialogContent>
        </Dialog>
    );
}