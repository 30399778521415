import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";
import { acortarTexto } from "functions/formulario";

export const ListaTrabajosReserva = ({ datos, eliminarTrabajo }) => {


    return (
        <>
            <Table>
                <TableHead>
                    <TableRow >
                        <TableCell></TableCell>
                        <TableCell>Pieza</TableCell>
                        <TableCell>Cara</TableCell>
                        <TableCell>Diagnóstico</TableCell>
                        <TableCell>Tratamiento</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!datos ? <TablaCarga espacio={5} /> :
                        datos.length < 1 ? <SinRegistros espacio={5} /> :
                            datos.map((i, posicion) => (
                                <TableRow
                                    key={posicion}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {
                                            i.id === datos.length ? (<></>) : (
                                                <IconButton onClick={(e) => eliminarTrabajo(posicion)}>
                                                    <Delete />
                                                </IconButton>
                                            )
                                        }
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {i.idPieza && i.idPieza.label ? acortarTexto(i.idPieza.label, 2) : '-'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {i.idCara && i.idCara.label ? i.idCara.label : '-'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {i.idDiagnostico && i.idDiagnostico.label ? acortarTexto(i.idDiagnostico.label, 15) : 'Desconocido'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {i.idTratamiento && i.idTratamiento.label ? acortarTexto(i.idTratamiento.label, 15) : 'Desconocido'}
                                    </TableCell>
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
        </>
    );
}