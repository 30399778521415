import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import { estiloPerfil } from "estilos/clases.js";
import * as ControllerUsuarios from 'controllers/Usuarios';
import { PhotoCamera } from "@material-ui/icons";
import { useAlert } from "react-alert";
import Foto from "components/Formularios/Foto";
import Pasos from "components/Formularios/Pasos";
import { pasos } from "functions/usuarios";
import { IconButton } from "@material-ui/core";
import { previewFoto } from "estilos/clases";
import { b64aBlob } from "functions/formulario";

function DatosAvatar(props) {
  let { id } = useParams();
  const estilo = estiloPerfil();
  const theme = useTheme();
  const alert = useAlert();
  const [itemsPasos, setItemsPasos] = React.useState([]);
  const itemActivo = 3;
  const [usuario, setUsuario] = React.useState({});
  let [usuarioActualizado, setUsuarioActualizado] = React.useState({ sexo: 'M', idPais: 214, idTipoDocumento: 1 });
  const formImagen = new FormData();
  const camposRequeridos = [{ id: 'imagen', nombre: 'Imagen' }];
  const [imagen, setImagen] = React.useState();

  const descartarImagen = () => {
    setImagen();
  };

  const peticionPost = async () => {
    if (imagen) {
      formImagen.append("avatar", imagen);
      const r = await ControllerUsuarios.peticionPostUsuarioAvatar(id, formImagen);
      (r.resultado) ? alert.success(r.mensaje) : alert.error(r.mensaje)
    } else {
      alert.error("Seleccione una imagen válida para subir");
    }
  }

  const seleccionarImagen = (e) => {
    const archivo = e.target.files[0];
    if (
      (archivo && archivo.type === "image/png") ||
      archivo.type === "image/jpeg" ||
      archivo.type === "image/jpg"
    ) {
      setImagen(archivo);    
    }
  }

  const obtenerUsuario = async () => {
    if (id) {
      const r = await ControllerUsuarios.peticionGetUsuario(id);
      if (r.resultado) {
        setUsuario(r.data);
        obtenerAvatar();
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerAvatar = async () => {
    if (id) {
      const r = await ControllerUsuarios.peticionGetUsuarioAvatar(id);
      if (r.resultado) {
        if(r.data){
          const blob= b64aBlob(r.data.base64 ? r.data.base64 : "");
          setImagen(blob);
        }        
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  useLayoutEffect(() => {
    setItemsPasos(pasos(id));
    obtenerUsuario();
  }, []);

  useLayoutEffect(() => {
    if (usuario && usuario.id) {
        obtenerAvatar();
    }
}, [usuario])

  return (
    <>
      <UserHeader />
      
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: estilo.containerRoot }}
      >
        <Grid container>
          <Foto avatar={imagen} />
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: estilo.gridItemRoot + " " + estilo.order2 }}
          >
            <Card
              classes={{
                root: estilo.cardRoot + " " + estilo.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {id && usuario && usuario.nombre1 ?
                          `Datos de ${usuario.nombre1} ${usuario.apellido1} (${usuario.cedula})`
                          : "Datos de usuario nuevo"}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
              <Pasos items={itemsPasos} activo={itemActivo} />
              <CardContent>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Avatar de usuario
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={3}>
                      <FormGroup>
                        <FormLabel>Subir imagen</FormLabel>
                        <FormLabel>
                          <IconButton color="primary" aria-label="upload picture" component="label">
                            <input hidden accept="image/*" type="file" onChange={seleccionarImagen} />
                            <PhotoCamera />
                          </IconButton>
                        </FormLabel>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Vista previa</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          {imagen && (
                            <div style={previewFoto.preview}>
                              <img
                                src={URL.createObjectURL(imagen)}
                                style={previewFoto.image}
                                alt="Avatar"
                              />
                              <button onClick={descartarImagen} style={previewFoto.delete}>
                                Descartar imagen
                              </button>
                            </div>
                          )}
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/usuarios/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => { peticionPost() }}
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid >
      </Container >
    </>
  );
}

export default DatosAvatar;
