import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogTitle, Dialog, Typography, FormControl, FormGroup, FilledInput, FormLabel, IconButton, Grid, FormControlLabel, Switch } from '@material-ui/core';
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";
import { WhatsApp, PhoneOutlined, MailOutlineOutlined, PhoneAndroidOutlined } from "@material-ui/icons";
import * as ControllerNotificaciones from 'controllers/Notificaciones';
import { recordatorioCuerpo, recordatorioAsunto } from "functions/notificaciones";
import { formatoTextoURL } from "functions/general";
import { useLayoutEffect } from "react";
import { evaluarCelular } from "functions/formulario";
import { evaluarCorreo } from "functions/formulario";

export default function EnviarNotificacion({ mostrar, cerrar, recargar, registro }) {
  const alert = useAlert();
  const [mensaje, setMensaje] = React.useState('');
  const [automatico, setAutomatico] = React.useState(false);

  const enviar = (modalidad) => {
    const validaCelular= evaluarCelular(registro.paciente.celular)
    const validarCorreo = evaluarCorreo(registro.paciente.mail);
    switch (modalidad) {
      case 'whatsapp':
        if (!validaCelular.resultado) {
          alert.error('El paciente tiene un número de celular inválido, verifique sus datos');
          return;
        }
        break;
      case 'mail':
        if (!validarCorreo.resultado) {
          alert.error('El paciente tiene un correo inválido, verifique sus datos');
          return;
        }
        break;
      case 'sms':
        if (!validaCelular.resultado) {
          alert.error('El paciente tiene un número de celular inválido, verifique sus datos');
          return;
        }
        break;
      case 'celular':
        if (!validaCelular.resultado) {
          alert.error('El paciente tiene un número de celular inválido, verifique sus datos');
          return;
        }
        break;
      default:
        break;
    }


    switch (modalidad) {
      case 'whatsapp':
        if (automatico) {
          envioWhatsapp()
        } else {
          alert.success('Se envía solicitud a Whatsapp app')
          window.open(`https://api.whatsapp.com/send?phone=${registro.paciente.celular.replace('+','')}&text=${formatoTextoURL(mensaje)}`)
        }
        break;
      case 'mail':
        alert.success('Se envía solicitud a la aplicación de correo por defecto')
        window.open(`mailto:${registro.paciente.mail}?subject=${recordatorioAsunto(registro.consultorio)}&body=${formatoTextoURL(mensaje)}`)
        break;
      case 'sms':
        if (automatico) {
          envioSMS()
        } else {
          alert.success('Se envió solicitud a casilla de SMS (sólo funciona desde smartphones)')
          window.open(`sms:${registro.paciente.celular}?&body=${formatoTextoURL(mensaje)}`)
        }
        break;
      case 'celular':
        alert.success('Se envió solicitud a aplicación de llamadas (sólo funciona desde smartphones)')
        window.open(`tel:${registro.paciente.celular}`)
        break;
      default:
        break;
    }
  };

  const finalizar = async () => {
    const r = await ControllerNotificaciones.peticionPutNotificacion([{ "idReserva": registro.idReserva }]);
    cerrar();
    recargar();
    (r.resultado) ? alert.success(r.mensaje) : alert.error(r.mensaje);
  }

  const envioWhatsapp = async () => {
    const elemento = { "mensaje": mensaje, "celular": registro.paciente.celular.replace('+','') }
    const r = await ControllerNotificaciones.peticionPostEnviarWhatsapp(elemento);
    (r.resultado) ? alert.success(r.mensaje) : alert.error(r.mensaje);
  }

  const envioSMS = async () => {
    const elemento = { "mensaje": mensaje, "celular": registro.paciente.celular.replace('+','') }
    const r = await ControllerNotificaciones.peticionPostEnviarSMS(elemento);
    (r.resultado) ? alert.success(r.mensaje) : alert.error(r.mensaje);
  }

  const guardarDatos = async e => {
    setMensaje(e.target.value);
  };

  const cambiarModo = () => {
    setAutomatico(!automatico);
  };

  const obtenerMensajePorDefecto = () => {
    setMensaje(recordatorioCuerpo(registro.paciente, registro.fecha, registro.medico, registro.consultorio));
  }

  useLayoutEffect(() => {
    if (registro)
      obtenerMensajePorDefecto();
  }, [registro]);

  return (
    <Dialog
      maxWidth="xs"
      open={mostrar}
      TransitionComponent={Transicion}
      keepMounted
      onClose={cerrar}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Typography variant="h4">Generación de recordatorio</Typography>
      </DialogTitle>
      <DialogContent>
        <FormGroup style={{ "margin": 0 }}>
          <FormLabel>Mensaje:</FormLabel>
          <FormControl
            variant="filled"
            component={Box}
            fontSize={"smaller"}
          >
            <Box
              component={FilledInput}
              autoComplete="off"
              type="text"
              defaultValue={mensaje}
              name="mensaje"
              key="mensaje"
              multiline rows={9}
              onChange={guardarDatos}
              inputProps={{maxLength: 400}}
            />
          </FormControl>
        </FormGroup>
        <Grid container style={{ "margin": 0 }} >
          <Grid
            item
            xs={2}
            xl={2}
            component={Box}
            display="flex"
            justifyContent="start"
            borderRadius={"10px"}
            bgcolor={"rgb(211,211,211,0.2) !important"}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={automatico}
                  onChange={cambiarModo}
                  name="automatico"
                  size="small"
                  color="success"
                  title="Servicio de pago"
                />
              }
              label={<Typography variant="h5" >Automático</Typography>}
            />
          </Grid>
          <Grid
            item
            xs={10}
            xl={10}
            component={Box}
            display="flex"
            style={{"justifyContent":"end"}}
            borderRadius={"10px"}
            bgcolor={"rgb(211,211,211,0.2) !important"}
          >
            <IconButton onClick={() => enviar('whatsapp')} title={`Whatsapp ${automatico && 'con CARGO extra'}`}>
              <WhatsApp />
            </IconButton>
            <IconButton onClick={() => enviar('mail')} title="Correo electrónico">
              <MailOutlineOutlined />
            </IconButton>
            <IconButton onClick={() => enviar('sms')} title={`SMS ${automatico ? 'con CARGO extra' : '(funciona sólo desde smartphone)'}`}>
              <PhoneAndroidOutlined />
            </IconButton>
            <IconButton onClick={() => enviar('celular')} title="Llamada (funciona sólo desde smartphone)">
              <PhoneOutlined />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
          <Button color="secondary" variant="contained" onClick={cerrar}>
            Cerrar
          </Button>
          <Button color="primary" variant="contained" onClick={() => finalizar()} >
            Finalizar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}