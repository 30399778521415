import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogContentText, DialogTitle, Dialog, Grid, Select, MenuItem } from '@material-ui/core';
import * as ControllerUsuarios from "controllers/Usuarios"
import { useAlert } from "react-alert";
import { evaluarForm } from "functions/formulario";
import { Transicion } from "components/Efectos/Transicion";

export default function AltaEspecialidad({ id, mostrarAltaEspecialidad, cerrarPopUPAltaEspecialidad, recargar, valores }) {
    const alert = useAlert();
    const [especialidadNueva, setEspecialidadNueva] = React.useState({});
    const camposRequeridos = [{ id: 'idEspecialidad', nombre: 'Especialidad' }];

    const guardarDatos = async e => {
        await setEspecialidadNueva({
            ...especialidadNueva,
            [e.target.name]: e.target.value
        });
    };


    const peticionPost = async () => {
        const v = evaluarForm(especialidadNueva, camposRequeridos);
        cerrarPopUPAltaEspecialidad();
        if (v.resultado) {
            const r = await ControllerUsuarios.peticionPostUsuarioEspecialidad(id,[especialidadNueva]);
            if (r.resultado) {
                alert.success(r.mensaje);
                recargar();
            } else {
                alert.error(r.mensaje);
            }
        } else {
            alert.error(v.mensaje);
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            open={mostrarAltaEspecialidad}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrarPopUPAltaEspecialidad}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Alta de especialidad</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Seleccione una nueva especialidad del usuario.
                </DialogContentText>
                <Grid item xs={12} lg={6}>
                    <FormGroup>
                        <FormLabel>Especialidad*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                            <Select defaultValue={0} name="idEspecialidad" key={'usuario.idEspecialidad'}
                                onChange={(e) => guardarDatos(e)}>
                                {valores && valores.length > 0 && valores.map(r => {
                                    return (
                                        <MenuItem value={r.idEspecialidad}>{r.nombre}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button color="secondary" variant="contained" onClick={cerrarPopUPAltaEspecialidad}>
                        Cerrar
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => peticionPost()} >
                        Confirmar
                    </Button>
                </Box>

            </DialogContent>
        </Dialog>
    );
}