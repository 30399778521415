import { queryGet } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Reporte/";

export function peticionGetKPIDeudores() {
  return queryGet(url + "ranking/deudores");
}

export function peticionGetKPICitasCanceladas() {
  return queryGet(url + "citas/canceladas");
}

export function peticionGetKPICitasRealizadas() {
  return queryGet(url + "citas/realizadas");
}

export function peticionGetKPIMontoTratamientos() {
  return queryGet(url + "monto/tratamiento");
}

export function peticionGetKPIMontoInsumos() {
  return queryGet(url + "monto/insumo");
}

export function peticionGetKPIGenero() {
  return queryGet(url + "paciente/sexo");
}

export function peticionGetKPITiempoReal() {
  return queryGet(url + "kpi/");
}

export function peticionGetKPIMotivosCancelacion() {
  return queryGet(url + "ranking/cita/cancelacion/motivo");
}