import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as ControllerPacientes from 'controllers/Pacientes';
import { Close, PhotoCamera } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle, FilledInput, FormControl, FormGroup, FormLabel, IconButton, MenuItem, Select, Typography } from "@material-ui/core";
import { obtenerFecha } from 'functions/fechas';
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";
import { evaluarForm } from "functions/formulario";

export default function AltaArchivo({ mostrar, cerrar, recargar, registros, id }) {
    const alert = useAlert();
    const [infoNuevoDocumento, setInfoNuevoDocumento] = React.useState({ "fechaReal": obtenerFecha('date'), "idDocumentacionTipo":1 });
    const [nombreNuevoDocumento, setNombreNuevoDocumento] = React.useState('');
    const [imagen, setImagen] = React.useState();
    const data = new FormData();
    const camposRequeridos = [{ id: 'nombre', nombre: 'Nombre' }, { id: 'fechaReal', nombre: 'Fecha' }, { id: 'idDocumentacionTipo', nombre: 'Tipo de imagen' },]


    const peticionPost = async () => {
        if (!imagen || imagen === '') {
            alert.error('Seleccione una imagen para subir');
            return;
        }

        const validarForm = evaluarForm(infoNuevoDocumento, camposRequeridos);
        if (!validarForm.resultado) {
            alert.error(validarForm.mensaje)
            return;
        }

        data.append("documento", imagen);
        data.append("idDocumentacionTipo", infoNuevoDocumento.idDocumentacionTipo);
        data.append("Titulo", infoNuevoDocumento.nombre);
        data.append("FechaReal", obtenerFecha("date-simple"));
        const r = await ControllerPacientes.peticionPostDocumento(id, data);
        cerrar();
        if (r.resultado) {
            recargar();
            alert.success(r.mensaje);
        } else {
            alert.error(r.mensaje);
        }
    }

    const guardarDatos = async e => {
        await setInfoNuevoDocumento({
            ...infoNuevoDocumento,
            [e.target.name]: e.target.value
        });
    };

    const seleccionarImagen = async (e) => {
        const archivo = e.target.files[0];
        if (archivo) {
            if (
                (archivo && archivo.type === "image/png") ||
                archivo.type === "image/jpeg" ||
                archivo.type === "image/jpg"
            ) {
                setNombreNuevoDocumento(`${e.target.files[0].name.substring(0, 30)}... (${Math.round(e.target.files[0].size / 1024)}KB)`);
                setImagen(archivo);
            } else {
                alert.error('Seleccione un archivo del tipo imagen');
            }
        }
    }

    return (
        <Dialog
            maxWidth="xs"
            open={mostrar}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrar}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="form-dialog-title" >
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Typography variant="h4">Subir documentos</Typography>
                    <IconButton aria-label="close" onClick={cerrar}>
                        <Close />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box
                    textAlign="center"
                    fontSize="1rem"
                >
                    <Grid container style={{ "paddingLeft": "2rem", "paddingRight": "2rem" }}>
                        <Grid item xs={12} lg={12}>
                            <FormGroup>
                                <FormLabel>Nombre*</FormLabel>
                                <FormControl
                                    variant="filled"
                                    component={Box}
                                    width="100%"
                                    marginBottom="1rem!important"
                                >
                                    <Box
                                        paddingLeft="0.75rem"
                                        paddingRight="0.75rem"
                                        component={FilledInput}
                                        onChange={(e) => guardarDatos(e)}
                                        autoComplete="off"
                                        type="text"
                                        name="nombre"
                                        key="nombre"
                                        inputProps={{maxLength: 200}}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Tipo de imagen*</FormLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select name="idDocumentacionTipo" key='idDocumentacionTipo' value={1}
                                        onChange={(e) => guardarDatos(e)}>
                                        {registros && registros.map(r => {
                                            return (
                                                <MenuItem value={r.idDocumentacionTipo}>{r.nombre}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Fecha*</FormLabel>
                                <FormControl
                                    variant="filled"
                                    component={Box}
                                    width="100%"
                                    marginBottom="1rem!important"
                                >
                                    <Box
                                        paddingLeft="0.75rem"
                                        paddingRight="0.75rem"
                                        component={FilledInput}
                                        onChange={(e) => guardarDatos(e)}
                                        autoComplete="off"
                                        defaultValue={obtenerFecha('date-simple')}
                                        type="date"
                                        name="fechaReal"
                                        key="fechaReal"
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} lg={10}>
                            <IconButton color="primary" aria-label="Subir imagen" component="label">
                                <input hidden accept="image/*" type="file" onChange={seleccionarImagen} />
                                <PhotoCamera />
                            </IconButton>
                            {nombreNuevoDocumento.length > 0 ? nombreNuevoDocumento : "Seleccione un archivo..."}
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        component="label"
                        onClick={peticionPost}
                    >Subir</Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}