import React, { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useAlert } from "react-alert";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import * as ControllerDiagnosticos from 'controllers/Diagnosticos';
import MenuTabla from "components/Tabla/MenuTabla";
import AlertaDialogo from "components/Popups/AlertaDialogo";
import { useSelector } from "react-redux";
import Buscador from "components/Tabla/Buscador";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";

const useStyles = makeStyles(componentStyles);

const Diagnosticos = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [diagnosticos, setDiagnosticos] = React.useState();
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [idEliminar, setIdEliminar] = React.useState();
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.diagnostico : []);

  const peticionDelete = async (id) => {
    cerrarModalEliminar();
    if (id) {
      const r = await ControllerDiagnosticos.peticionDeleteDiagnostico(id);
      if (r.resultado) {
        setDiagnosticos(diagnosticos.filter(i => i.idDiagnostico !== id));
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const eliminarRegistro = (id) => {
    setIdEliminar(id);
    setModalEliminar(true);
  }

  const cerrarModalEliminar = () => {
    setIdEliminar();
    setModalEliminar(false);
  }

  const itemsMenu = [{ nombre: "Eliminar", "funcion": eliminarRegistro, "permiso": permisos && permisos.eliminar }];

  const obtenerDiagnosticos = async () => {
    const r = await ControllerDiagnosticos.peticionGetDiagnosticos();
    (r.resultado) ? setDiagnosticos(r.data) : alert.error(r.mensaje);
  }

  const buscarPorNombre = async (valor) => {
    const r = await ControllerDiagnosticos.peticionPostBuscar(valor);
    if (!r.resultado) {
      alert.error(r.mensaje);
      return;
    }
    setDiagnosticos(r.data);
  }

  useLayoutEffect(() => {
    obtenerDiagnosticos();
  }, []);

  return (
    <>
      <Header />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>


          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    Listado
                  </Box>
                </Grid>
                {permisos && permisos.crear &&
                  <Grid item xs="auto">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        href="/app/diagnosticos/diagnostico/"
                      >
                        Nuevo
                      </Button>
                    </Box>
                  </Grid>}
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>
          {permisos && permisos.buscar &&
            <Buscador etiqueta="Nombre" buscar={buscarPorNombre} reiniciar={() => obtenerDiagnosticos()} />
          }
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Nombre
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!diagnosticos ? <TablaCarga espacio={2} /> :
                  diagnosticos.length < 1 ? <SinRegistros espacio={2} /> :
                    diagnosticos.map(diagnostico => {
                      return (
                        <TableRow>
                          <TableCell
                            classes={{ root: classes.tableCellRoot }}
                            align="right">
                            <MenuTabla id={diagnostico.idDiagnostico} items={itemsMenu} />
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {diagnostico.nombre}
                          </TableCell>
                        </TableRow>
                      )
                    })},
              </TableBody>
            </Box>
          </TableContainer>
        </Card>

      </Container>
      {modalEliminar && <AlertaDialogo titulo={"Seguro desea eliminar el diagnósitico seleccionado?"} id={idEliminar} funcionConfirmar={peticionDelete} abierto={modalEliminar} funcionCerrar={cerrarModalEliminar} />}
    </>
  );
};

export default Diagnosticos;
