import React from "react";
import Grid from "@material-ui/core/Grid";
import { Close } from '@material-ui/icons';
import { Box, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Transicion } from "components/Efectos/Transicion";
import { previewFoto } from "estilos/clases";
import { formatoFecha } from "functions/fechas";
import { useLayoutEffect } from "react";
import { b64aBlob } from "functions/formulario";
import * as ControllerPacientes from 'controllers/Pacientes';

export default function ZoomArchivo({ mostrar, cerrar, registro, id }) {
  const [imagen, setImagen] = React.useState();

  const obtenerDocumento = async (idDocumentacion) => {
    const r = await ControllerPacientes.peticionGetDocumento(id, idDocumentacion);
    if (r.resultado) {
      if (r.data) {
        const blob = b64aBlob(r.data ? r.data.base64 : "");
        setImagen(blob);
      }
    } else {
      alert.error(r.mensaje);
    }
  }

  useLayoutEffect(() => {
    if (registro.idDocumentacion)
      obtenerDocumento(registro.idDocumentacion);
  }, [registro]);


  return (
    <Dialog
      maxWidth="md"
      open={mostrar}
      TransitionComponent={Transicion}
      keepMounted
      onClose={cerrar}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="form-dialog-title" style={{ "paddingBottom": 0 }} >
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Typography gutterBottom variant="h4" component="h4"> Visualización de documento</Typography>
          <IconButton aria-label="close" onClick={cerrar}>
            <Close />
          </IconButton>
        </Grid></DialogTitle>
      <DialogContent style={{ "paddingTop": 0 }}>
        <DialogContentText>
          <strong> Nombre:</strong> {registro.titulo}<br />
          <strong> Fecha:</strong> {registro.fechaReal ? formatoFecha(registro.fechaReal, "DD/MM/YYYY HH:MM") : '-'} <br />
          <strong> Subido:</strong> {registro.fechaRegistro ? formatoFecha(registro.fechaRegistro, "DD/MM/YYYY HH:MM") : '-'} <br />
        </DialogContentText>
        <Box textAlign={"center"}>
          {!imagen ?
            <CircularProgress />
            : (
              <img
                src={imagen && imagen.size > 0 ? URL.createObjectURL(imagen) :
                  require("assets/img/theme/imagen.png").default}
                style={previewFoto.image}
                alt="Documento"
              />
            )}
        </Box>
      </DialogContent>

    </Dialog>
  );
}