import { queryPut } from "functions/controllers";
import { queryGet, queryPost, queryDelete } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Sistema/alergia/";

export function peticionGetAlergias() {
  return queryGet(url);
}

export function peticionPostAlergia(elemento) {
  return queryPost(url, elemento);
}

export function peticionPutAlergia(id, elemento) {
  return queryPut(url  + id, elemento);
}

export function peticionDeleteAlergia(id) {
  return queryDelete(url  + id);
}

export function peticionGetAlergia(id) {
  return queryGet(url + id);
}
