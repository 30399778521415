import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import FilledInput from "@material-ui/core/FilledInput";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import UserHeader from "components/Headers/UserHeader.js";
import { estiloTabla } from "estilos/clases.js";
import * as ControllerPacientes from 'controllers/Pacientes';
import * as ControllerEmergencias from 'controllers/Emergencias';
import * as ControllerMutualistas from 'controllers/Mutualistas';
import * as ControllerAlergias from 'controllers/Alergias';
import * as ControllerMedicamentos from 'controllers/Medicamentos';
import * as ControllerPatologias from 'controllers/Patologias';
import { useAlert } from "react-alert";
import Foto from "components/Formularios/Foto";
import Pasos from "components/Formularios/Pasos";
import { evaluarForm } from "functions/formulario";
import { pasos } from "functions/pacientes";
import { MenuItem, Select } from "@material-ui/core";
import MenuTabla from "components/Tabla/MenuTabla";
import AltaPatologia from "./popup/altaPatologia";
import AltaMedicamento from "./popup/altaMedicamento";
import AltaAlergia from "./popup/altaAlergia";
import { formatoFecha } from 'functions/fechas';
import { b64aBlob } from "functions/formulario";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";
import { acortarTexto } from "functions/formulario";

function Gestion(props) {
  let { id } = useParams();
  const estilo = estiloTabla();
  const theme = useTheme();
  const alert = useAlert();
  const [itemsPasos, setItemsPasos] = React.useState([]);
  const itemActivo = 1;
  const [paciente, setPaciente] = React.useState({});
  let [pacienteActualizado, setPacienteActualizado] = React.useState({});
  const [pacientePatologias, setPacientePatologias] = React.useState();
  const [pacienteMedicamentos, setPacienteMedicamentos] = React.useState();
  const [pacienteAlergias, setPacienteAlergias] = React.useState();
  const [mutualistas, setMutualistas] = React.useState([]);
  const [emergencias, setEmergencias] = React.useState([]);
  const [patologias, setPatologias] = React.useState([]);
  const [medicamentos, setMedicamentos] = React.useState([]);
  const [alergias, setAlergias] = React.useState([]);
  const camposRequeridos = [{ id: 'idEmergencia', nombre: 'Emergencia' },
  { id: 'vencimientoCarnetSalud', nombre: 'Vencimiento de carnet de salud' },
  { id: 'idMutualista', nombre: 'Mutualista' },];
  const [mostrarAltaPatologia, setMostrarAltaPatologia] = React.useState(false);
  const [mostrarAltaMedicamento, setMostrarAltaMedicamento] = React.useState(false);
  const [mostrarAltaAlergia, setMostrarAltaAlergia] = React.useState(false);
  const [avatar, setAvatar] = React.useState();

  const abrirPopUPAltaPatologia = () => {
    setMostrarAltaPatologia(true);
  };
  const abrirPopUPAltaMedicamento = () => {
    setMostrarAltaMedicamento(true);
  };
  const abrirPopUPAltaAlergia = () => {
    setMostrarAltaAlergia(true);
  };

  const cerrarPopUPAltaPatologia = () => {
    setMostrarAltaPatologia(false);
  };

  const cerrarPopUPAltaMedicamento = () => {
    setMostrarAltaMedicamento(false);
  };

  const cerrarPopUPAltaAlergia = () => {
    setMostrarAltaAlergia(false);
  };

  const peticionPut = async () => {
    const validarForm = evaluarForm(pacienteActualizado, camposRequeridos);
    if (!validarForm.resultado) {
      alert.error(validarForm.mensaje);
      return;
    }
    const r = await ControllerPacientes.peticionPutPaciente(id, pacienteActualizado);
    if (r.resultado) {
      alert.success(r.mensaje);
    } else {
      alert.error(r.mensaje);
    }
  }

  const peticionDeletePatologia = async (idPatologia) => {
    if (id) {
      const r = await ControllerPacientes.peticionDeletePacientePatologias(id,[{"id":idPatologia}]);
      if (r.resultado) {
        obtenerPacientePatologias();
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const peticionDeleteMedicamento = async (idMedicamento) => {
    if (id) {
      const r = await ControllerPacientes.peticionDeletePacienteMedicamentos(id, [{"id":idMedicamento}]);
      if (r.resultado) {
        obtenerPacienteMedicamentos();
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const peticionDeleteAlergia = async (idAlergia) => {
    if (id) {
      const r = await ControllerPacientes.peticionDeletePacienteAlergias(id, [{"id":idAlergia}]);
      if (r.resultado) {
        obtenerPacienteAlergias();
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }


  const guardarDatos = async e => {
    await setPacienteActualizado({
      ...pacienteActualizado,
      [e.target.name]: e.target.value
    });
  };

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
        setPacienteActualizado({
          ...r.data,
          idEmergencia: r.data && r.data.emergencia ? r.data.emergencia.idEmergencia : 0,
          idMutualista: r.data && r.data.mutualista ? r.data.mutualista.idMutualista : 0,
        });
        obtenerPacientePatologias();
        obtenerPacienteMedicamentos();
        obtenerPacienteAlergias();
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerPacientePatologias = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPacientePatologias(id);
      if (r.resultado) {
        setPacientePatologias(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerPacienteMedicamentos = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPacienteMedicamentos(id);
      if (r.resultado) {
        setPacienteMedicamentos(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerPacienteAlergias = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPacienteAlergias(id);
      if (r.resultado) {
        setPacienteAlergias(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerAvatar = async () => {
    const r = await ControllerPacientes.peticionGetPacienteAvatar(id);
    if (r.resultado) {
      if (r.data) {
        const blob = b64aBlob(r.data.base64 ? r.data.base64 : "");
        setAvatar(blob);
      }
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerMutualistas = async () => {
      const r = await ControllerMutualistas.peticionGetMutualistas();
      (r.resultado) ? setMutualistas(r.data) : alert.error(r.mensaje);
  };

  const obtenerEmergencias = async () => {
      const r = await ControllerEmergencias.peticionGetEmergencias();
      (r.resultado) ? setEmergencias(r.data) : alert.error(r.mensaje);
  };

  const obtenerPatologias = async () => {
    const r = await ControllerPatologias.peticionGetPatologias();
    (r.resultado) ? setPatologias(r.data) : alert.error(r.mensaje);
};

const obtenerAlergias = async () => {
  const r = await ControllerAlergias.peticionGetAlergias();
  (r.resultado) ? setAlergias(r.data) : alert.error(r.mensaje);
};

const obtenerMedicamentos = async () => {
  const r = await ControllerMedicamentos.peticionGetMedicamentos();
  (r.resultado) ? setMedicamentos(r.data) : alert.error(r.mensaje);
};

  const itemsMenuPatologia = [{ nombre: "Eliminar", "funcion": peticionDeletePatologia }];
  const itemsMenuMedicamento = [{ nombre: "Eliminar", "funcion": peticionDeleteMedicamento }];
  const itemsMenuAlergia = [{ nombre: "Eliminar", "funcion": peticionDeleteAlergia }];

  useLayoutEffect(() => {
    setItemsPasos(pasos(id));
    obtenerPaciente();
    obtenerMutualistas();
    obtenerEmergencias();
    obtenerMedicamentos();
    obtenerPatologias();
    obtenerAlergias();
  }, []);

  useLayoutEffect(() => {
    if (paciente && paciente.idPaciente) {
      obtenerAvatar();
    }
  }, [paciente])

  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: estilo.containerRoot }}
      >
        <Grid container>
          <Foto avatar={avatar} />
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: estilo.gridItemRoot + " " + estilo.order2 }}
          >
            <Card
              classes={{
                root: estilo.cardRoot + " " + estilo.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {id && paciente && paciente.nombre1 ?
                          `Datos de ${paciente.nombre1} ${paciente.apellido1} (${paciente.documento})`
                          : "Datos de paciente nuevo"}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
              <Pasos items={itemsPasos} activo={itemActivo} />
              <CardContent>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Datos médicos
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Mutualista*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={paciente && paciente.mutualista && paciente.mutualista.idMutualista} name="idMutualista" key={paciente && paciente.mutualista ? paciente.mutualista.idMutualista : 'paciente.idMutualista'}
                            onChange={(e) => guardarDatos(e)}>
                            {mutualistas && mutualistas.map(p => {
                              return (
                                <MenuItem value={p.idMutualista}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Emergencia*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={paciente && paciente.emergencia && paciente.emergencia.idEmergencia} name="idEmergencia" key={paciente && paciente.emergencia ? paciente.emergencia.idEmergencia : 'paciente.idEmergencia'}
                            onChange={(e) => guardarDatos(e)}>
                            {emergencias && emergencias.map(p => {
                              return (
                                <MenuItem value={p.idEmergencia}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Vto Carnet de salud*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="date"
                            defaultValue={paciente && paciente.vencimientoCarnetSalud? formatoFecha(paciente.vencimientoCarnetSalud, "YYYY-MM-DD") : null}
                            name="vencimientoCarnetSalud"
                            key={paciente ? paciente.vencimientoCarnetSalud : 'paciente.vencimientoCarnetSalud'}
                            onChange={(e) => guardarDatos(e)}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <CardHeader
                    subheader={
                      <Grid
                        container
                        component={Box}
                        alignItems="end"
                        justifyContent="flex-end"
                      >
                        <Grid item xs="auto">
                          <Box
                            justifyContent="flex-end"
                            display="flex"
                            flexWrap="wrap"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => { peticionPut() }}
                            >
                              Guardar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                    classes={{ root: estilo.cardHeaderRoot }}
                  ></CardHeader>
                  <CardHeader
                    subheader={
                      <Grid
                        container
                        component={Box}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs="auto">
                          <Box
                            component={Typography}
                            variant="h4"
                            marginBottom="0!important"
                          >
                            Patologías
                          </Box>
                        </Grid>
                        <Grid item xs="auto">
                          <Box
                            justifyContent="flex-end"
                            display="flex"
                            flexWrap="wrap"
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={abrirPopUPAltaPatologia}
                            >
                              Agregar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  ></CardHeader>
                  <CardContent>
                    <div className={estilo.plLg4}>
                      <TableContainer>
                        <Box
                          component={Table}
                          alignItems="center"
                          marginBottom="0!important"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}></TableCell>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                                Nombre
                              </TableCell>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                                Descripción
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!pacientePatologias ? <TablaCarga espacio={3} /> :
                              pacientePatologias.length < 1 ? <SinRegistros espacio={3} /> :
                                pacientePatologias.map(e => {
                                  return (
                                    <TableRow>
                                      <TableCell
                                        classes={{ root: estilo.tableCellRoot }}
                                        align="right">
                                        <MenuTabla id={e.id} items={itemsMenuPatologia} />
                                      </TableCell>
                                      <TableCell classes={{ root: estilo.tableCellRoot }}>
                                        {e.patologia.nombre}
                                      </TableCell>
                                      <TableCell classes={{ root: estilo.tableCellRoot }}>
                                        {acortarTexto(e.patologia.descripcion,120)}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                          </TableBody>
                        </Box>
                      </TableContainer>
                    </div>
                  </CardContent>
                  <CardHeader
                    subheader={
                      <Grid
                        container
                        component={Box}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs="auto">
                          <Box
                            component={Typography}
                            variant="h4"
                            marginBottom="0!important"
                          >
                            Alergias
                          </Box>
                        </Grid>
                        <Grid item xs="auto">
                          <Box
                            justifyContent="flex-end"
                            display="flex"
                            flexWrap="wrap"
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={abrirPopUPAltaAlergia}
                            >
                              Agregar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  ></CardHeader>
                  <CardContent>
                    <div className={estilo.plLg4}>
                      <TableContainer>
                        <Box
                          component={Table}
                          alignItems="center"
                          marginBottom="0!important"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}></TableCell>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                                Nombre
                              </TableCell>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                                Descripción
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!pacienteAlergias ? <TablaCarga espacio={3} /> :
                              pacienteAlergias.length < 1 ? <SinRegistros espacio={3} /> :
                                pacienteAlergias.map(e => {
                                  return (
                                    <TableRow>
                                      <TableCell
                                        classes={{ root: estilo.tableCellRoot }}
                                        align="right">
                                        <MenuTabla id={e.id} items={itemsMenuAlergia} />
                                      </TableCell>
                                      <TableCell classes={{ root: estilo.tableCellRoot }}>
                                        {e.alergia.nombre}
                                      </TableCell>
                                      <TableCell classes={{ root: estilo.tableCellRoot }}>
                                        {acortarTexto(e.alergia.descripcion,120)}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                          </TableBody>
                        </Box>
                      </TableContainer>
                    </div>
                  </CardContent>
                  <CardHeader
                    subheader={
                      <Grid
                        container
                        component={Box}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs="auto">
                          <Box
                            component={Typography}
                            variant="h4"
                            marginBottom="0!important"
                          >
                            Medicamentos
                          </Box>
                        </Grid>
                        <Grid item xs="auto">
                          <Box
                            justifyContent="flex-end"
                            display="flex"
                            flexWrap="wrap"
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={abrirPopUPAltaMedicamento}
                            >
                              Agregar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  ></CardHeader>
                  <CardContent>
                    <div className={estilo.plLg4}>
                      <TableContainer>
                        <Box
                          component={Table}
                          alignItems="center"
                          marginBottom="0!important"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}></TableCell>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                                Nombre
                              </TableCell>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                                Descripción
                              </TableCell>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                                Principio activo
                              </TableCell>
                              <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                                Laboratorio
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!pacienteMedicamentos ? <TablaCarga espacio={5} /> :
                              pacienteMedicamentos.length < 1 ? <SinRegistros espacio={5} /> :
                                pacienteMedicamentos.map(e => {
                                  return (
                                    <TableRow>
                                      <TableCell
                                        classes={{ root: estilo.tableCellRoot }}
                                        align="right">
                                        <MenuTabla id={e.id} items={itemsMenuMedicamento} />
                                      </TableCell>
                                      <TableCell classes={{ root: estilo.tableCellRoot }}>
                                        {e.medicamento.nombre}
                                      </TableCell>
                                      <TableCell classes={{ root: estilo.tableCellRoot }}>
                                        {acortarTexto(e.medicamento.descripcion,120)}
                                      </TableCell>
                                      <TableCell classes={{ root: estilo.tableCellRoot }}>
                                        {e.medicamento.principioActivo}
                                      </TableCell>
                                      <TableCell classes={{ root: estilo.tableCellRoot }}>
                                        {e.medicamento.laboratorio}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                          </TableBody>
                        </Box>
                      </TableContainer>
                    </div>
                  </CardContent>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    justifyContent="flex-start"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/pacientes/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid >
      </Container >

      {/* PopUp Alta de Patologia */}
      {mostrarAltaPatologia && <AltaPatologia id={id} mostrarAlta={mostrarAltaPatologia} cerrarPopUPAlta={cerrarPopUPAltaPatologia} recargarDatos={obtenerPacientePatologias} valores={patologias} />}

      {/* PopUp Alta de Medicamento */}
      {mostrarAltaMedicamento && <AltaMedicamento id={id} mostrarAlta={mostrarAltaMedicamento} cerrarPopUPAlta={cerrarPopUPAltaMedicamento} recargarDatos={obtenerPacienteMedicamentos} valores={medicamentos} />}

      {/* PopUp Alta de Alergia */}
      {mostrarAltaAlergia && <AltaAlergia id={id} mostrarAlta={mostrarAltaAlergia} cerrarPopUPAlta={cerrarPopUPAltaAlergia} recargarDatos={obtenerPacienteAlergias} valores={alergias} />}


    </>
  );
}

export default Gestion;
