import React, { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import CardActions from "@material-ui/core/CardActions";
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import * as ControllerRegistros from 'controllers/Registros';
import { useAlert } from "react-alert";
import MenuTabla from "components/Tabla/MenuTabla";
import VerError from "./popup/verError";
import { formatoFecha } from 'functions/fechas';
import Detalle from "components/Tabla/Detalle";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";
import { convertirTimezone } from "functions/fechas";
import { acortarTexto } from "functions/formulario";

const useStyles = makeStyles(componentStyles);

const Sistema = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [registros, setRegistros] = React.useState();
  const [idRegistro, setIdRegistro] = React.useState('');
  const [mostrarRegistro, setMostrarRegistro] = React.useState(false);
  const [pagina, setPagina] = React.useState(1);
  const [cantidadPaginas, setCantidadPaginas] = React.useState(0);

  const abrirPopUPRegistro = async (id) => {
    setIdRegistro(id);
    setMostrarRegistro(true);
  };

  const cerrarPopUPRegistro = () => {
    setMostrarRegistro(false);
  };

  const cambioPagina = (a, b) => {
    setPagina(b)
  }

  const obtenerErrores = async () => {
    const r = await ControllerRegistros.peticionGetErrores(pagina);
    if (r.resultado) {
      setRegistros(r.data ? r.data.registros : []);
      setCantidadPaginas(r.data.paginado.cantidadDePaginas);
    } else {
      alert.error(r.mensaje);
    }
  }

  useLayoutEffect(() => {
    obtenerErrores(pagina);
  }, [pagina]);

  const itemsMenu = [{ nombre: "Ver", "funcion": abrirPopUPRegistro }];

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>

          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    Listado
                  </Box>
                </Grid>
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    ID
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Fecha
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Servicio
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Error
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!registros ? <TablaCarga espacio={5} /> :
                  registros.length < 1 ? <SinRegistros espacio={5} /> :
                    registros.map(r => {
                      return (
                        <TableRow>
                          <TableCell
                            classes={{ root: classes.tableCellRoot }}
                            align="right">
                            <MenuTabla id={r.traceIdentifier} items={itemsMenu} />
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            <Detalle texto={`ID: ${r.traceIdentifier}`} />
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {formatoFecha(convertirTimezone(r.date, 'GMT-3'), "DD/MM/YYYY HH:MM")}
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {r.source ? acortarTexto(r.source, 80) : "-"}
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {r.message ? acortarTexto(r.message, 120) : "No hay información"}
                          </TableCell>
                        </TableRow>
                      )
                    })},
              </TableBody>
            </Box>
          </TableContainer>
          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination count={cantidadPaginas} onChange={cambioPagina} color="primary" variant="outlined" />
          </Box>
        </Card>
      </Container>

      {/* PopUp */}
      {mostrarRegistro && <VerError mostrarRegistro={mostrarRegistro} cerrarPopUPRegistro={cerrarPopUPRegistro} id={idRegistro} />}

    </>
  );
};

export default Sistema;
