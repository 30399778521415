import React, { useLayoutEffect } from "react";
import Box from "@material-ui/core/Box";
import { Grid, Typography } from "@material-ui/core";
import { FilledInput, IconButton } from '@material-ui/core';
import { Search, RotateLeft } from "@material-ui/icons";
import { useAlert } from "react-alert";

const Buscador = ({ etiqueta, buscar, reiniciar, porDefecto, tamaño, tipo }) => {
  const alert = useAlert();
  const [valor, setValor] = React.useState('');

  const guardarDatos = async e => {
    await setValor({
      ...valor,
      [e.target.name]: e.target.value
    });
  };

  const restablecerBusqueda = () => {
    setValor({ nombre: porDefecto ? porDefecto : '' });
    reiniciar();
  };

  const procesar = () => {
    if (!valor || (valor && valor.nombre.length === 0)) {
      alert.error('Ingrese un valor de búsqueda');
      return;
    }
    buscar(valor);
  };

  useLayoutEffect(() => {
    if (porDefecto)
      setValor({ nombre: porDefecto });
  }, []);

  return (
    <Grid
      container
      component={Box}
      alignItems="center"
      alignSelf="end"
    >
      <Grid item >
        <Box
          component={Typography}
          variant="h5"
          marginBottom="0!important"
        >
          {etiqueta ? etiqueta : ''}
        </Box>
      </Grid>
      <Grid item style={{ "width": `${tamaño === "small" ? "70px" : "120px"}` }}>
        <Box
          component={FilledInput}
          autoComplete="off"
          placeholder={etiqueta ? etiqueta : ''}
          type={tipo ? tipo : 'texto'}
          defaultValue={porDefecto}
          value={valor && valor.nombre}
          name="nombre"
          key="nombre"
          onChange={guardarDatos}
        />
      </Grid>
      <Grid item style={{ "marginRight": "1rem" }} >
        <IconButton title={'Buscar'} onClick={procesar} >
          <Search />
        </IconButton>
        <IconButton title={'Quitar búsqueda'} onClick={restablecerBusqueda} >
          <RotateLeft />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Buscador;
