export const timeAgo_traduccion =
{
	"locale": "es-AR",
	"long": {
		"year": {
			"previous": "el año pasado",
			"current": "este año",
			"next": "el próximo año",
			"past": {
				"one": "hace {0} año",
				"other": "hace {0} años"
			},
			"future": {
				"one": "dentro de {0} año",
				"other": "dentro de {0} años"
			}
		},
		"quarter": {
			"previous": "el trimestre pasado",
			"current": "este trimestre",
			"next": "el próximo trimestre",
			"past": {
				"one": "hace {0} trimestre",
				"other": "hace {0} trimestres"
			},
			"future": {
				"one": "dentro de {0} trimestre",
				"other": "dentro de {0} trimestres"
			}
		},
		"month": {
			"previous": "el mes pasado",
			"current": "este mes",
			"next": "el próximo mes",
			"past": {
				"one": "hace {0} mes",
				"other": "hace {0} meses"
			},
			"future": {
				"one": "dentro de {0} mes",
				"other": "dentro de {0} meses"
			}
		},
		"week": {
			"previous": "la semana pasada",
			"current": "esta semana",
			"next": "la próxima semana",
			"past": {
				"one": "hace {0} semana",
				"other": "hace {0} semanas"
			},
			"future": {
				"one": "dentro de {0} semana",
				"other": "dentro de {0} semanas"
			}
		},
		"day": {
			"previous": "ayer",
			"previous-2": "anteayer",
			"current": "hoy",
			"next": "mañana",
			"next-2": "pasado mañana",
			"past": {
				"one": "hace {0} día",
				"other": "hace {0} días"
			},
			"future": {
				"one": "dentro de {0} día",
				"other": "dentro de {0} días"
			}
		},
		"hour": {
			"current": "esta hora",
			"past": {
				"one": "hace {0} hora",
				"other": "hace {0} horas"
			},
			"future": {
				"one": "dentro de {0} hora",
				"other": "dentro de {0} horas"
			}
		},
		"minute": {
			"current": "este minuto",
			"past": {
				"one": "hace {0} minuto",
				"other": "hace {0} minutos"
			},
			"future": {
				"one": "dentro de {0} minuto",
				"other": "dentro de {0} minutos"
			}
		},
		"second": {
			"current": "ahora",
			"past": {
				"one": "hace {0} segundo",
				"other": "hace {0} segundos"
			},
			"future": {
				"one": "dentro de {0} segundo",
				"other": "dentro de {0} segundos"
			}
		}
	},
	"short": {
		"year": {
			"previous": "el año pasado",
			"current": "este año",
			"next": "el próximo año",
			"past": "hace {0} a",
			"future": "dentro de {0} a"
		},
		"quarter": {
			"previous": "el trimestre pasado",
			"current": "este trimestre",
			"next": "el próximo trimestre",
			"past": "hace {0} trim.",
			"future": "dentro de {0} trim."
		},
		"month": {
			"previous": "el mes pasado",
			"current": "este mes",
			"next": "el próximo mes",
			"past": "hace {0} m",
			"future": "dentro de {0} m"
		},
		"week": {
			"previous": "la semana pasada",
			"current": "esta semana",
			"next": "la próxima semana",
			"past": "hace {0} sem.",
			"future": "dentro de {0} sem."
		},
		"day": {
			"previous": "ayer",
			"previous-2": "anteayer",
			"current": "hoy",
			"next": "mañana",
			"next-2": "pasado mañana",
			"past": "hace {0} días",
			"future": "dentro de {0} días"
		},
		"hour": {
			"current": "esta hora",
			"past": "hace {0} h",
			"future": "dentro de {0} h"
		},
		"minute": {
			"current": "este minuto",
			"past": "hace {0} min",
			"future": "dentro de {0} min"
		},
		"second": {
			"current": "ahora",
			"past": "hace {0} seg.",
			"future": "dentro de {0} seg."
		}
	},
	"narrow": {
		"year": {
			"previous": "el año pasado",
			"current": "este año",
			"next": "el próximo año",
			"past": "hace {0} a",
			"future": "dentro de {0} a"
		},
		"quarter": {
			"previous": "el trimestre pasado",
			"current": "este trimestre",
			"next": "el próximo trimestre",
			"past": "hace {0} trim.",
			"future": "dentro de {0} trim."
		},
		"month": {
			"previous": "el mes pasado",
			"current": "este mes",
			"next": "el próximo mes",
			"past": "hace {0} m",
			"future": "dentro de {0} m"
		},
		"week": {
			"previous": "la semana pasada",
			"current": "esta semana",
			"next": "la próxima semana",
			"past": "hace {0} sem.",
			"future": "dentro de {0} sem."
		},
		"day": {
			"previous": "ayer",
			"previous-2": "anteayer",
			"current": "hoy",
			"next": "mañana",
			"next-2": "pasado mañana",
			"past": "hace {0} días",
			"future": "dentro de {0} días"
		},
		"hour": {
			"current": "esta hora",
			"past": "hace {0} h",
			"future": "dentro de {0} h"
		},
		"minute": {
			"current": "este minuto",
			"past": "hace {0} min",
			"future": "dentro de {0} min"
		},
		"second": {
			"current": "ahora",
			"past": "hace {0} seg.",
			"future": "dentro de {0} seg."
		}
	}
};