
function refrescarPagina() {
  window.location.reload();
}

const formatoTextoURL = (texto) => {
  return texto.replaceAll(" ","%20").replaceAll("&","	%26").replaceAll("\n","%0A").replaceAll(":","%3A");
};

function calcularPocentaje(newValue, oldValue) {
  return Math.round(((newValue - oldValue) / oldValue) * 100);
}

const obtenerEtiquetaSexo = (sexo) => {
  const valor= sexo ? sexo.toLowerCase() : "";
  switch (valor) {
    case "m":
      return "Masculino";
      break;
    case "f":
      return "Femenino";
      break;
    case "i":
      return "Indefinido";
      break;
    default:
      break;
  }
  return "";
};

export { formatoTextoURL, obtenerEtiquetaSexo, refrescarPagina, calcularPocentaje};