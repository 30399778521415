import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogTitle, Dialog, Select, MenuItem, Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import FilledInput from "@material-ui/core/FilledInput";
import * as ControllerInsumos from "controllers/Insumos"
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";
import { evaluarForm } from "functions/formulario";
import { useLayoutEffect } from "react";

export default function AltaVentaInsumo({ id, mostrarAlta, cerrarPopUPAlta, recargarDatos, valores }) {
    const alert = useAlert();
    const [venta, setVenta] = React.useState({ 'idPaciente': Number(id), 'cantidad': 1 });
    const [idInsumo, setIdInsumo] = React.useState();
    const [insumos, setInsumos] = React.useState([]);
    const camposRequeridos = [{ id: 'idPaciente', nombre: 'Paciente' }, { id: 'cantidad', nombre: 'Cantidad' },];

    const obtenerInsumos = async () => {
        const r = await ControllerInsumos.peticionGetInsumos();
        (r.resultado) ? setInsumos(r.data.registros) : alert.error(r.mensaje);
    }

    const guardarDatos = async e => {
        if (e.target.name !== "idInsumo") {
            await setVenta({
                ...venta,
                [e.target.name]: e.target.value
            });
        } else {
            await setIdInsumo(e.target.value);
        }
    };



    const peticionPost = async () => {
        if (idInsumo && idInsumo > 0) {
            const validarForm = evaluarForm(venta, camposRequeridos);
            if (validarForm.resultado) {
                const r = await ControllerInsumos.peticionPostVenta(idInsumo, venta);
                if (r.resultado) {
                    cerrarPopUPAlta();
                    alert.success(r.mensaje);
                    recargarDatos();
                } else {
                    alert.error(r.mensaje);
                }
            } else {
                alert.error(validarForm.mensaje)
            }
        } else {
            alert.error('Seleccione un Producto');
        }
    };

    useLayoutEffect(() => {
        obtenerInsumos();
    }, []);



    return (
        <Dialog
            maxWidth="xs"
            open={mostrarAlta}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrarPopUPAlta}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="form-dialog-title" >
                <Typography variant="h4">Agregar venta</Typography>
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <FormLabel>Producto</FormLabel>
                        <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                        >
                            <Select
                                labelId="idInsumo"
                                variant="outlined"
                                id="idInsumo"
                                name="idInsumo"
                                onChange={(e) => guardarDatos(e)}
                                placeholder="Producto"
                            >
                                {insumos && insumos.length > 0 && insumos.map(i =>
                                    <MenuItem value={i.idInsumo}>{i.nombre + " ($" + i.precio + ")"}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormGroup>
                            <FormLabel>Cantidad</FormLabel>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="number"
                                    defaultValue={1}
                                    name="cantidad"
                                    key="cantidad"
                                    onChange={(e) => guardarDatos(e)}
                                    inputProps={{min: 1,max:9999999999}}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                        <Button color="primary" variant="contained" onClick={() => peticionPost()}>
                            Confirmar
                        </Button>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}