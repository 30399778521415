import { queryGet, queryPost, queryDelete } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Periodontograma/";

export async function peticionGetPeriodontogramas(idPaciente) {
  return queryGet(url + idPaciente);
}

export async function peticionGetPeriodontograma(idPaciente, idPeriodontograma) {
  return queryGet(`${url}${idPaciente}/${idPeriodontograma}`);
}

export async function peticionPostPeriodontograma(idPaciente, elementoNuevo) {
  return queryPost(url + idPaciente, elementoNuevo);
}

export async function peticionDeletePeriodontograma(idPaciente,idElemento) {
  return queryDelete(url + idPaciente + "/" + idElemento);
}