const initialState = {
    sesion: {
        token:null,
        login: null,
        forzarCambioDeClave: false
    },
    usuario:{
        id:null,
        usuario:"",
        nombre:"",
        apellido:"",
        roles:[],
        permisos:[],
    },
    paises: [],
    tiposDocumentos:[],
    tiposDocumentacion: [],
    piezas: [],
    caras: [],
    roles: [],
    tiposUsuario: [],
    carga: false,
}

export default initialState;