import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';

export default function AlertaDialogo({ titulo, texto, id, funcionConfirmar, abierto, funcionCerrar}) {

  return (
    <div>
      <Dialog
        open={abierto}
        onClose={funcionCerrar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h2">{titulo}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <span style={{ whiteSpace: 'pre-line' }}>{texto ? texto: 'Seleccione confirmar para continuar con la solicitud.'}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {funcionCerrar && <Button color="secondary" variant="contained" onClick={funcionCerrar}>Cerrar</Button>}
          <Button color="primary" variant="contained" onClick={e => funcionConfirmar(id)}  autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}