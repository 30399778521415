import axios from "axios";
import { store } from '../redux/store';

function evaluarResultado(resultado) {
  let r = {
    "codigo": 500,
    "resultado": false,
    "mensaje": "No se pudo realizar la solicitud, intente nuevamente.",
    "data": {}
  }
  if (resultado.status != undefined && Object.keys(resultado).length > 0) {
    r.codigo = resultado.data.codigo;
    r.mensaje = resultado.data.mensaje;
    r.data = resultado.data.data;
  }
  switch (r.codigo) {
    case -10:
      r.mensaje = "Su contraseña expirò, debe de cambiarla antes de poder hacer cualquier otra acciòn";
      r.resultado = false;
      break;
    case -7:
      store.dispatch({ "type": "AVISO_CARGANDO", "data": false });
      store.dispatch({ "type": "LOGOUT" });
      break;
    case 1:
      r.resultado = true;
      break;
    case 0:
      r.resultado = true;
      break;
    case 200:
      r.resultado = true;
      break;
    case 304:
      r.resultado = true;
      break;
    default:
      break;
  }
  store.dispatch({ "type": "AVISO_CARGANDO", "data": false });
  return r;
}

function obtenerToken() {
  const redux = store.getState();
  return redux.sesion.token;
}

function obtenerHeaderConBody(body) {
  return { headers: { 'Authorization': 'Bearer ' + obtenerToken(), 'Content-Type': 'application/json' }, data: body };
}

function obtenerHeader() {
  return { headers: { 'Authorization': 'Bearer ' + obtenerToken(), 'Content-Type': 'application/json' } };
}

function obtenerHeaderForm() {
  return { headers: { 'Authorization': 'Bearer ' + obtenerToken() } };
}

async function queryGet(url) {
  const r = await axios.get(url, obtenerHeader()).then(r => {
    return r;
  }).catch(e => {
    return e.toJSON();
  })
  return evaluarResultado(r);
}

async function queryPost(url, body) {
  store.dispatch({ "type": "AVISO_CARGANDO", "data": true });
  const r = await axios.post(url, body, obtenerHeader()).then(r => {
    return r;
  }).catch(e => {
    return e.toJSON();
  })
  return evaluarResultado(r);
}

async function queryPostForm(url, body) {
  store.dispatch({ "type": "AVISO_CARGANDO", "data": true });
  const r = await axios.post(url, body, obtenerHeaderForm()).then(r => {
    return r;
  }).catch(e => {
    return e.toJSON();
  })
  return evaluarResultado(r);
}

async function queryPut(url, body) {
  store.dispatch({ "type": "AVISO_CARGANDO", "data": true });
  const r = await axios.put(url, body, obtenerHeader()).then(r => {
    return r;
  }).catch(e => {
    return e.toJSON();
  })
  return evaluarResultado(r);
}

async function queryPatch(url, body) {
  store.dispatch({ "type": "AVISO_CARGANDO", "data": true });
  const r = await axios.patch(url, body, obtenerHeader()).then(r => {
    return r;
  }).catch(e => {
    return e.toJSON();
  })
  return evaluarResultado(r);
}

async function queryDeleteBody(url, body) {
  store.dispatch({ "type": "AVISO_CARGANDO", "data": true });
  const r = await axios.delete(url, obtenerHeaderConBody(body)).then(r => {
    return r;
  }).catch(e => {
    return e;
  })
  return evaluarResultado(r);
}

async function queryDelete(url) {
  store.dispatch({ "type": "AVISO_CARGANDO", "data": true });
  const r = await axios.delete(url, obtenerHeader()).then(r => {
    return r;
  }).catch(e => {
    return e;
  })
  return evaluarResultado(r);
}

export { queryGet, queryPatch, queryPost, queryPostForm, queryPut, queryDeleteBody, queryDelete }