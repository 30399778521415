import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";

const Detalle = props => {
    const texto = props.texto;
    return (
        <Tooltip title={texto}>
            <IconButton>
                <InfoOutlined />
            </IconButton>
        </Tooltip>
    );
};

export default Detalle;