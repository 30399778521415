import { queryGet, queryPost, queryDelete, queryPut } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Tratamiento/";
const cantidadPagina= 10;

export function peticionGetTratamientos(pagina) {
  let paginado="";
  if(pagina)
    paginado = `?pagina=${pagina}&cantidad=${cantidadPagina}`;
  return queryGet(url  + paginado);
}

export function peticionGetCategorias() {
  return queryGet(url  + "categorias");
}

export function peticionPostTratamiento(elemento) {
  return queryPost(url, elemento);
}

export function peticionDeleteTratamiento(id) {
  return queryDelete(url  + id);
}

export function peticionPutTratamiento(id, elemento) {
  return queryPut(url  + id, elemento);
}

export function peticionGetTratamiento(id) {
  return queryGet(url + id);
}

export function peticionPostBuscar(elemento) {
  return queryPost(url + "buscar/", elemento);
}