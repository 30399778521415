import React from "react";
import { TableRow, TableCell } from "@material-ui/core";

const SinRegistros = ({ espacio, mensajePersonalizado }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={espacio}>
        {mensajePersonalizado ? mensajePersonalizado : 'No se encontraron registros'}
      </TableCell>
    </TableRow>
  );
};

export default SinRegistros;
