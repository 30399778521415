import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as ControllerPacientes from 'controllers/Pacientes';
import { formatoFecha } from 'functions/fechas'
import { Delete, FileCopyRounded, Visibility } from '@material-ui/icons';
import UserHeader from "components/Headers/UserHeader.js";
import { CardActionArea, CircularProgress, IconButton } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import FolderIcon from '@material-ui/icons/Folder';
import { useDispatch, useSelector } from "react-redux";
import componentStyles from "assets/theme/components/admin-footer";
import { useAlert } from "react-alert";
import Perfil_Paciente from "components/Perfil/Perfil_Paciente";
import { ObtenerTiposDocumentacion } from "functions/datosSistema";
import AltaArchivo from "./datos/popup/altaArchivo";
import ZoomArchivo from "./datos/popup/zoomArchivo";
import { convertirTimezone } from "functions/fechas";

const useStyles = makeStyles(componentStyles);

function Documentos() {
  let { id } = useParams();
  const classes = useStyles();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [modalZoom, setModalZoom] = React.useState(false);
  const [modalNuevo, setModalNuevo] = React.useState(false);
  const [documentoZoom, setDocumentoZoom] = React.useState();
  const [paciente, setPaciente] = React.useState([]);
  const [documentos, setDocumentos] = React.useState([]);
  const [documentosMostrados, setDocumentosMostrados] = React.useState();
  const [tipoSeleccionado, setTipoSeleccionado] = React.useState(-1);
  const tiposDocumentacion = useSelector(state => state.tiposDocumentacion);
  const permisos = useSelector((state) => state.usuario && state.usuario.permisos ? state.usuario.permisos.documento : []);

  const cerrarModal = () => {
    setModalZoom(false);
    setModalNuevo(false);
  }

  const abrirModalZoom = (documento) => {
    setDocumentoZoom(documento);
    setModalZoom(true);
  };

  const abrirNuevo = () => {
    setModalNuevo(true);
  };

  const peticionDelete = async (idDocumentacion) => {
    const r = await ControllerPacientes.peticionDeleteDocumento(id, idDocumentacion);
    if (r.resultado) {
      obtenerDocumentos();
      alert.success(r.mensaje);
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
        obtenerDocumentos();
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const obtenerNombreTipo = (idBuscado) => {
    for (let i = 0; i < tiposDocumentacion.length; i++) {
      if (tiposDocumentacion[i].idDocumentacionTipo === idBuscado)
        return tiposDocumentacion[i].nombre;
    }
    return "General";
  }

  const obtenerDocumentos = async () => {
    const r = await ControllerPacientes.peticionGetDocumentos(id);
    if (r.resultado) {
      setDocumentos(r.data);
      setDocumentosMostrados(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerDocumentacionTipos = async () => {
    if (tiposDocumentacion && tiposDocumentacion.length === 0) {
      const r = await ObtenerTiposDocumentacion();
      dispatch({ "type": "CARGAR_TIPOS_DOCUMENTACION", "data": r });
    }
  }

  useLayoutEffect(() => {
    if (tipoSeleccionado !== -1) {
      setDocumentosMostrados(documentos.filter((d) => d.idDocumentacionTipo == tipoSeleccionado));
    } else {
      setDocumentosMostrados(documentos);
    }
  }, [tipoSeleccionado]);

  useLayoutEffect(() => {
    obtenerPaciente();
    obtenerDocumentacionTipos();
  }, []);


  return (
    <>
      <UserHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={8}
            style={{ "marginBottom": "3rem" }}
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Gestión de Documentos
                      </Box>
                    </Grid>
                    {permisos && permisos.subir &&
                      <Grid item xs="auto">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={abrirNuevo}
                        >
                          Subir
                        </Button>
                      </Grid>}
                  </Grid>
                } classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} lg={3}>
                    <Box
                      component={Typography}
                      variant="h3"
                    >
                      Secciones
                    </Box>
                    <Divider />
                    <ListItem button selected={-1 === tipoSeleccionado} onClick={t => setTipoSeleccionado(-1)}>
                      <ListItemIcon>
                        <ListItemText><FolderIcon /> Todos</ListItemText>
                      </ListItemIcon>
                    </ListItem>
                    <List component="nav" aria-label="main mailbox folders">
                      {
                        tiposDocumentacion.map(t =>
                          <ListItem button selected={t.idDocumentacionTipo === tipoSeleccionado} onClick={e => setTipoSeleccionado(t.idDocumentacionTipo)}>
                            <ListItemIcon>
                              <ListItemText><FolderIcon /> {t.nombre}</ListItemText>
                            </ListItemIcon>
                          </ListItem>
                        )
                      }
                    </List>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <Grid container>
                      {!documentosMostrados ? <CircularProgress /> :
                        documentosMostrados.length < 1 ? <p>No se encontraron documentos</p> :
                          documentosMostrados.map(documento => {
                            return (<Grid item xl={6} lg={6} xs={6} style={{ "marginBottom": "1rem" }}>
                              <Card className={classes.root}>
                                <CardActionArea>
                                  <CardContent>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                      <Grid item xs={12} lg={7} style={{ "padding": 0, "margin": 0 }}>
                                        <Typography variant="h4">
                                          <FileCopyRounded /> {documento.titulo}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} lg={5} style={{ "padding": 0, "margin": 0 }}>
                                        {permisos && permisos.eliminar &&
                                          <IconButton aria-label="delete" onClick={d => peticionDelete(documento.idDocumentacion)}>
                                            <Delete />
                                          </IconButton>}
                                        {permisos && permisos.obtener &&
                                          <IconButton aria-label="delete" onClick={b => abrirModalZoom(documento)}>
                                            <Visibility />
                                          </IconButton>}
                                      </Grid>
                                    </Grid>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                      Categoría: {obtenerNombreTipo(documento.idDocumentacionTipo)}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                      Subido: {formatoFecha(convertirTimezone(documento.fechaReal, 'GMT-3'), "DD/MM/YYYY HH:MM")}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          )})
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Perfil_Paciente paciente={paciente} tipo={'citas'} />
        </Grid>
      </Container>


      {modalZoom && <ZoomArchivo mostrar={modalZoom} cerrar={cerrarModal} registro={documentoZoom} id={id} />}
      {modalNuevo && <AltaArchivo mostrar={modalNuevo} cerrar={cerrarModal} registros={tiposDocumentacion} id={id} recargar={obtenerDocumentos} />}
    </>
  );
}

export default Documentos;