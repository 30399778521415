import { mode, colors, fonts } from "estilos/graficas";
import { Grafica } from "components/Graficas/Grafica";
import { obtenerMes } from "./fechas";

export const etiquetasUltimosSeisMeses = () => {
    return [obtenerMes(6), (5), obtenerMes(4), obtenerMes(3), obtenerMes(2), obtenerMes(1)];
};

export function parseOptions(parent, options) {
    for (var item in options) {
        if (typeof options[item] !== "object") {
            parent[item] = options[item];
        } else {
            parseOptions(parent[item], options[item]);
        }
    }
}

export const generarDatosGraficaLineas = (etiquetas, datoPrimario, datoSecundario, tituloPrimario, tituloSecundario) => {
    return {
        'grafica1': {
            labels: etiquetas,
            datasets: [{ label: tituloPrimario, backgroundColor: 'green', borderColor: 'green', data: datoPrimario },
            { label: tituloSecundario, backgroundColor: 'red', borderColor: 'red', data: datoSecundario }]
        },
        'grafica2': {
            labels: etiquetas,
            datasets: [{
                label: tituloSecundario, backgroundColor: 'red', borderColor: 'red',
                data: datoSecundario,
            }],
        },
    }
};

export const generarDatosGraficaBarras = (etiquetas, datoPrimario, datoSecundario, tituloPrimario, tituloSecundario) => {
    return {
        labels: etiquetas,
        datasets: [
            { label: tituloPrimario, backgroundColor: 'skyblue', data: datoPrimario, maxBarThickness: 20, },
            { label: tituloSecundario, backgroundColor: 'gray', data: datoSecundario, maxBarThickness: 20, },
        ],
    };
};

export const generarDatosGraficaTorta = (etiquetas, datos, titulo) => {
    return {
        labels: etiquetas,
        datasets: [
            { label: titulo, backgroundColor: ['pink', 'green', 'skyblue',], data: datos, hoverOffset: 4 },
        ],
    };
};

// Chart.js global options
export function opcionesGlobales() {
    // Options
    var options = {
        defaults: {
            global: {
                responsive: true,
                maintainAspectRatio: false,
                defaultColor: mode === "dark" ? colors.gray[700] : colors.gray[600],
                defaultFontColor: mode === "dark" ? colors.gray[700] : colors.gray[600],
                defaultFontFamily: fonts.base,
                defaultFontSize: 13,
                layout: {
                    padding: 0,
                },
                legend: {
                    display: false,
                    position: "bottom",
                    labels: {
                        usePointStyle: true,
                        padding: 16,
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                        backgroundColor: colors.theme["primary"],
                    },
                    line: {
                        tension: 0.4,
                        borderWidth: 4,
                        borderColor: colors.theme["primary"],
                        backgroundColor: colors.transparent,
                        borderCapStyle: "rounded",
                    },
                    rectangle: {
                        backgroundColor: colors.theme["warning"],
                    },
                    arc: {
                        backgroundColor: colors.theme["primary"],
                        borderColor: mode === "dark" ? colors.gray[800] : colors.white,
                        borderWidth: 4,
                    },
                },
                tooltips: {
                    enabled: true,
                    mode: "index",
                    intersect: false,
                },
            },
            doughnut: {
                cutoutPercentage: 83,
                legendCallback: function (chart) {
                    var data = chart.data;
                    var content = "";

                    data.labels.forEach(function (label, index) {
                        var bgColor = data.datasets[0].backgroundColor[index];

                        content += '<span className="chart-legend-item" >';
                        content +=
                            '<i className="chart-legend-indicator" style="background-color: ' +
                            bgColor +
                            '"></i>';
                        content += label;
                        content += "</span>";
                    });

                    return content;
                },
            },
        },
    };

    // yAxes
    Grafica.scaleService.updateScaleDefaults("linear", {
        gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: mode === "dark" ? colors.gray[900] : colors.gray[300],
            drawBorder: false,
            drawTicks: false,
            lineWidth: 0,
            zeroLineWidth: 0,
            zeroLineColor: mode === "dark" ? colors.gray[900] : colors.gray[300],
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
        },
        ticks: {
            beginAtZero: true,
            padding: 10,
            callback: function (value) {
                if (!(value % 10)) {
                    return value;
                }
            },
        },
    });

    // xAxes
    Grafica.scaleService.updateScaleDefaults("category", {
        gridLines: {
            drawBorder: false,
            drawOnChartArea: false,
            drawTicks: false,
        },
        ticks: {
            padding: 20,
        },
    });

    return options;
}