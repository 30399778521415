import React from 'react';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';
import ImageMapper from 'react-img-mapper';

export function ArcadaSuperior({seleccion, limpiar}) {
  const componente = useRef(null);

  const limpiarSeleccion = () => {
    componente.current.clearHighlightedArea();
  };

  useLayoutEffect(() => {
    if(limpiar)
      limpiarSeleccion();
  }, [limpiar]);

  const MapaArcadaSuperior = {
    name: 'ArcadaSuperior',
    areas: [
      { "id": "superior2", "title": "2", "shape": "circle", "name": "2", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [19,114,14],},
      { "id": "superior3", "title": "3", "shape": "circle", "name": "3", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [25,89,14],},
      { "id": "superior4", "title": "4", "shape": "circle", "name": "4", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [34,67,10],},
      { "id": "superior5", "title": "5", "shape": "circle", "name": "5", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [41,49,10],},
      { "id": "superior6", "title": "6", "shape": "circle", "name": "6", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [52,32,10],},
      { "id": "superior7", "title": "7", "shape": "circle", "name": "7", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [68,20,10],},
      { "id": "superior8", "title": "8", "shape": "circle", "name": "8", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [88,14,10],},
      { "id": "superior9", "title": "9", "shape": "circle", "name": "9", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [109,14,10],},
      { "id": "superior10", "title": "10", "shape": "circle", "name": "10", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [130,20,10],},
      { "id": "superior11", "title": "11", "shape": "circle", "name": "11", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [147,31,10],},
      { "id": "superior12", "title": "12", "shape": "circle", "name": "12", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [156,49,10],},
      { "id": "superior1", "title": "1", "shape": "circle", "name": "1", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [22,140,14],},
      { "id": "superior13", "title": "13", "shape": "circle", "name": "13", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [164,66,10],},
      { "id": "superior14", "title": "14", "shape": "circle", "name": "14", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [173,90,14],},
      { "id": "superior15", "title": "15", "shape": "circle", "name": "15", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [179,115,14],},
      { "id": "superior16", "title": "16", "shape": "circle", "name": "16", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [177,140,14],},
    ],
  };
  
  return <ImageMapper height={171} width={200} containerRef={componente} key={"ArcadaSuperior"} stayHighlighted natural src={require("assets/img/icons/common/dental_arch_upper.png").default} map={MapaArcadaSuperior} onClick={(e)=> seleccion(e, "superior")}/>
}