import React from "react";
import { CircularProgress, TableRow, TableCell } from "@material-ui/core";

const TablaCarga = ({ espacio }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={espacio}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );
};

export default TablaCarga;
