import { queryGet } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Sistema/";

export function peticionGetDocumentos() {
  return queryGet(url + "documentos/");
}

export function peticionGetPaises() {
  return queryGet(url + "paises/");
}

export function peticionGetPiezas() {
  return queryGet(url + "piezas/");
}

export function peticionGetCaras() {
  return queryGet(url + "cara/");
}

export function peticionGetTiposDocumentacion() {
  return queryGet(url + "tiposDocumentacion/");
}