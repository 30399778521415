import React from "react";
import boxShadows from "assets/theme/box-shadow";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { estiloPerfil } from "estilos/clases.js";

function Foto({ avatar }) {
  const classes = estiloPerfil();

  return (
    <Grid
      item
      xs={12}
      xl={3}
      component={Box}
      marginBottom="3rem!important"
      classes={{ root: classes.order1 + " " + classes.marginBottomXl0 }}
    >
      <Card classes={{ root: classes.cardRoot }}>
        <Box component={Grid} container justifyContent="center">
          <Grid item xs={12} lg={3}>
            <Box position="relative">
              <Box
                component="img"
                src={
                  avatar ? URL.createObjectURL(avatar) : require("assets/img/theme/avatar.png").default
                }
                alt="Fotografia del usuario"
                maxWidth="230px"
                maxHeight="230px"
                borderRadius="50%"
                position="absolute"
                left="50%"
                boxShadow={boxShadows.boxShadow + "!important"}
                className={classes.profileImage}
              />
            </Box>
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
}

export default Foto;