import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import { Grid } from "@material-ui/core";
import { EstiloFicha } from 'estilos/agenda';
import { EstiloEstado } from 'estilos/agenda';
import { EstiloDetalle } from 'estilos/agenda';
import { EstiloCelda } from "estilos/agenda";
import { EstiloConsultorio } from "estilos/agenda";
import { EstiloProfesional } from "estilos/agenda";

export const ResumenCita = (({
  children, appointmentData, ...restProps
}) => (
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData} >
      <Grid container alignItems="center">
        <EstiloCelda item xs={2} className={'Demo-textCenter'}>
          <EstiloEstado className={'Demo-icon'} />
        </EstiloCelda>
        <Grid item xs={10}>
          <span style={{ fontWeight: 'bold' }}>{appointmentData.reservaEstado && appointmentData.reservaEstado.nombre ? appointmentData.reservaEstado.nombre : "Desconocido"}</span>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <EstiloCelda item xs={2} className={'Demo-textCenter'}>
          <EstiloConsultorio className={'Demo-icon'} />
        </EstiloCelda>
        <Grid item xs={10}>
          <span>{appointmentData.consultorio && appointmentData.consultorio.nombre ? appointmentData.consultorio.nombre : "Desconocido"}</span>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <EstiloCelda item xs={2} className={'Demo-textCenter'}>
          <EstiloProfesional className={'Demo-icon'} />
        </EstiloCelda>
        <Grid item xs={10}>
          <span>{appointmentData.medico && appointmentData.medico.nombre1 && appointmentData.medico.apellido1 ? `${appointmentData.medico.nombre1} ${appointmentData.medico.apellido1}` : "Desconocido"}</span>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <EstiloCelda item xs={2} className={'Demo-textCenter'}>
          <EstiloDetalle className={'Demo-icon'} />
        </EstiloCelda>
        <Grid item xs={10}>
          <span>{appointmentData.detalle ? appointmentData.detalle : "No hay detalle extra"}</span>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <EstiloCelda item xs={2} className={'Demo-textCenter'}>
          <EstiloFicha className={'Demo-icon'} />
        </EstiloCelda>
        <Grid item xs={10}>
          <a target="_blank" href={appointmentData.paciente && appointmentData.paciente.idPaciente ? `/app/pacientes/ficha/${appointmentData.paciente.idPaciente}` : '/app/pacientes/listado'}>Ver ficha</a>
        </Grid>
      </Grid>
    </AppointmentTooltip.Content>
));