import { queryGet, queryPost, queryDelete, queryPut } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Insumo/";
const cantidadPagina= 10;

export function peticionGetInsumos(pagina) {
  let paginado="";
  if(pagina)
    paginado = `?pagina=${pagina}&cantidad=${cantidadPagina}`;
  return queryGet(url  + paginado);
}

export function peticionGetCategorias() {
  return queryGet(url  + "categorias");
}

export function peticionPostInsumo(elemento) {
  return queryPost(url, elemento);
}

export function peticionDeleteInsumo(id) {
  return queryDelete(url  + id);
}

export function peticionPutInsumo(id, elemento) {
  return queryPut(url  + id, elemento);
}

export function peticionGetInsumo(id) {
  return queryGet(url + id);
}

export function peticionPostVenta(id, elemento) {
  return queryPost(url + id + "/venta", elemento);
}

export function peticionPostBuscar(elemento) {
  return queryPost(url + "buscar/", elemento);
}