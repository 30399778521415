import Box from "@material-ui/core/Box";
import { Line } from "react-chartjs-2";

//Opciones
const configuracion = {
    options: {
        responsive: false,
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        display: false,
                        max: 10,
                        min: -23
                    },
                    gridLines: {
                        display: false
                    }
                },
            ],
            xAxes: [
                {
                    ticks: {
                        display: false,

                    },
                    gridLines: {
                        display: false
                    }
                },
            ],
        },

    },
};


export default function GraficaLineasPiezaInferior({ id, datos, imagen }) {
    const dataGenerada= {
        labels: ['','',''],
        datasets: [{ label: "a", backgroundColor: 'red', borderColor: 'red', data: datos.primario },
        { label: "b", backgroundColor: 'blue', borderColor: 'blue', data: datos.secundario }]
    };
    
    return (
        <Box>
            <Line
                key={id}
                data={dataGenerada}
                options={configuracion.options}
                height={105}
                width={52}
                redraw={true}
                plugins={[{
                    beforeDraw: chart => {
                        var ctx = chart.chart.ctx;
                        ctx.save();
                        var image = new Image();
                        image.src = require(`assets/img/icons/dientes/${imagen}.png`).default;
                        ctx.drawImage(image, 3, 0, 40, 100);
                        ctx.restore();
                    },
                }]}
            />
        </Box>
    );
};