import Inicio from "views/usuario/inicio.js";
import Restringido from "views/usuario/restringido.js";
import Inexistente from "views/usuario/inexistente.js";
import Perfil from "views/usuario/perfil.js";
import Equipo from "views/equipo/listado.js";
import Usuario from "views/equipo/usuario.js";
import UsuarioDatosPersonales from "views/equipo/datos/personales.js";
import UsuarioDatosAvatar from "views/equipo/datos/avatar.js";
import UsuarioDatosEspecialidades from "views/equipo/datos/especialidades.js";
import UsuarioDatosRoles from "views/equipo/datos/roles.js";
import Agenda from "views/agenda/agenda.js";
import Logs from "views/registros/log.js";
import Errores from "views/registros/sistema.js";
import Notificaciones from "views/notificaciones/listado.js";
import Pacientes from "views/pacientes/listado.js";
import Paciente from "views/pacientes/paciente.js";
import Diagnosticos from "views/diagnosticos/listado.js";
import Diagnostico from "views/diagnosticos/diagnostico.js";
import PacienteDatosPersonales from "views/pacientes/datos/personales.js";
import PacienteDatosClinicos from "views/pacientes/datos/clinicos.js";
import PacienteDatosContactos from "views/pacientes/datos/contactos.js";
import PacienteDatosAvatar from "views/pacientes/datos/avatar.js";
import PacientePeriodontogramas from "views/pacientes/periodontogramas/listado.js";
import PacientePeriodontograma from "views/pacientes/periodontogramas/grafico.js";
import Odontograma from "views/pacientes/odontograma.js";
import Cuenta from "views/pacientes/cuenta.js";
import Ficha from "views/pacientes/ficha.js";
import Archivo from "views/pacientes/archivo.js";
import Dashboard from "views/dashboard/general.js";
import Administracion from "views/administracion/opciones.js";
import Configuracion from "views/configuracion/opciones.js";
import ConfiguracionRegistros from "views/configuracion/registros.js";
import Tratamientos from "views/tratamientos/listado.js";
import Tratamiento from "views/tratamientos/tratamiento.js";
import Consultorios from "views/consultorios/listado.js";
import Consultorio from "views/consultorios/consultorio.js";
import Insumos from "views/insumos/listado.js";
import Insumo from "views/insumos/insumo.js";
import Mutualistas from "views/mutualistas/listado.js";
import Mutualista from "views/mutualistas/mutualista.js";
import Emergencias from "views/emergencias/listado.js";
import Emergencia from "views/emergencias/emergencia.js";
import Patologias from "views/patologias/listado.js";
import Patologia from "views/patologias/patologia.js";
import Alergias from "views/alergias/listado.js";
import Alergia from "views/alergias/alergia.js";
import Medicamentos from "views/medicamentos/listado.js";
import Medicamento from "views/medicamentos/medicamento.js";
import Person from "@material-ui/icons/Person";
import Tv from "@material-ui/icons/Tv";
import { InsertInvitation, People, PeopleOutline, Settings, TuneOutlined } from "@material-ui/icons";
import { rolesPredefinidos } from "datos/permisos";

var routes = [
  {
    path: "/inicio",
    name: "Inicio",
    icon: Person,
    iconColor: "WarningLight",
    component: Inicio,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.todos]
  },
  {
    path: "/restringido",
    name: "Acceso restringido",
    icon: Person,
    iconColor: "WarningLight",
    component: Restringido,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.todos]
  },
  {
    path: "/inexistente",
    name: "No encontrado",
    icon: Person,
    iconColor: "WarningLight",
    component: Inexistente,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.todos]
  },
  {
    path: "/usuario/perfil/",
    name: "Usuario | Perfil",
    icon: Person,
    iconColor: "WarningLight",
    component: Perfil,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.todos]
  },
  {
    path: "/agenda",
    name: "Agenda",
    icon: InsertInvitation,
    iconColor: "Primary",
    component: Agenda,
    layout: "/app",
    show: true,
    allowedRoles: [rolesPredefinidos.todos]
  },
  {
    path: "/pacientes/paciente/:id?",
    pathRoot: "/pacientes/paciente/",
    name: "Paciente | Datos personales",
    component: Paciente,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor, rolesPredefinidos.doctor, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/datos/personales/:id?",
    pathRoot: "/pacientes/datos/personales/",
    name: "Paciente | Datos personales",
    component: PacienteDatosPersonales,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/datos/clinicos/:id",
    pathRoot: "/pacientes/datos/clinicos/",
    name: "Paciente | Datos médicos",
    component: PacienteDatosClinicos,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/datos/contactos/:id",
    pathRoot: "/pacientes/datos/contactos/",
    name: "Paciente | Contactos",
    component: PacienteDatosContactos,
    layout: "/app",
    show: false,
    allowedRoles:[rolesPredefinidos.administrador, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/datos/avatar/:id",
    pathRoot: "/pacientes/datos/avatar/",
    name: "Paciente | Avatar",
    component: PacienteDatosAvatar,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/archivo/:id?",
    pathRoot: "/pacientes/archivo/",
    name: "Paciente | Archivo",
    icon: Person,
    iconColor: "WarningLight",
    component: Archivo,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor, rolesPredefinidos.doctor, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/cuenta/:id?",
    pathRoot: "/pacientes/cuenta/",
    name: "Paciente | Cuenta corriente",
    icon: Person,
    iconColor: "WarningLight",
    component: Cuenta,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor, rolesPredefinidos.cajero, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/ficha/:id?",
    pathRoot: "/pacientes/ficha/",
    name: "Paciente | Ficha médica",
    icon: Person,
    iconColor: "WarningLight",
    component: Ficha,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor, rolesPredefinidos.doctor, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/odontograma/:id?",
    pathRoot: "/pacientes/odontograma/",
    name: "Paciente | Odontograma",
    icon: Person,
    iconColor: "WarningLight",
    component: Odontograma,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.doctor]
  },
  {
    path: "/pacientes/periodontogramas/grafico/:idPaciente/:idPeriodontograma?",
    pathRoot: "/pacientes/periodontogramas/grafico/",
    name: "Paciente | Periodontograma",
    icon: Person,
    iconColor: "WarningLight",
    component: PacientePeriodontograma,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor]
  },
  {
    path: "/pacientes/periodontogramas/listado/:id?",
    pathRoot: "/pacientes/periodontogramas/listado/",
    name: "Paciente | Periodontogramas",
    icon: Person,
    iconColor: "WarningLight",
    component: PacientePeriodontogramas,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor]
  },
  {
    path: "/registros/log",
    name: "Registros | Log",
    icon: Person,
    iconColor: "WarningLight",
    component: Logs,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.auditor]
  },
  {
    path: "/registros/sistema",
    name: "Registros | Sistema",
    icon: Person,
    iconColor: "WarningLight",
    component: Errores,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.auditor]
  },
  {
    path: "/dashboard/general",
    name: "Dashboard",
    headerTitle: "Dashboard diario",
    icon: Tv,
    iconColor: "WarningLight",
    component: Dashboard,
    layout: "/app",
    show: true,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/listado/:busqueda?",
    pathRoot: "/pacientes/listado/",
    name: "Pacientes",
    icon: People,
    iconColor: "Info",
    component: Pacientes,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor, rolesPredefinidos.administrativo]
  },
  {
    path: "/pacientes/listado/",
    pathRoot: "/pacientes/listado",
    name: "Pacientes",
    icon: People,
    iconColor: "Info",
    component: Pacientes,
    layout: "/app",
    show: true,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor,rolesPredefinidos.administrativo, rolesPredefinidos.doctor, rolesPredefinidos.cajero]
  },
  {
    path: "/equipo/listado",
    name: "Equipo",
    icon: PeopleOutline,
    iconColor: "PrimaryLight",
    component: Equipo,
    layout: "/app",
    show: true,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor, rolesPredefinidos.administrativo]
  },
  {
    path: "/equipo/usuario/:id?",
    pathRoot: "/equipo/usuario/",
    name: "Equipo | Usuario",
    icon: Person,
    iconColor: "WarningLight",
    component: Usuario,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor, rolesPredefinidos.administrativo]
  },
  {
    path: "/equipo/datos/personales/:id?",
    pathRoot: "/equipo/datos/personales/",
    name: "Usuario | Datos personales",
    component: UsuarioDatosPersonales,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/equipo/datos/roles/:id",
    pathRoot: "/equipo/datos/roles/",
    name: "Usuario | Roles de seguridad",
    component: UsuarioDatosRoles,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/equipo/datos/especialidades/:id",
    pathRoot: "/equipo/datos/especialidades/",
    name: "Usuario | Especialidades",
    component: UsuarioDatosEspecialidades,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/equipo/datos/avatar/:id",
    pathRoot: "/equipo/datos/avatar/",
    name: "Usuario | Avatar",
    component: UsuarioDatosAvatar,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/tratamientos/listado",
    name: "Tratamientos",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Tratamientos,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo,rolesPredefinidos.auditor,rolesPredefinidos.cajero]
  },
  {
    path: "/tratamientos/tratamiento/:id?",
    pathRoot: "/tratamientos/tratamiento/",
    name: "Tratamiento",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Tratamiento,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo]
  },

  {
    path: "/diagnosticos/listado",
    name: "Diagnósticos",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Diagnosticos,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador, rolesPredefinidos.auditor]
  },
  {
    path: "/diagnosticos/diagnostico/",
    pathRoot: "/diagnosticos/diagnostico/",
    name: "Diagnóstico",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Diagnostico,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/consultorios/consultorio/:id?",
    pathRoot: "/consultorios/consultorio/",
    name: "Consultorio",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Consultorio,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/consultorios",
    name: "Consultorios",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Consultorios,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/insumos/insumo/:id?",
    pathRoot: "/insumos/insumo/",
    name: "Insumo",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Insumo,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo, rolesPredefinidos.doctor]
  },
  {
    path: "/insumos",
    name: "Insumos",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Insumos,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo,rolesPredefinidos.cajero]
  },
  {
    path: "/mutualistas/mutualista/:id?",
    pathRoot: "/mutualistas/mutualista/",
    name: "Mutualista",
    component: Mutualista,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/mutualistas",
    name: "Mutualistas",
    component: Mutualistas,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/patologias/patologia/:id?",
    pathRoot: "/patologias/patologia/",
    name: "Patología",
    component: Patologia,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/patologias",
    name: "Patologias",
    component: Patologias,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/emergencias/emergencia/:id?",
    pathRoot: "/emergencias/emergencia/",
    name: "Emergencia",
    component: Emergencia,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/emergencias",
    name: "Emergencias",
    component: Emergencias,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/alergias/alergia/:id?",
    pathRoot: "/alergias/alergia/",
    name: "Alergia",
    component: Alergia,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/alergias",
    name: "Alergias",
    component: Alergias,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/medicamentos/medicamento/:id?",
    pathRoot: "/medicamentos/medicamento/",
    name: "Medicamento",
    component: Medicamento,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/medicamentos",
    name: "Medicamentos",
    component: Medicamentos,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador]
  },
  {
    path: "/notificaciones/listado",
    name: "Notificaciones",
    icon: People,
    iconColor: "Info",
    component: Notificaciones,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo]
  },
  {
    path: "/administracion/opciones",
    name: "Administración",
    icon: TuneOutlined,
    iconColor: "ErrorLight",
    component: Administracion,
    layout: "/app",
    show: true,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.administrativo, rolesPredefinidos.cajero]
  },
  {
    path: "/configuracion/opciones",
    name: "Configuración",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: Configuracion,
    layout: "/app",
    show: true,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.auditor]
  },
  {
    path: "/configuracion/registros",
    name: "Configuración | Registros",
    icon: Settings,
    iconColor: "PrimaryLight",
    component: ConfiguracionRegistros,
    layout: "/app",
    show: false,
    allowedRoles: [rolesPredefinidos.administrador,rolesPredefinidos.auditor]
  },
];
export default routes;
