import React, { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import * as ControllerPatologias from 'controllers/Patologias';
import { useAlert } from "react-alert";
import MenuTabla from "components/Tabla/MenuTabla";
import Historial from "components/Tabla/Historial";
import AlertaDialogo from "components/Popups/AlertaDialogo";
import { useSelector } from "react-redux";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";
import { convertirTimezone } from "functions/fechas";
import { acortarTexto } from "functions/formulario";

const useStyles = makeStyles(componentStyles);

const Patologias = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [patologias, setPatologias] = React.useState();
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [idEliminar, setIdEliminar] = React.useState();
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.patologia : []);

  const peticionDelete = async (id) => {
    cerrarModalEliminar();
    if (id) {
      const r = await ControllerPatologias.peticionDeletePatologia(id);
      if (r.resultado) {
        setPatologias(patologias.filter(i => i.idPatologia !== id));
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const eliminarRegistro = (id) => {
    setIdEliminar(id);
    setModalEliminar(true);
  }

  const cerrarModalEliminar = () => {
    setIdEliminar();
    setModalEliminar(false);
  }

  const itemsMenu = [{ nombre: "Modificar", "href": "/app/patologias/patologia/", "permiso": permisos && permisos.modificar },
  { nombre: "Eliminar", "funcion": eliminarRegistro, "permiso": permisos && permisos.eliminar }];

  const obtenerPatologias = async () => {
    const r = await ControllerPatologias.peticionGetPatologias();
    (r.resultado) ? setPatologias(r.data) : alert.error(r.mensaje);
  }

  useLayoutEffect(() => {
    obtenerPatologias();
  }, []);



  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    Listado
                  </Box>
                </Grid>
                {permisos && permisos.crear &&
                  <Grid item xs="auto">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        href="/app/patologias/patologia"
                      >
                        Nuevo
                      </Button>
                    </Box>
                  </Grid>}
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Nombre
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Descripción
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!patologias ? <TablaCarga espacio={4} /> :
                  patologias.length < 1 ? <SinRegistros espacio={4} /> :
                    patologias.map(patologia => {
                      return (
                        <TableRow>
                          <TableCell
                            classes={{ root: classes.tableCellRoot }}
                            align="right">
                            <MenuTabla id={patologia.idPatologia} items={itemsMenu} />
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {patologia.nombre}
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {acortarTexto(patologia.descripcion,80)}
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            <Historial creacion={{ fecha: convertirTimezone(patologia.fechaRegistro,'GMT-3') }} modificacion={{ fecha: convertirTimezone(patologia.fechaUltimaModificacion,'GMT-3') }} />
                          </TableCell>
                        </TableRow>
                      )
                    })},
              </TableBody>
            </Box>
          </TableContainer>
        </Card>

      </Container>

      {modalEliminar && <AlertaDialogo titulo={"Seguro desea eliminar la patología seleccionada?"} id={idEliminar} funcionConfirmar={peticionDelete} abierto={modalEliminar} funcionCerrar={cerrarModalEliminar} />}
    </>
  );
};

export default Patologias;
