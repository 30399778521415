import React, { useLayoutEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogContentText, DialogTitle, Dialog, Typography } from '@material-ui/core';
import { Transicion } from "components/Efectos/Transicion";
import { formatoFecha } from "functions/fechas";
import * as ControllerRegistros from 'controllers/Registros';
import { useAlert } from "react-alert";
import Acordion from "components/Formularios/Acordion";
import { useDispatch } from "react-redux";

export default function VerLog({ mostrarRegistro, cerrarPopUPRegistro, id }) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [registro, setRegistro] = React.useState([]);

  const obtenerLog = async () => {
    dispatch({ "type": "AVISO_CARGANDO", "data": true });
    const r = await ControllerRegistros.peticionGetLog(id);
    if (!r.resultado || (r.resultado && !r.data)) {
      alert.error(r.mensaje);
      dispatch({ "type": "AVISO_CARGANDO", "data": false });
      return;
    }
    const registroObtenido = {
      fecha: r.data.date,
      usuario: r.data.session && r.data.session.cedula,
      servicio: r.data.service,
      metodo: r.data.method,
      solicitud: r.data.request,
      respuesta: r.data.response
    };
    setRegistro(registroObtenido);
    dispatch({ "type": "AVISO_CARGANDO", "data": false });
}

useLayoutEffect(() => {
  if (id && id !== '')
    obtenerLog();
}, [id]);

return (
  <Dialog
    maxWidth="md"
    open={mostrarRegistro}
    TransitionComponent={Transicion}
    keepMounted
    onClose={cerrarPopUPRegistro}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">
      <Typography variant="h3"> Visualización de registro</Typography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        <Box
          component={Typography}
          variant="h3"
          marginBottom="0!important"
        >
          ID: {id ? id : '-'}
        </Box>
        <Box
          component={Typography}
          variant="h3"
          marginBottom="0!important"
        >
          Fecha: {registro && registro.fecha ? formatoFecha(registro.fecha, "DD/MM/YYYY HH:MM") : '-'}
        </Box>
        <Box
          component={Typography}
          variant="h3"
          marginBottom="0!important"
        >
          Usuario: {registro && registro.usuario ? registro.usuario : 'Sistema'}
        </Box>
        <Box
          component={Typography}
          variant="h3"
          marginBottom="0!important"
        >
          Servicio: {registro.servicio ? registro.servicio.replace(process.env.REACT_APP_API_URL, "") : "-"}
        </Box>
        <Box
          component={Typography}
          variant="h3"
          marginBottom="0!important"
        >
          Método: {registro.metodo ? registro.metodo : "-"}
        </Box>
        <Acordion titulo={"Solicitud"} esJson={true} texto={registro && registro.solicitud ? registro.solicitud : 'No hay información'} />
        <Acordion titulo={"Respuesta"} esJson={true} texto={registro && registro.respuesta ? registro.respuesta : 'No hay información'} />
      </DialogContentText>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
        <Button color="secondary" variant="contained" onClick={cerrarPopUPRegistro}>
          Cerrar
        </Button>
      </Box>
    </DialogContent>
  </Dialog>
);
}