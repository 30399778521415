import { Grid, styled } from "@material-ui/core";
import { Assignment, LocalHospital, Notifications, ImportExport, Room, School } from "@material-ui/icons";

export const EstiloEstado = styled(ImportExport)(({ theme: { palette } }) => ({
  [`&.${'Demo-icon'}`]: {
    color: palette.action.active,
  },
}));

export const EstiloConsultorio = styled(Room)(({ theme: { palette } }) => ({
    [`&.${'Demo-icon'}`]: {
      color: palette.action.active,
    },
  }));
  
  export const EstiloProfesional = styled(School)(({ theme: { palette } }) => ({
    [`&.${'Demo-icon'}`]: {
      color: palette.action.active,
    },
  }));

  export const EstiloDetalle = styled(Notifications)(({ theme: { palette } }) => ({
    [`&.${'Demo-icon'}`]: {
      color: palette.action.active,
    },
  }));
  
  export const EstiloPlanificado = styled(LocalHospital)(({ theme: { palette } }) => ({
    [`&.${'Demo-icon'}`]: {
      color: palette.action.active,
    },
  }));

  export const EstiloFicha = styled(Assignment)(({ theme: { palette } }) => ({
    [`&.${'Demo-icon'}`]: {
      color: palette.action.active,
    },
  }));


  export const EstiloCelda = styled(Grid)(() => ({
    [`&.${'Demo-textCenter'}`]: {
      textAlign: 'center',
    },
  }));

