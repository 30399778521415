import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import componentStyles from "assets/theme/views/admin/profile.js";
import { calcularEdad } from 'functions/fechas'
import boxShadows from "assets/theme/box-shadow.js";
import { PhoneAndroid } from "@material-ui/icons";
import Email from "@material-ui/icons/Email";
import * as ControllerPacientes from 'controllers/Pacientes';
import { useEffect } from "react";
import { b64aBlob } from "functions/formulario";
import { useAlert } from "react-alert";
import InfoSaludHorizontal from "./InfoSaludHorizontal";
import { useSelector } from "react-redux";

const useStyles = makeStyles(componentStyles);

const HeadPaciente = ({ paciente }) => {
    const classes = useStyles();
    const alert = useAlert();
    const [avatar, setAvatar] = React.useState();
    const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.paciente : []);

    const obtenerAvatar = async () => {
        const r = await ControllerPacientes.peticionGetPacienteAvatar(paciente.idPaciente);
        if (r.resultado) {
            if (r.data) {
                const blob = b64aBlob(r.data.base64 ? r.data.base64 : "");
                setAvatar(blob);
            }
        } else {
            alert.error(r.mensaje);
        }
    };

    useEffect(() => {
        if (paciente && paciente.idPaciente) {
            obtenerAvatar();
        }
    }, [paciente])

    return (
        <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            classes={{ root: classes.order1 + " " + classes.marginBottomXl0 }}
        >
            <Card classes={{ root: classes.cardRoot }}>
                <Grid container>
                    <Grid item xs={12} lg={3}>
                        <Box component={Grid} container justifyContent="center">
                            <Grid item xs={12} lg={1}>
                                <Box position="relative">
                                    <Box
                                        component="img"
                                        src={
                                            avatar ? URL.createObjectURL(avatar) : require("assets/img/theme/avatar.png").default
                                        }
                                        alt="..."
                                        maxWidth="180px"
                                        borderRadius="50%"
                                        position="absolute"
                                        left="50%"
                                        boxShadow={boxShadows.boxShadow + "!important"}
                                        className={classes.profileImage}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                        <Box
                            component={CardHeader}
                            border="0!important"
                            textAlign="center"
                            paddingBottom="0!important"
                            paddingTop="8rem!important"
                            classes={{ root: classes.cardHeaderRootProfile }}
                            subheader={
                                <Box display="flex" justifyContent="space-between">
                                    <Button
                                        href={"mailto:" + paciente.mail}
                                        variant="contained"
                                        size="small"
                                        classes={{ root: classes.buttonRootInfo }}
                                    >
                                        Correo
                                    </Button>
                                    <Button
                                        href={"https://api.whatsapp.com/send?phone=" + paciente.celular}
                                        target="_blank"
                                        variant="contained"
                                        classes={classes.buttonContainedSuccess}
                                        size="small"
                                    >
                                        Whatsapp
                                    </Button>
                                </Box>
                            }
                        ></Box>
                        <Box marginTop="3rem" textAlign="center">
                            <Typography variant="h1">
                                {paciente.nombre1 ? `${paciente.nombre1} ${paciente.apellido1}`:''}
                                <Box component="span" fontWeight="300">
                                   {paciente.fechaNacimiento ? `, ${calcularEdad(paciente.fechaNacimiento)}` :''}
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Box component={Grid} paddingLeft={"2rem"} marginTop={"2rem"} justifyContent="left">
                            <Box
                                component={Typography}
                                variant="h3"
                                fontWeight="300!important"
                                display="flex"
                                alignItems="center"
                                justifyContent="left"
                            >
                                <Box
                                    component={PhoneAndroid}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                    marginRight={"0.5rem"}
                                ></Box>
                                {paciente.telefono ? ` ${paciente.telefono}` :'-'}
                            </Box>
                            <Box
                                component={Typography}
                                variant="h3"
                                fontWeight="300!important"
                                display="flex"
                                alignItems="center"
                                justifyContent="left"
                            >
                                <Box
                                    component={PhoneAndroid}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                    marginRight={"0.5rem"}
                                ></Box>
                                {paciente.celular ? ` ${paciente.celular}`:'-'}
                            </Box>
                            <Box
                                component={Typography}
                                variant="h3"
                                fontWeight="300!important"
                                display="flex"
                                alignItems="center"
                                justifyContent="left"
                            >
                                <Box
                                    component={Email}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                    marginRight={"0.5rem"}
                                ></Box>
                                {paciente.mail ? ` ${paciente.mail}` :'-'}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        {permisos && permisos.salud &&
                            <Box component={Grid} paddingLeft={"2rem"} marginTop={"2rem"} justifyContent="left">
                                <InfoSaludHorizontal paciente={paciente} />
                            </Box>}
                    </Grid>
                </Grid>
            </Card >
        </Grid >
    );
};

export default HeadPaciente;