import React, { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import { IconButton } from '@material-ui/core';
import * as ControllerNotificaciones from 'controllers/Notificaciones';
import { useAlert } from "react-alert";
import EnviarNotificacion from "./popup/enviarNotificacion";
import { formatoFecha } from 'functions/fechas';
import { Send } from "@material-ui/icons";
import Buscador from "components/Tabla/Buscador";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";

const useStyles = makeStyles(componentStyles);

const Notificaciones = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [registros, setRegistros] = React.useState();
  const [registro, setRegistro] = React.useState([]);
  const diasPorDefecto = 3;
  const [mostrar, setMostrarRegistro] = React.useState(false);

  const abrirEnvio = (r) => {
    setRegistro(r);
    setMostrarRegistro(true);
  };

  const cerrarEnvio = () => {
    setMostrarRegistro(false);
  };

  const obtenerNotificaciones = async (valor) => {
    const r = await ControllerNotificaciones.peticionGetNotificacionesPendientes(valor ? valor.nombre : diasPorDefecto);
    if (r.resultado) {
      setRegistros(r.data ? r.data.sort((a, b) => new Date(a.fecha) - new Date(b.fecha)) : []);
    } else {
      alert.error(r.mensaje);
    }
  }

  useLayoutEffect(() => {
    obtenerNotificaciones();
  }, []);

  return (
    <>
      <Header />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    Listado
                  </Box>
                </Grid>
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>
          <Buscador etiqueta="Días de búsqueda" buscar={obtenerNotificaciones} porDefecto={diasPorDefecto} reiniciar={() => obtenerNotificaciones()} tamaño={"small"} tipo={"number"} />
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Fecha
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Paciente
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Profesional
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Consultorio
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!registros ? <TablaCarga espacio={5} /> :
                  registros.length < 1 ? <SinRegistros espacio={5} mensajePersonalizado={"No hay notificaciones pendientes para los días posteriores indicados"} /> :
                    registros.map(r => {
                      return (
                        <TableRow>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            <IconButton title="Enviar" onClick={() => abrirEnvio(r)}>
                              <Send />
                            </IconButton>
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {formatoFecha(r.fecha, "DD/MM/YYYY HH:MM")}
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {r.paciente ? `${r.paciente.nombre1} ${r.paciente.apellido1}` : 'Desconocido'}
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {r.medico ? `${r.medico.nombre1} ${r.medico.apellido1}` : 'Desconocido'}
                          </TableCell>
                          <TableCell classes={{ root: classes.tableCellRoot }}>
                            {r.consultorio ? `${r.consultorio.nombre}` : 'Desconocido'}
                          </TableCell>
                        </TableRow>
                      )
                    })},
              </TableBody>
            </Box>
          </TableContainer>
        </Card >

      </Container >

      {/* PopUp */}
      {mostrar && <EnviarNotificacion mostrar={mostrar} cerrar={cerrarEnvio} registro={registro} recargar={obtenerNotificaciones} />}

    </>
  );
};

export default Notificaciones;
