import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Bar } from "react-chartjs-2";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import componentStyles from "assets/theme/views/admin/dashboard.js";


const useStyles = makeStyles(componentStyles);

//Opciones
const configuracion = {
    options: {
      legend: {
        display: true,
        position: 'bottom',
      },
      },
};

export const GraficaBarras = ({titulo, subtitulo, datos}) => {
  const classes = useStyles();
  const theme = useTheme();

    return (
        <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                title={
                  <Box component="span" color={theme.palette.gray[600]}>
                    {subtitulo}
                  </Box>
                }
                subheader={titulo}
                classes={{ root: classes.cardHeaderRoot }}
                titleTypographyProps={{
                  component: Box,
                  variant: "h6",
                  letterSpacing: ".0625rem",
                  marginBottom: ".25rem!important",
                  classes: {
                    root: classes.textUppercase,
                  },
                }}
                subheaderTypographyProps={{
                  component: Box,
                  variant: "h2",
                  marginBottom: "0!important",
                  color: "initial",
                }}
              ></CardHeader>
              <CardContent>
                <Box position="relative" height="350px">
                  <Bar
                    data={datos}
                    options={configuracion.options}
                  />
                </Box>
              </CardContent>
            </Card>
    );
};