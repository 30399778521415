import { queryPut } from "functions/controllers";
import { queryGet, queryPost, queryDelete } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Sistema/patologia/";

export function peticionGetPatologias() {
  return queryGet(url);
}

export function peticionPostPatologia(elemento) {
  return queryPost(url, elemento);
}

export function peticionPutPatologia(id, elemento) {
  return queryPut(url + id, elemento);
}

export function peticionDeletePatologia(id) {
  return queryDelete(url  + id);
}

export function peticionGetPatologia(id) {
  return queryGet(url + id);
}
