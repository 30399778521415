import React from "react";
import Box from "@material-ui/core/Box";
import { useAlert } from "react-alert";
import * as ControllerPacientes from 'controllers/Pacientes';
import { useLayoutEffect } from "react";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { acortarTexto } from "functions/formulario";

const InfoSaludHorizontal = ({ paciente }) => {
  const alert = useAlert();
  const [patologias, setPatologias] = React.useState([]);
  const [alergias, setAlergias] = React.useState([]);
  const [medicamentos, setMedicamentos] = React.useState([]);

  const obtenerPatologias = async () => {
    const r = await ControllerPacientes.peticionGetPacientePatologias(paciente.idPaciente);
    if (r.resultado) {
      setPatologias(r && r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerAlergias = async () => {
    const r = await ControllerPacientes.peticionGetPacienteAlergias(paciente.idPaciente);
    if (r.resultado) {
      setAlergias(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerMedicamentos = async () => {
    const r = await ControllerPacientes.peticionGetPacienteMedicamentos(paciente.idPaciente);
    if (r.resultado) {
      setMedicamentos(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  useLayoutEffect(() => {
    if (paciente && paciente.idPaciente) {
      obtenerAlergias();
      obtenerPatologias();
      obtenerMedicamentos();
    }
  }, [paciente])

  return (
    <>
      <Box component={Grid} container justifyContent="center">
        <Grid item xs={12} lg={4}>
          <Box
            component="p"
            lineHeight="1"
            marginBottom="0rem"
            fontSize="1rem"
            textAlign="left"
          >
            <strong>Alergias:</strong> {(alergias && alergias.length > 0) ?
              alergias.map((i, index) => {
                return (
                  <Tooltip title={`${i.alergia.descripcion ? `Descripción: ${acortarTexto(i.alergia.descripcion, 120)}` : ''}`}>
                    <Typography variant="body2"> {`- ${i.alergia.nombre}`}</Typography>
                  </Tooltip>
                )
              }
              ) : "Ninguna"}
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            component="p"
            lineHeight="1"
            fontSize="1rem"
            textAlign="left"
          >
            <strong>Medicamentos:</strong> {(medicamentos && medicamentos.length > 0) ?
              medicamentos.map((i, index) => {
                return (
                  <Tooltip title={`${i.medicamento.principioActivo ? `Principio activo: ${i.medicamento.principioActivo}` : ''}`}>
                    <Typography variant="body2"> {`- ${i.medicamento.nombre}`}</Typography>
                  </Tooltip>
                )
              }
              ) : "Ninguno"}
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            component="p"
            lineHeight="1"
            padding={0}
            fontSize="1rem"
            textAlign="left"
          >
            <strong>Patologías:</strong> {(patologias && patologias.length > 0) ?
              patologias.map(i => {
                return (
                  <Tooltip title={`${i.patologia.descripcion ? `Descripción: ${acortarTexto(i.patologia.descripcion, 40)}` : ''}`}>
                    <Typography variant="body2"> {`- ${i.patologia.nombre}`}</Typography>
                  </Tooltip>
                )
              }
              )
              : "Ninguna"}
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default InfoSaludHorizontal;