import React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Reserva } from "views/agenda/popup/reserva";
import theme from "assets/theme/theme";

export const FormularioReserva = ({ mostrar, datos, cerrar, id }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Reserva visible={mostrar} cerrar={cerrar} datos={datos} id={id} />
        </ThemeProvider>
    );
};
