import React, { useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogContentText, DialogTitle, Dialog, IconButton, Grid, Typography } from '@material-ui/core';
import * as ControllerUsuarios from "controllers/Usuarios"
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";
import { Sync } from "@material-ui/icons";

export default function ReiniciarClave({ mostrar, cerrar, id }) {
    const alert = useAlert();
    const [nuevaClave, setNuevaClave] = useState();


    const peticionReiniciar = async () => {
        setNuevaClave("******")
        const r = await ControllerUsuarios.peticionReiniciarClave(id);
        if (!r.resultado || !r.data) {
            alert.error(r.mensaje);
            return;
        }
        setNuevaClave(r.data.clave)
        alert.success(r.mensaje);
    }

    return (
        <Dialog
            maxWidth="xs"
            open={mostrar}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrar}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title"><Typography variant="h3">Reiniciar contraseña</Typography></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                <Typography variant="body2">Presione en el botón para generar una contraseña nueva para el usuario.</Typography>
                </DialogContentText>
                <Grid container style={{"marginTop":"2rem"}}>
                    <Grid item xs={6} lg={5}>
                        <FormGroup>
                            <FormLabel>Nueva contraseña:</FormLabel>
                            <Typography variant="body2" color={nuevaClave ? 'green' : 'grey'}> {nuevaClave ? nuevaClave : '******'}</Typography>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <IconButton title={'Generar'} onClick={peticionReiniciar} >
                            <Sync  style={{ transform: "scale(3)", opacity: "0.5", color:"green" }} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Box
                    display="flex"
                    justifyContent="end"
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button color="secondary" variant="contained" onClick={cerrar}>
                        Cerrar
                    </Button>
                </Box>

            </DialogContent>
        </Dialog>
    );
}