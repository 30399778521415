import React from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import ImageMapper from 'react-img-mapper';

export function ArcadaInferior({seleccion, limpiar}) {
  const componente = useRef(null);

  const limpiarSeleccion = () => {
    componente.current.clearHighlightedArea();
  };

  useLayoutEffect(() => {
    if(limpiar)
      limpiarSeleccion();
  }, [limpiar]);

  const MapaArcadaInferior = {
    name: 'ArcadaInferior',
    areas: [
      { "id": "inferior32", "title": "32", "shape": "circle", "name": "32", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [20, 32, 14], },
      { "id": "inferior31", "title": "31", "shape": "circle", "name": "31", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [23, 59, 14], },
      { "id": "inferior30", "title": "30", "shape": "circle", "name": "30", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [28, 87, 14], },
      { "id": "inferior29", "title": "29", "shape": "circle", "name": "29", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [38, 111, 10], },
      { "id": "inferior28", "title": "28", "shape": "circle", "name": "28", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [47, 129, 10], },
      { "id": "inferior27", "title": "27", "shape": "circle", "name": "27", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [61, 144, 10], },
      { "id": "inferior26", "title": "26", "shape": "circle", "name": "26", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [76, 153, 10], },
      { "id": "inferior25", "title": "25", "shape": "circle", "name": "25", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [90, 157, 10], },
      { "id": "inferior24", "title": "24", "shape": "circle", "name": "24", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [107, 157, 10], },
      { "id": "inferior23", "title": "23", "shape": "circle", "name": "23", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [123, 153, 10], },
      { "id": "inferior22", "title": "22", "shape": "circle", "name": "22", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [137, 144, 10], },
      { "id": "inferior21", "title": "21", "shape": "circle", "name": "21", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [150, 129, 10], },
      { "id": "inferior20", "title": "20", "shape": "circle", "name": "20", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [160, 111, 10], },
      { "id": "inferior19", "title": "19", "shape": "circle", "name": "19", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [168, 87, 14], },
      { "id": "inferior18", "title": "18", "shape": "circle", "name": "18", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [176, 59, 14], },
      { "id": "inferior17", "title": "17", "shape": "circle", "name": "17", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [177, 30, 14], },
    ],
  };

  return <ImageMapper height={171} width={200} containerRef={componente} key={"ArcadaInferior"} stayHighlighted natural  src={require("assets/img/icons/common/dental_arch_lower.png").default} map={MapaArcadaInferior} onClick={(e)=> seleccion(e,"inferior")}/>
}