import React from "react";
import { MoreVert } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTheme } from "@material-ui/core/styles";

const MenuTabla = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    return (
        <React.Fragment>
            <Box
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={event => setAnchorEl(event.currentTarget)}
                size="small"
                component={Button}
                width="2rem!important"
                height="2rem!important"
                minWidth="2rem!important"
                minHeight="2rem!important"
            >
                <Box
                    component={MoreVert}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    position="relative"
                    top="2px"
                    color={theme.palette.gray[500]}
                />
            </Box>
            <Menu
                elevation={0}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                onClose={() => setAnchorEl(null)}
            >
                {props.items && props.items.map((i, index) => {
                    if (i.permiso === false)
                        return null;
                    return (
                        <MenuItem
                            key={"MenuTablaItem" + index}
                            onClick={() => {
                                setAnchorEl(null);
                                { i.funcion && i.funcion(props.id) }
                            }}
                            href={i.href && i.href + props.id}
                            component="a" >
                            {i.nombre}</MenuItem>
                    )
                })}
            </Menu>
        </React.Fragment >
    );
};

export default MenuTabla;