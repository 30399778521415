import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import componentStyles from "assets/theme/views/admin/profile.js";
import { formatoFecha } from 'functions/fechas'
import { Event } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core";
import { useAlert } from "react-alert";
import * as ControllerCitas from 'controllers/paciente/Citas';
import { useLayoutEffect } from "react";

const useStyles = makeStyles(componentStyles);

const InfoCitas = ({ paciente }) => {
    const classes = useStyles();
    const alert = useAlert();
    const [proxCitas, setProxCitas] = React.useState([]);

    const obtenerProxCitas = async () => {
        if (paciente && paciente.idPaciente) {
            const r = await ControllerCitas.peticionGetCitasPendientes(paciente.idPaciente);
            if (r.resultado) {
                setProxCitas(r.data);
            } else {
                alert.error("Error obteniendo citas: " + r.mensaje);
            }
        }
    }

    useLayoutEffect(() => {
        if (paciente) {
            obtenerProxCitas();
        }
    }, [])

    return (
        <>
            <Box
                component="p"
                fontWeight="300"
                lineHeight="1.7"
                marginBottom="1rem"
                fontSize="1rem"
                textAlign="center"
            >
                <strong>Próximas citas: </strong>
            </Box>
            <>
                {proxCitas.length ?
                    proxCitas.map(cita => {
                        return (
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={<Event style={{ transform: "scale(1.8)" }} />}
                                    title={`Profesional: ${cita && cita.medico ? cita.medico.nombre1 + " " + cita.medico.apellido1 : 'Desconocido'}`}
                                    subheader={<> {cita && cita.consultorio ? cita.consultorio.nombre : "Consultorio desconocido"} <br /> {formatoFecha(cita.fechaInicio,"DD/MM/YYYY HH:MM")}</>}
                                />
                            </Card>
                        )
                    })
                    :
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <h3>No tiene citas próximas agendadas</h3>
                    </Box>
                }
            </>
        </>
    );
};

export default InfoCitas;