import React from "react";


import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";


import componentStyles from "assets/theme/components/admin-footer.js";

const useStyles = makeStyles(componentStyles);

const ValidacionForm = ({mensajeValidacion}) => {
  const classes = useStyles();
  return (
        <Box
          item
          xs={12}
          xl={12}
          component={Grid}
          display="flex"
          alignItems="center"
          color="red"
          className={classes.justifyContentCenter}
        >
         {(mensajeValidacion)?`${mensajeValidacion}`:""}
         <br />
        </Box>
  );
};

export default ValidacionForm;
