import React from "react";
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import { FormularioReserva } from './FormularioReserva';
import AlertaDialogo from 'components/Popups/AlertaDialogo';
import * as ControllerAgenda from 'controllers/Agenda';
import { useAlert } from "react-alert";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "assets/theme/theme";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const ResumenCitaLayout = ({ children, appointmentData, appointmentMeta, ...restProps }) => {
  const alert = useAlert();
  const [mostrarReserva, setMostrarReserva] = React.useState(false);
  const [mostrarEliminar, setMostrarEliminar] = React.useState(false);
  const [idReserva, setIdReserva] = React.useState();
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.reserva : []);

  const abrirReserva = () => {
    if (!permisos || (permisos && !permisos.crear))
      alert.error("No tienes permiso para realizar esta acción")
    setMostrarReserva(true);
  }

  const cerrarReserva = () => {
    setMostrarReserva(false);
  }

  const abrirEliminar = () => {
    if (!permisos || (permisos && !permisos.eliminar))
      alert.error("No tienes permiso para realizar esta acción")
    setMostrarEliminar(true);
  }

  const cerrarEliminar = () => {
    setMostrarEliminar(false);
  }

  const eliminarReserva = async () => {
    const r = await ControllerAgenda.peticionDeleteCita(idReserva);
    cerrarEliminar();
    if (r.resultado) {
      //Trigger evento de aviso a Calendario para recargar datos
      dispatchEvent(new Event("cambioAgenda"))
      alert.success(r.mensaje);
    } else {
      alert.error(r.mensaje);
    }
  }

  useEffect(() => {
    setIdReserva(appointmentMeta && appointmentMeta.data && appointmentMeta.data.idReserva ? appointmentMeta.data.idReserva : null);
  }, [appointmentMeta]);


  return (
    <>
      <AppointmentTooltip.Layout appointmentMeta={appointmentMeta} {...restProps} onOpenButtonClick={() => abrirReserva()} onDeleteButtonClick={() => abrirEliminar()}>
        {children}
      </AppointmentTooltip.Layout>
      {mostrarReserva && permisos && permisos.modificar && <FormularioReserva mostrar={mostrarReserva} cerrar={cerrarReserva} id={idReserva} />}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {mostrarEliminar && permisos && permisos.eliminar && <AlertaDialogo abierto={mostrarEliminar} titulo={"Seguro quieres eliminar la cita?"}
          texto={"En caso que sea por motivo del paciente cancele la cita en vez de eliminarla."}
          id={idReserva} funcionConfirmar={eliminarReserva} funcionCerrar={cerrarEliminar} />}
      </ThemeProvider>
    </>
  )
};