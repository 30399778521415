import localForage from 'localforage';

export async function checkRegistro(key) {
    const r= await localForage.getItem(key);
    return (r) ? true : false;
}

export async function obtenerRegistro(key) {
    return await localForage.getItem(key);
}

export async function guardarRegistro(key, valor) {
    await localForage.setItem(key, valor);
}

export async function eliminarRegistro(key) {
    await localForage.removeItem(key);
}

export async function borrarBrowserDB() {
    await localForage.clear();
}