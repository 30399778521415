import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TwitterPicker } from "react-color";
import reactCSS from 'reactcss'
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import * as ControllerConsultorios from 'controllers/Consultorios';
import { useAlert } from "react-alert";
import { evaluarForm } from "functions/formulario";
import { MenuItem, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ObtenerPaises } from "functions/datosSistema";

const useStyles = makeStyles(componentStyles);

function Consultorio(props) {
  let { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const [consultorio, setConsultorio] = React.useState([]);
  let [consultorioActualizado, setConsultorioActualizado] = React.useState({ idPais: 214, color: '#E91E63' });
  const paises = useSelector((state) => state.paises);
  const [color, setColor] = React.useState('#E91E63');
  const [showColorPicker, setshowColorPicker] = React.useState(false);
  const camposRequeridos = [{ id: 'nombre', nombre: 'Nombre' }, { id: 'direccion', nombre: 'Dirección' },
  { id: 'barrio', nombre: 'Barrio' }, { id: 'celular', nombre: 'Celular' }, { id: 'departamento', nombre: 'Departamento' },
  { id: 'telefono', nombre: 'Teléfono' }, { id: 'color', nombre: 'Color' }, { id: 'idPais', nombre: 'País' }];

  const peticion = async (tipo) => {
    const validarForm = evaluarForm(consultorioActualizado, camposRequeridos);
    let r = { resultado: false };
    if (validarForm.resultado) {
      switch (tipo) {
        case "post":
          r = await ControllerConsultorios.peticionPostConsultorio(consultorioActualizado);
          break;
        case "put":
          r = await ControllerConsultorios.peticionPutConsultorio(id, consultorioActualizado);
          break;
        default:
          break;
      }
      if (r.resultado) {
        alert.success(r.mensaje);
        history.push("/app/consultorios/listado");
      } else {
        alert.error(r.mensaje);
      }
      return;
    }
    alert.error(validarForm.mensaje);
  }


  const guardarDatos = async e => {
    await setConsultorioActualizado({
      ...consultorioActualizado,
      [e.target.name]: e.target.value
    });
  };

  const guardarDatoColor = async colorElegido => {
    setColor(colorElegido.hex)
    await setConsultorioActualizado({
      ...consultorioActualizado,
      "color": colorElegido.hex
    });
  };


  const eventoColorPicker = () => { setshowColorPicker(showColorPicker => !showColorPicker) }

  const obtenerConsultorio = async () => {
    if (id) {
      const r = await ControllerConsultorios.peticionGetConsultorio(id);
      if (r.resultado) {
        setConsultorio(r.data);
        setConsultorioActualizado(r.data);
        setColor(r.data.color || '#E91E63');
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerPaises = async () => {
    if (paises && paises.length === 0) {
      const r = await ObtenerPaises();
      dispatch({ "type": "CARGAR_PAISES", "data": r });
    }
  }

  useEffect(() => {
    obtenerPaises();
    obtenerConsultorio();
  }, []);


  const colorPickerButtonCSS = reactCSS({
    'default': {
      color: {
        width: '100%',
        height: '14px',
        borderRadius: '2px',
        background: `${color}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });


  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>

          <Grid
            item
            xs={12}
            xl={8}
            justifyContent="center"
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Info del consultorio
                      </Box>
                    </Grid>

                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Nombre*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="nombre"
                            key={consultorio ? consultorio.nombre : 'consultorio.nombre'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={consultorio ? consultorio.nombre : ''}
                            inputProps={{maxLength: 30}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Teléfono*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="telefono"
                            key={consultorio ? consultorio.telefono : 'consultorio.telefono'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={consultorio ? consultorio.telefono : ''}
                            inputProps={{maxLength: 12}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Celular*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="celular"
                            key={consultorio ? consultorio.telefono : 'consultorio.celular'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={consultorio ? consultorio.celular : ''}
                            inputProps={{maxLength: 12}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Barrio*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="barrio"
                            key={consultorio ? consultorio.barrio : 'consultorio.barrio'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={consultorio ? consultorio.barrio : ''}
                            inputProps={{maxLength: 30}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Departamento*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="departamento"
                            key={consultorio ? consultorio.departamento : 'consultorio.departamento'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={consultorio ? consultorio.departamento : ''}
                            inputProps={{maxLength: 20}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>País*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={(consultorio && consultorio.idPais) || 214} name="idPais" key={consultorio ? consultorio.idPais : 'consultorio.idPais'}
                            onChange={(e) => guardarDatos(e)}>
                            {paises && paises.map(p => {
                              return (
                                <MenuItem value={p.idPais}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={8}>
                      <FormGroup>
                        <FormLabel>Dirección*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            multiline
                            defaultValue={consultorio ? consultorio.direccion : ''}
                            name="direccion"
                            key={consultorio ? consultorio.direccion : 'consultorio.direccion'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 50}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Identificación visual*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="20%"
                          marginBottom="1rem!important"
                        >

                          <div style={colorPickerButtonCSS.swatch} onClick={eventoColorPicker}>
                            <div style={colorPickerButtonCSS.color} />
                          </div>
                          {showColorPicker && (
                            <TwitterPicker color={color} onChange={guardarDatoColor} onChangeComplete={eventoColorPicker} onClose={eventoColorPicker} />
                          )}

                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>

                  </Grid>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/consultorios/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => { id ? peticion("put") : peticion("post") }}
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default Consultorio;
