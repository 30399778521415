import { queryPut } from "functions/controllers";
import { queryGet, queryPost, queryDelete } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Sistema/mutualista/";

export function peticionGetMutualistas() {
  return queryGet(url);
}

export function peticionPostMutualista(elemento) {
  return queryPost(url, elemento);
}

export function peticionPutMutualista(id, elemento) {
  return queryPut(url + id, elemento);
}

export function peticionDeleteMutualista(id) {
  return queryDelete(url + id);
}

export function peticionGetMutualista(id) {
  return queryGet(url + id);
}
