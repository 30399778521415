import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import { estiloTabla } from "estilos/clases.js";
import * as ControllerUsuarios from 'controllers/Usuarios';
import { useAlert } from "react-alert";
import Foto from "components/Formularios/Foto";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuTabla from "components/Tabla/MenuTabla";
import Pasos from "components/Formularios/Pasos";
import { CardContent } from "@material-ui/core";
import AltaRol from "./popup/altaRol";
import { pasos } from "functions/usuarios";
import AlertaDialogo from "components/Popups/AlertaDialogo";
import { useDispatch, useSelector } from "react-redux";
import Historial from "components/Tabla/Historial";
import { ObtenerRoles } from "functions/datosSistema";
import { b64aBlob } from "functions/formulario";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";
import { convertirTimezone } from "functions/fechas";

function DatosRoles(props) {
  let { id } = useParams();
  const estilo = estiloTabla();
  const theme = useTheme();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [itemsPasos, setItemsPasos] = React.useState([]);
  const itemActivo = 2;
  const [usuario, setUsuario] = React.useState([]);
  const roles = useSelector((state) => state.roles);
  const [mostrarAltaRol, setMostrarAltaRol] = React.useState(false);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [idEliminar, setIdEliminar] = React.useState();
  const [avatar, setAvatar] = React.useState();

  const abrirPopUPAltaRol = () => {
    setMostrarAltaRol(true);
  };

  const cerrarPopUPAltaRol = () => {
    setMostrarAltaRol(false);
  };

  const peticionDelete = async (idRol) => {
    cerrarModalEliminar();
    if (id) {
      const r = await ControllerUsuarios.peticionDeleteUsuarioRol(id, [{ "idRol": idRol }]);
      if (r.resultado) {
        obtenerUsuario();
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const eliminarRegistro = (idRol) => {
    setIdEliminar(idRol);
    setModalEliminar(true);
  }

  const cerrarModalEliminar = () => {
    setIdEliminar();
    setModalEliminar(false);
  }

  const obtenerUsuario = async () => {
    if (id) {
      const r = await ControllerUsuarios.peticionGetUsuario(id);
      if (r.resultado) {
        setUsuario(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerAvatar = async () => {
    const r = await ControllerUsuarios.peticionGetUsuarioAvatar(id);
    if (r.resultado) {
      if (r.data) {
        const blob = b64aBlob(r.data.base64 ? r.data.base64 : "");
        setAvatar(blob);
      }
    } else {
      alert.error(r.mensaje);
    }
  };

  const itemsMenu = [{ nombre: "Eliminar", "funcion": eliminarRegistro }];

  const obtenerRoles = async () => {
    if (roles && roles.length === 0) {
      const r = await ObtenerRoles();
      dispatch({ "type": "CARGAR_ROLES", "data": r });
    }
  }

  useLayoutEffect(() => {
    setItemsPasos(pasos(id));
    obtenerRoles();
    obtenerUsuario();
  }, []);

  useLayoutEffect(() => {
    if (usuario && usuario.id) {
      obtenerAvatar();
    }
  }, [usuario])

  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: estilo.containerRoot }}
      >
        <Grid container>
          <Foto avatar={avatar} />
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: estilo.gridItemRoot + " " + estilo.order2 }}
          >
            <Card
              classes={{
                root: estilo.cardRoot + " " + estilo.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {id && usuario && usuario.nombre1 ?
                          `Datos de ${usuario.nombre1} ${usuario.apellido1} (${usuario.cedula})`
                          : "Datos de usuario nuevo"}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
              <Pasos items={itemsPasos} activo={itemActivo} />
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h4"
                        marginBottom="0!important"
                      >
                        Roles de seguridad
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={abrirPopUPAltaRol}
                        >
                          Agregar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
              ></CardHeader>
              <CardContent>
                <div className={estilo.plLg4}>
                  <TableContainer>
                    <Box
                      component={Table}
                      alignItems="center"
                      marginBottom="0!important"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}></TableCell>
                          <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                            Nombre
                          </TableCell>
                          <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}>
                            Detalle
                          </TableCell>
                          <TableCell classes={{ root: estilo.tableCellRoot + " " + estilo.tableCellRootHead }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!usuario || !usuario.roles? <TablaCarga espacio={4} /> :
                          usuario.roles.length < 1 ? <SinRegistros espacio={4} /> :
                            usuario.roles.map(r => {
                              return (
                                <TableRow>
                                  <TableCell
                                    classes={{ root: estilo.tableCellRoot }}
                                    align="right">
                                    <MenuTabla id={r.idRol} items={itemsMenu} />
                                  </TableCell>
                                  <TableCell classes={{ root: estilo.tableCellRoot }}>
                                    {r.nombre}
                                  </TableCell>
                                  <TableCell classes={{ root: estilo.tableCellRoot }}>
                                    {r.detalle}
                                  </TableCell>
                                  <TableCell classes={{ root: estilo.tableCellRoot }}>
                                    <Historial creacion={{ fecha: convertirTimezone(r.fechaRegistro,'GMT-3') }} modificacion={{ fecha: convertirTimezone(r.fechaUltimaModificacion) }} />
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                      </TableBody>
                    </Box>
                  </TableContainer>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/usuarios/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid >
      </Container >

      {/* PopUp Alta de rol */}
      {mostrarAltaRol && <AltaRol id={id} mostrarAltaRol={mostrarAltaRol} cerrarPopUPAltaRol={cerrarPopUPAltaRol} recargarDatos={obtenerUsuario} valores={roles} />}

      {modalEliminar && <AlertaDialogo titulo={"Seguro desea eliminar la rol seleccionado?"} id={idEliminar} funcionConfirmar={peticionDelete} abierto={modalEliminar} funcionCerrar={cerrarModalEliminar} />}
    </>
  );
}

export default DatosRoles;
