import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from "@material-ui/core/Box";
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import { timeAgo_traduccion } from 'traducciones/timeAgo_traduccion'
import { Event as EventIcon } from '@material-ui/icons';
import { Cancelacion } from './Cancelacion';
import MenuTabla from 'components/Tabla/MenuTabla';
import { FormularioReserva } from './FormularioReserva';
import Pagination from "@material-ui/lab/Pagination";
import { CardActions } from '@material-ui/core';
import { useLayoutEffect } from 'react';
import { Finalizacion } from './Finalizacion';
import * as ControllerAgenda from 'controllers/Agenda';
import { useAlert } from "react-alert";
import AlertaDialogo from 'components/Popups/AlertaDialogo';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: "100%",
        margin: theme.spacing(1),
    },
    media: {
        height: 190,
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {

        minWidth: 200,
    }
}));

TimeAgo.locale(timeAgo_traduccion)


export default function Pendientes({ citas }) {
    const classes = useStyles();
    const theme = useTheme();
    const alert = useAlert();
    const [carga, setCarga] = React.useState(false);
    const [citasPagina, setCitasPagina] = React.useState([]);
    const [modalReserva, setModalReserva] = React.useState(false);
    const [citaReserva, setCitaReserva] = React.useState(0);
    const [modalCancelacion, setModalCancelacion] = React.useState(false);
    const [modalFinalizacion, setModalFinalizacion] = React.useState(false);
    const [modalEliminar, setModalEliminar] = React.useState(false);
    const [pagina, setPagina] = React.useState(1);
    const [cantidadPaginas, setCantidadPaginas] = React.useState(0);
    const permisos = useSelector((state) => state.usuario && state.usuario.permisos ? state.usuario.permisos.reserva : []);
    const citasPorPagina = 4;

    const abrirModalCancelacion = (idReserva) => {
        setCitaReserva(idReserva);
        setModalCancelacion(true);
    };

    const cerrarModalCancelacion = () => {
        setModalCancelacion(false);
        setCitaReserva({});
    };

    const abrirModalFinalizacion = (idReserva) => {
        setCitaReserva(idReserva);
        setModalFinalizacion(true);
    };

    const cerrarModalFinalizacion = () => {
        setModalFinalizacion(false);
        setCitaReserva({});
    };

    const abrirModalEliminar = (idReserva) => {
        setModalEliminar(true);
        setCitaReserva(idReserva);
    };

    const cerrarModalEliminar = () => {
        setModalEliminar(false);
        setCitaReserva({});
    };

    const abrirReserva = (idReserva) => {
        setModalReserva(true);
        setCitaReserva(idReserva);
    };

    const cerrarReserva = () => {
        setModalReserva(false);
        setCitaReserva({});
    };

    const eliminarReserva = async () => {
        const r = await ControllerAgenda.peticionDeleteCita(citaReserva);
        cerrarModalEliminar();
        if (r.resultado) {
            alert.success(r.mensaje);
            //Trigger evento de aviso a Calendario para recargar datos
            dispatchEvent(new Event("cambioAgenda"));
        } else {
            alert.error(r.mensaje);
        }
    }

    const cambioPagina = (a, b) => {
        setPagina(b);
        obtenerRegistrosPagina(b);
    }

    const calcularCantidadPaginas = () => {
        setCantidadPaginas(Math.ceil(citas.length / citasPorPagina));
        obtenerRegistrosPagina(pagina);
    }

    const obtenerRegistrosPagina = (numeroPagina) => {
        setCitasPagina(citas && citas.length > 0 ? citas.filter((c, i) => (i + 1 > ((numeroPagina * citasPorPagina) - citasPorPagina)) && (i + 1 <= (numeroPagina * citasPorPagina))) : []);
    }

    const itemsMenu = [{ nombre: "Confirmar", "funcion": abrirModalFinalizacion, "permiso": permisos && permisos.finalizar },
    { nombre: "Modificar", "funcion": abrirReserva, "permiso": permisos && permisos.modificar },
    { nombre: "Eliminar", "funcion": abrirModalEliminar, "permiso": permisos && permisos.eliminar },
    { nombre: "Cancelar", "funcion": abrirModalCancelacion, "permiso": permisos && permisos.cancelar }];

    useLayoutEffect(() => {
        calcularCantidadPaginas();
    }, [citas]);

    return (
        <>
            {citasPagina && citasPagina.length ?
                <>
                    {citasPagina.map((cita, i) => {
                        return (
                            <Card className={classes.card} key={i}>
                                <CardHeader
                                    avatar={
                                        carga ? (
                                            <Skeleton animation="wave" variant="circle" width={40} height={40} />
                                        ) : (
                                            <EventIcon style={{ transform: "scale(1.8)" }} />
                                        )
                                    }
                                    action={
                                        carga ? null : (
                                            <>
                                                <MenuTabla id={cita.idReserva} items={itemsMenu} />
                                            </>
                                        )
                                    }
                                    title={
                                        carga ? (
                                            <Skeleton animation="wave" height={6} width="80%" style={{ marginBottom: 6 }} />
                                        ) : (
                                            cita.paciente.nombre1 + ' ' + cita.paciente.apellido1
                                        )
                                    }
                                    subheader={carga ? <Skeleton animation="wave" height={6} width="40%" /> : <>{cita.consultorio.nombre} <br /> <ReactTimeAgo date={Date.parse(cita.fechaInicio)} locale="es-AR" /></>}
                                />
                            </Card>
                        )
                    })}
                    < Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        <Pagination count={cantidadPaginas} onChange={cambioPagina} color="primary" variant="outlined" />
                    </Box>
                </>
                : <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="35px"
                        marginBottom="35px"
                    >
                        <AssignmentTurnedInTwoToneIcon style={{ transform: "scale(8)", opacity: "0.5" }} />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <h3>No tiene más citas para revisar</h3>
                    </Box>
                </>
            }

            {modalReserva && <FormularioReserva mostrar={modalReserva} cerrar={cerrarReserva} id={citaReserva} />}
            {modalCancelacion && <Cancelacion visible={modalCancelacion} cerrar={cerrarModalCancelacion} id={citaReserva} />}
            {modalFinalizacion && <Finalizacion visible={modalFinalizacion} cerrar={cerrarModalFinalizacion} id={citaReserva} />}
            {modalEliminar && <AlertaDialogo abierto={modalEliminar} titulo={"Seguro quieres eliminar la cita?"}
                texto={"En caso que sea por motivo del paciente cancele la cita en vez de eliminarla."}
                id={citaReserva} funcionConfirmar={eliminarReserva} funcionCerrar={cerrarModalEliminar} />}
        </>
    );
}