import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import MUIDataTable from "mui-datatables";
import * as ControllerPacientes from 'controllers/Pacientes';
import * as ControllerFichas from 'controllers/paciente/Ficha';
import { useAlert } from "react-alert";
import Perfil_Paciente from "components/Perfil/Perfil_Paciente";
import { formatoFecha } from "functions/fechas";
import { convertirTimezone } from "functions/fechas";
import { tabla_traduccion } from "traducciones/tabla_traduccion";

const useStyles = makeStyles(componentStyles);

function PatientRecords() {
  let { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [paciente, setPaciente] = React.useState([]);
  const [registros, setRegistros] = React.useState([]);

  const obtenerFichas = async () => {
    const r = await ControllerFichas.peticionGetFichas(id);
    if (r.resultado) {
      for (let i = 0; i < r.data.length; i++) {
        if (r.data[i] && r.data[i].tratamientos) {
          r.data[i].tratamientos.map(p => (
            setRegistros([
              ...registros,
              {
                "detalle": r.data[i].detalle,
                "reserva": r.data[i].reserva,
                "consultorio": r.data[i].consultorio,
                "medico": r.data[i].medico,
                ...p
              }])
          ));
        }
      }
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
        obtenerFichas();
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  useLayoutEffect(() => {
    obtenerPaciente();
  }, []);


  return (
    <>
      <UserHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Registros médicos
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/pacientes/listado/"
                      >
                        Volver
                      </Button>
                    </Grid>
                  </Grid>
                } classes={{ root: classes.cardHeaderRoot }}

              ></CardHeader>
              <CardContent style={{ backgroundColor: "white" }}>
                <MUIDataTable
                  title={""}
                  data={(!registros) ? [] :
                    registros.map(item => {
                      return [
                        (item.reserva) ? formatoFecha(convertirTimezone(item.reserva.fechaFin, 'GMT-3'), 'DD/MM/YYYY HH:MM') : 'Indefinido',
                        (item.tratamiento) ? item.tratamiento.nombre : 'Indefinido',
                        (item.tratamiento) ? item.tratamiento.categoria.nombre : 'Indefinido',
                        (item.pieza) ? `${item.pieza.numero} ${item.pieza.nombre}` : 'Indefinido',
                        (item.cara) ? item.cara.nombre : 'Indefinido',
                        (item.diagnostico) ? item.diagnostico.nombre : 'Indefinido',
                        (item.medico) ? item.medico.nombre1 + " " + item.medico.apellido1 : 'Indefinido',
                        (item.consultorio) ? item.consultorio.nombre : 'Indefinido',
                      ]
                    })
                  }
                  columns={
                    [{ name: "fechaInicio", label: "Fecha" }, { name: "nombre", label: "Tratamiento" },
                    { name: "categoria", label: "Categoría" }, { name: "pieza", label: "Pieza" },
                    { name: "cara", label: "Cara" }, { name: "diagnostico", label: "Diagnóstico" },
                    { name: "medico", label: "Médico" }, { name: "consultorio", label: "Consultorio" }]}
                  options={{
                    filterType: 'dropdown',
                    selectableRowsHideCheckboxes: true,
                    responsive: 'standard',
                    elevation: 0,
                    textLabels: tabla_traduccion
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Perfil_Paciente tipo={'salud'} paciente={paciente} />
        </Grid>
      </Container>
    </>
  );
}

export default PatientRecords;