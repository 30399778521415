import React, { useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import { estiloPerfil } from "estilos/clases.js";
import { useSelector } from "react-redux";
import * as ControllerAutenticacion from 'controllers/Autenticacion';
import { useAlert } from "react-alert";
import Foto from "components/Formularios/Foto";
import { formatoFecha } from 'functions/fechas';
import { obtenerEtiquetaSexo } from "functions/general";
import { b64aBlob } from "functions/formulario";

function Perfil(props) {
  const theme = useTheme();
  const estilo = estiloPerfil();
  const alert = useAlert();
  const usuario = useSelector((state) => state.usuario);
  const [usuarioDatos, setUsuarioDatos] = React.useState({});
  const [avatar, setAvatar] = React.useState();

  const obtenerAvatar = async () => {
    if (usuario.avatar) {
      const blob = b64aBlob(usuario.avatar ? usuario.avatar : "");
      setAvatar(blob);
    }
  };

  const obtenerUsuario = async () => {
    const r = await ControllerAutenticacion.peticionGetSesion();
    if (r.resultado) {
      setUsuarioDatos(r.data);
    } else {
      alert.error(r.mensaje);
    }
  };


  useLayoutEffect(() => {
    obtenerAvatar();
    obtenerUsuario();
  }, [usuario])

  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: estilo.containerRoot }}
      >
        <Grid container>
          <Foto avatar={avatar} />
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: estilo.gridItemRoot + " " + estilo.order2 }}
          >
            <Card
              classes={{
                root: estilo.cardRoot + " " + estilo.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {'Datos de usuario'}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Datos personales
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Primer Nombre</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.nombre1 : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Segundo Nombre</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.nombre2 : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Primer Apellido</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.apellido1 : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Segundo Apellido</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.apellido2 : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Tipo de documento</FormLabel>
                        <Typography variant="body2"> {'Cédula'}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Documento</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.cedula : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Sexo</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? obtenerEtiquetaSexo(usuarioDatos.sexo) : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Nacimiento</FormLabel>
                        <Typography variant="body2"> {usuarioDatos != null ? formatoFecha(usuarioDatos.fechaNacimiento, "YYYY-MM-DD") : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Información de contacto
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Teléfono</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.telefono : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Celular</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.celular : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Correo electrónico</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.mail : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Dirección</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.direccion : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Departamento</FormLabel>
                        <Typography variant="body2"> {usuarioDatos ? usuarioDatos.departamento : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>País</FormLabel>
                        <Typography variant="body2"> {usuarioDatos && usuarioDatos.pais && usuarioDatos.pais.nombre ? usuarioDatos.pais.nombre : 'Desconocido'}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Datos médicos
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Mutualista</FormLabel>
                        <Typography variant="body2"> {usuarioDatos && usuarioDatos.mutualista ? usuarioDatos.mutualista.nombre : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Emergencia</FormLabel>
                        <Typography variant="body2"> {usuarioDatos && usuarioDatos.emergencia ? usuarioDatos.emergencia.nombre : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Vto Carnet de salud</FormLabel>
                        <Typography variant="body2">{usuarioDatos != null ? formatoFecha(usuarioDatos.vencimientoCarnetSalud, "YYYY-MM-DD") : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Acceso
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Tipo</FormLabel>
                        <Typography variant="body2"> {usuarioDatos && usuarioDatos.tipoUsuario && usuarioDatos.tipoUsuario.nombre ? usuarioDatos.tipoUsuario.nombre : 'No tiene'}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <FormGroup>
                        <FormLabel>Roles</FormLabel>
                        <Typography variant="body2"> {usuarioDatos && usuarioDatos.roles && usuarioDatos.roles.length > 0 ? usuarioDatos.roles.map(i => i.nombre + ", ") : 'No tiene'}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/inicio"
                      >
                        Inicio
                      </Button>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid >
      </Container >
    </>
  );
}

export default Perfil;
