import { queryPut } from "functions/controllers";
import { queryGet, queryPost, queryDelete } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Sistema/emergencia/";

export function peticionGetEmergencias() {
  return queryGet(url);
}

export function peticionPostEmergencia(elemento) {
  return queryPost(url, elemento);
}

export function peticionPutEmergencia(id, elemento) {
  return queryPut(url + id, elemento);
}

export function peticionDeleteEmergencia(id) {
  return queryDelete(url  + id);
}

export function peticionGetEmergencia(id) {
  return queryGet(url + id);
}
