import React, { useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import UserHeader from "components/Headers/UserHeader.js";
import { estiloPerfil } from "estilos/clases.js";
import { useSelector } from "react-redux";
import { useDispatch } from "../../../redux/store";
import * as ControllerUsuarios from 'controllers/Usuarios';
import * as ControllerMutualistas from 'controllers/Mutualistas';
import * as ControllerEmergencias from 'controllers/Emergencias';
import { useAlert } from "react-alert";
import Foto from "components/Formularios/Foto";
import Pasos from "components/Formularios/Pasos";
import { pasos } from "functions/usuarios";
import { evaluarForm } from "functions/formulario";
import { formatoFecha } from 'functions/fechas';
import { ObtenerPaises } from "functions/datosSistema";
import { ObtenerTiposUsuario } from "functions/datosSistema";
import { validarCI } from "functions/formulario";
import { b64aBlob } from "functions/formulario";
import { evaluarCorreo } from "functions/formulario";
import { evaluarNacimiento } from "functions/formulario";
import { evaluarTelefono } from "functions/formulario";
import { evaluarCelular } from "functions/formulario";
import AlertaDialogo from "components/Popups/AlertaDialogo";

function DatosPersonales(props) {
  let { id } = useParams();
  const theme = useTheme();
  const estilo = estiloPerfil();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const [itemsPasos, setItemsPasos] = React.useState([]);
  const itemActivo = 0;
  const paises = useSelector((state) => state.paises);
  const [emergencias, setEmergencias] = React.useState([]);
  const [mutualistas, setMutualistas] = React.useState([]);
  const [alertaCreacion, setAlertaCreacion] = React.useState(false);
  const [idGenerado, setIdGenerado] = React.useState();
  const [claveGenerada, setClaveGenerada] = React.useState();
  const tiposUsuario = useSelector((state) => state.tiposUsuario);
  const [usuario, setUsuario] = React.useState({});
  const [avatar, setAvatar] = React.useState();
  let [usuarioActualizado, setUsuarioActualizado] = React.useState({
    sexo: 'M', idPais: 214,
  });
  const camposRequeridos = [{ id: 'nombre1', nombre: 'Primer nombre' }, { id: 'apellido1', nombre: 'Primer apellido' },
  { id: 'cedula', nombre: 'Documento' }, { id: 'idEmergencia', nombre: 'Emergencia' },
  { id: 'idPais', nombre: 'País' }, { id: 'celular', nombre: 'Celular' },
  { id: 'departamento', nombre: 'Departamento' },{ id: 'direccion', nombre: 'Dirección' },
  { id: 'fechaNacimiento', nombre: 'Fecha de nacimiento' }, { id: 'mail', nombre: 'Correo' },
  { id: 'sexo', nombre: 'Sexo' }, { id: 'vencimientoCarnetSalud', nombre: 'Vto. Carnet de Salud' },
  { id: 'idMutualista', nombre: 'Mutualista' }, { id: 'idTipo', nombre: 'Posición' },];

  const peticion = async (tipo) => {
    const validarForm = evaluarForm(usuarioActualizado, camposRequeridos);
    let r = { resultado: false };
    if (!validarForm.resultado) {
      alert.error(validarForm.mensaje);
      return;
    }

    if (usuarioActualizado.hasOwnProperty('cedula') && usuarioActualizado.cedula) {
      const validarCedula = validarCI(usuarioActualizado.cedula.toString());
      if (!validarCedula.resultado) {
        alert.error(validarCedula.mensaje);
        return;
      }
    }

    if (usuarioActualizado.hasOwnProperty('mail') && usuarioActualizado.mail) {
      const validarCorreo = evaluarCorreo(usuarioActualizado.mail);
      if (!validarCorreo.resultado) {
        alert.error(validarCorreo.mensaje);
        return;
      }
    }

    if (usuarioActualizado.hasOwnProperty('fechaNacimiento') && usuarioActualizado.fechaNacimiento) {
      const validarNacimiento = evaluarNacimiento(usuarioActualizado.fechaNacimiento);
      if (!validarNacimiento.resultado) {
        alert.error(validarNacimiento.mensaje);
        return;
      }
    }

    if (usuarioActualizado.hasOwnProperty('telefono') && usuarioActualizado.telefono) {
      const validarTelefono = evaluarTelefono(usuarioActualizado.telefono);
      if (!validarTelefono.resultado) {
        alert.error(validarTelefono.mensaje);
        return;
      }
    }

    if (usuarioActualizado.hasOwnProperty('celular') && usuarioActualizado.celular) {
      const validarCelular = evaluarCelular(usuarioActualizado.celular);
      if (!validarCelular.resultado) {
        alert.error(validarCelular.mensaje);
        return;
      }
    }

    switch (tipo) {
      case "post":
        r = await ControllerUsuarios.peticionPostUsuario(usuarioActualizado);
        break;
      case "put":
        r = await ControllerUsuarios.peticionPutUsuario(id, usuarioActualizado);
        break;
      default:
        break;
    }
    if (r.resultado) {
      if(tipo ==="put"){
        alert.success(r.mensaje);
        history.push(`/app/equipo/datos/especialidades/${id}`);
        return;
      }
      setIdGenerado(r.data.idUsuario && r.data.idUsuario);
      setClaveGenerada(r.data && r.data.clave);
      abrirAlertaCreacion();
      return;
    } else {
      alert.error(r.mensaje);
    }
  }

  const redireccionSiguientePaso = () => {
    history.push(`/app/equipo/datos/especialidades/${idGenerado}`);
   };
  
  const abrirAlertaCreacion = () => {
    setAlertaCreacion(true);
   };

  const cerrarAlertaCreacion = () => {
   setAlertaCreacion(false);
  };

  const guardarDatos = async e => {
    await setUsuarioActualizado({
      ...usuarioActualizado,
      [e.target.name]: e.target.value
    });
  };

  const obtenerUsuario = async () => {
    if (id) {
      const r = await ControllerUsuarios.peticionGetUsuario(id);
      if (r.resultado) {
        const u = {
          ...r.data,
          idMutualista: r.data.mutualista.idMutualista,
          idEmergencia: r.data.emergencia.idEmergencia,
          idPais: r.data && r.data.pais ? r.data.pais.idPais : 214,
          idTipo: r.data && r.data.tipoUsuario ? r.data.tipoUsuario.idTipo : 214,
        };
        setUsuario(u);
        setUsuarioActualizado(u);
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerPaises = async () => {
    if (paises && paises.length === 0) {
      const r = await ObtenerPaises();
      dispatch({ "type": "CARGAR_PAISES", "data": r });
    }
  };

  const obtenerMutualistas = async () => {
    const r = await ControllerMutualistas.peticionGetMutualistas();
    if (r.resultado) {
      setMutualistas(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerEmergencias = async () => {
    const r = await ControllerEmergencias.peticionGetEmergencias();
    (r.resultado) ? setEmergencias(r.data) : alert.error(r.mensaje);
  }

  const obtenerTiposUsuario = async () => {
    if (tiposUsuario && tiposUsuario.length === 0) {
      const r = await ObtenerTiposUsuario();
      dispatch({ "type": "CARGAR_TIPOS_USUARIO", "data": r });
    }
  }

  const obtenerAvatar = async () => {
    const r = await ControllerUsuarios.peticionGetUsuarioAvatar(id);
    if (r.resultado) {
      if (r.data) {
        const blob = b64aBlob(r.data.base64 ? r.data.base64 : "");
        setAvatar(blob);
      }
    } else {
      alert.error(r.mensaje);
    }
  };

  useLayoutEffect(() => {
    obtenerPaises();
    obtenerEmergencias()
    obtenerMutualistas();
    obtenerTiposUsuario();
    setItemsPasos(pasos(id));
    obtenerUsuario();
  }, []);

  useLayoutEffect(() => {
    if (usuario && usuario.id) {
      obtenerAvatar();
    }
  }, [usuario])

  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: estilo.containerRoot }}
      >
        <Grid container>
          <Foto avatar={avatar} />
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: estilo.gridItemRoot + " " + estilo.order2 }}
          >
            <Card
              classes={{
                root: estilo.cardRoot + " " + estilo.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {id && usuario && usuario.nombre1 ?
                          `Datos de ${usuario.nombre1} ${usuario.apellido1} (${usuario.cedula})`
                          : "Datos de usuario nuevo"}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
              <Pasos items={itemsPasos} activo={itemActivo} />
              <CardContent>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Datos personales
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Primer Nombre*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="nombre1"
                            key={usuario ? usuario.nombre1 : 'usuario.nombre1'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={usuario ? usuario.nombre1 : ''}
                            inputProps={{ maxLength: 20 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Segundo Nombre</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.nombre2 : ''}
                            name="nombre2"
                            key={usuario ? usuario.nombre2 : 'usuario.nombre2'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{ maxLength: 20 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Primer Apellido*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.apellido1 : ''}
                            name="apellido1"
                            key={usuario ? usuario.apellido1 : 'usuario.apellido1'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{ maxLength: 20 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Segundo Apellido</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.apellido2 : ''}
                            name="apellido2"
                            key={usuario ? usuario.apellido2 : 'usuario.apellido2'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{ maxLength: 20 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Tipo de documento*</FormLabel>
                        <Typography variant="body2"> {'Cédula'}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Documento*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.cedula : ''}
                            name="cedula"
                            key={usuario ? usuario.cedula : 'usuario.cedula'}
                            onChange={(e) => guardarDatos(e)}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Sexo*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={(usuario && usuario.sexo) || "M"} name="sexo" key={usuario ? usuario.sexo : 'usuario.sexo'}
                            onChange={(e) => guardarDatos(e)}>
                            <MenuItem value="M">Masculino</MenuItem>
                            <MenuItem value="F">Femenino</MenuItem>
                            <MenuItem value="I">Indefinido</MenuItem>
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Nacimiento*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="date"
                            defaultValue={usuario && usuario.fechaNacimiento ? formatoFecha(usuario.fechaNacimiento, "YYYY-MM-DD") : ''}
                            name="fechaNacimiento"
                            key={usuario ? usuario.fechaNacimiento : 'usuario.fechaNacimiento'}
                            onChange={(e) => guardarDatos(e)}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Información de contacto
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Teléfono</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.telefono : ''}
                            name="telefono"
                            key={usuario ? usuario.telefono : 'usuario.telefono'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{ maxLength: 15 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Celular*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.celular : ''}
                            name="celular"
                            key={usuario ? usuario.celular : 'usuario.celular'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{ maxLength: 15 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Correo electrónico*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.mail : ''}
                            name="mail"
                            key={usuario ? usuario.mail : 'usuario.mail'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{ maxLength: 100 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Dirección*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.direccion : ''}
                            name="direccion"
                            key={usuario ? usuario.direccion : 'usuario.direccion'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{ maxLength: 50 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Departamento*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={usuario ? usuario.departamento : ''}
                            name="departamento"
                            key={usuario ? usuario.departamento : 'usuario.departamento'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{ maxLength: 50 }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>País*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={(usuario && usuario.pais && usuario.pais.idPais) || 214} name="idPais" key={usuario && usuario.pais ? usuario.pais.idPais : 'usuario.idPais'}
                            onChange={(e) => guardarDatos(e)}>
                            {paises && paises.map(p => {
                              return (
                                <MenuItem value={p.idPais}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Datos médicos
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Mutualista*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={usuario && usuario.idMutualista} name="idMutualista" key={usuario ? usuario.idMutualista : 'usuario.idMutualista'}
                            onChange={(e) => guardarDatos(e)}>
                            {mutualistas && mutualistas.map(p => {
                              return (
                                <MenuItem value={p.idMutualista}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Emergencia*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={usuario && usuario.idEmergencia} name="idEmergencia" key={usuario ? usuario.idEmergencia : 'usuario.idEmergencia'}
                            onChange={(e) => guardarDatos(e)}>
                            {emergencias && emergencias.map(p => {
                              return (
                                <MenuItem value={p.idEmergencia}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Vto Carnet de salud*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="date"
                            defaultValue={usuario != null ? formatoFecha(usuario.vencimientoCarnetSalud, "YYYY-MM-DD") : ''}
                            name="vencimientoCarnetSalud"
                            key={usuario ? usuario.vencimientoCarnetSalud : 'usuario.vencimientoCarnetSalud'}
                            onChange={(e) => guardarDatos(e)}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Tipo de usuario
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Posición*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={(usuario && usuario.tipoUsuario && usuario.tipoUsuario.idTipo)} name="idTipo" key={usuario && usuario.tipoUsuario ? usuario.tipoUsuario.idTipo : 'usuario.idTipo'}
                            onChange={(e) => guardarDatos(e)}>
                            {tiposUsuario && tiposUsuario.map(t => {
                              return (
                                <MenuItem value={t.idTipo}>{t.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/equipo/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => { id ? peticion('put') : peticion('post') }}
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid >
      </Container >

      {/*Alerta para mostrar nueva contraseña después de creación de usuario*/}
      <AlertaDialogo titulo={"Creación exitosa"} 
      texto={`Se ha creado satisfactoriamente el nuevo usuario${usuarioActualizado? ` ${usuarioActualizado.cedula}` : ''} con la siguiente contraseña autogenerada: \n${claveGenerada}
      \nPresione confirmar para continuar con el siguiente paso de edición de usuario.`} 
      abierto={alertaCreacion} id={idGenerado} funcionConfirmar={redireccionSiguientePaso}/>
    </>
  );
}

export default DatosPersonales;
