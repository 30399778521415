import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import * as ControllerPeriodontogramas from 'controllers/paciente/Periodontograma';
import * as ControllerPacientes from 'controllers/Pacientes';
import { useAlert } from "react-alert";
import { Periodontograma } from "components/Periodontograma/Periodontograma";
import HeadPaciente from "components/Perfil/Head_Paciente";
import { formatoFecha } from "functions/fechas";

const useStyles = makeStyles(componentStyles);


function PeriodontogramaGrafico() {
  let { idPaciente, idPeriodontograma } = useParams();
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const [paciente, setPaciente] = React.useState([]);
  const [periodontograma, setPeriodontograma] = React.useState();


  const obtenerPaciente = async () => {
    const r = await ControllerPacientes.peticionGetPaciente(idPaciente);
    if (r.resultado) {
      setPaciente(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  const peticionPost = async (periodontogramaNuevo) => {
    const r = await ControllerPeriodontogramas.peticionPostPeriodontograma(idPaciente, periodontogramaNuevo);
    if (r.resultado) {
      alert.success(r.mensaje);
      history.push(`/app/pacientes/periodontogramas/listado/${idPaciente}`);
    } else {
      alert.error(r.mensaje);
    }
  }

  async function obtenerPeriodontograma() {
    const r = await ControllerPeriodontogramas.peticionGetPeriodontograma(idPaciente,idPeriodontograma);
    if (r.resultado) {
      setPeriodontograma(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  useEffect(async () => {
    if (idPeriodontograma)
      obtenerPeriodontograma();
    if (idPaciente)
      obtenerPaciente();
  }, []);

  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container component={Box} marginBottom={"2rem"}>
          <HeadPaciente paciente={paciente} tipo={'salud'} />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            xl={12}
            justifyContent="center"
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Evaluación gráfica {periodontograma ? ` (${formatoFecha(periodontograma.fechaRegistro,'DD/MM/YYYY HH:MM')})` :''}
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          href={`/app/pacientes/periodontogramas/listado/${idPaciente}`}
                        >
                          Volver
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent style={{ backgroundColor: "white" }}>
                <div className={classes.plLg4}>
                  <Periodontograma datos={periodontograma} guardar={peticionPost} />
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href={`/app/pacientes/periodontogramas/listado/${idPaciente}`}
                      >
                        Volver
                      </Button>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default PeriodontogramaGrafico;
