import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import MUIDataTable from "mui-datatables";
import * as ControllerPacientes from 'controllers/Pacientes';
import * as ControllerCuentas from 'controllers/paciente/Cuenta';
import { useAlert } from "react-alert";
import { tabla_traduccion } from "traducciones/tabla_traduccion";
import Perfil_Paciente from "components/Perfil/Perfil_Paciente";
import AltaPago from "./datos/popup/altaPago";
import AltaVentaInsumo from "./datos/popup/altaVentaInsumo";
import { formatoFecha } from 'functions/fechas';
import AltaBonificacion from "./datos/popup/altaBonificacion";
import { useSelector } from "react-redux";
import AlertaDialogo from "components/Popups/AlertaDialogo";

const useStyles = makeStyles(componentStyles);

function Cuenta() {
  let { id } = useParams();
  const classes = useStyles();
  const alert = useAlert();
  const [paciente, setPaciente] = React.useState([]);
  const [registros, setRegistros] = React.useState([]);
  const [mostrarEliminar, setMostrarEliminar] = React.useState(false);
  const [mostrarAltaPago, setMostrarAltaPago] = React.useState(false);
  const [mostrarAltaBonificacion, setMostrarAltaBonificacion] = React.useState(false);
  const [mostrarAltaVentaInsumo, setMostrarAltaVentaInsumo] = React.useState(false);
  const [registrosSeleccionados, setRegistrosSeleccionados] = React.useState([]);
  const [filasSeleccionados, setFilasSeleccionados] = React.useState([]);
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.cuenta : []);

  const abrirModalEliminar = () => {
    setMostrarEliminar(true);
  };

  const cerrarModalEliminar = () => {
    setMostrarEliminar(false);
  };

  const eliminarMovimientos = async (elementos) => {
    cerrarModalEliminar();

    if (!id) {
      alert.error("No se tiene referencia al paciente vinculado a la cuenta");
      return;
    }

    const cantidadRegistros = elementos.length;
    let cantidadSatisfactorios = 0;

    setFilasSeleccionados([]);

    for (const elemento of elementos) {
      const r = await ControllerCuentas.peticionDeleteCuenta(id, elemento.idMovimiento);
      if (r.resultado)
        cantidadSatisfactorios = cantidadSatisfactorios + 1;
    }

    if (cantidadRegistros === cantidadSatisfactorios) {
      obtenerCuentas();
      alert.success("Se eliminarion todos los registros seleccionados");
      return;
    }

    alert.error(`Se pudieron eliminar ${cantidadSatisfactorios} movimientos de ${cantidadRegistros} seleccionados`)
  }

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
        obtenerCuentas();
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const abrirPopUPAltaPago = () => {
    setMostrarAltaPago(true);
  };

  const cerrarPopUPAltaPago = () => {
    setMostrarAltaPago(false);
  };

  const abrirPopUPAltaBonificacion = () => {
    setMostrarAltaBonificacion(true);
  };

  const cerrarPopUPAltaBonificacion = () => {
    setMostrarAltaBonificacion(false);
  };

  const abrirPopUPAltaVentaInsumo = () => {
    setMostrarAltaVentaInsumo(true);
  };

  const cerrarPopUPAltaVentaInsumo = () => {
    setMostrarAltaVentaInsumo(false);
  };

  const obtenerCuentas = async () => {
    const r = await ControllerCuentas.peticionGetCuentas(id);
    (r.resultado) ? setRegistros(r.data ? r.data.sort((a,b)=>new Date(b.fechaRegistro) - new Date(a.fechaRegistro)):[]) : alert.error(r.mensaje);
  }

  function registrosDeseleccionados(dataIndex, selectedRows, data) {
    if(!permisos.anular)
      return registros.some((r, i) => false);
    return registros.some((r, i) => i === dataIndex && r.fechaAnulado === null);
  }

  function seleccionarRegistro(rowsSelectedData, allRows, rowsSelected) {
    setFilasSeleccionados(rowsSelected);
    setRegistrosSeleccionados(registros.filter((r, i) => Object.values(rowsSelected).includes(i)));
  }

  function eliminarSeleccion(rowsDeleted, data, newTableData) {
    const cuentasABorrar = registros.filter((r) => rowsDeleted.map(x => x.idMovimiento).includes(r.idMovimiento));
    if (cuentasABorrar.length > 0)
      eliminarMovimientos(cuentasABorrar);
  }

  useLayoutEffect(() => {
    obtenerPaciente();
  }, []);

  return (
    <>
      <UserHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            style={{ "marginTop": "3rem" }}
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Cuenta Corriente
                      </Box>
                    </Grid>
                    <Grid container>
                      {permisos && permisos.vender &&
                        <Grid item lg={3} xs={3}>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={abrirPopUPAltaVentaInsumo}
                          >
                            Venta
                          </Button>
                        </Grid>}
                      {permisos && permisos.pagar &&
                        <Grid item lg={3} xs={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={abrirPopUPAltaPago}
                          >
                            Pagar
                          </Button>
                        </Grid>}
                      {permisos && permisos.bonificar &&
                        <Grid item lg={3} xs={3}>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={abrirPopUPAltaBonificacion}
                          >
                            Bonificación
                          </Button>
                        </Grid>}
                    </Grid>
                  </Grid>
                } classes={{ root: classes.cardHeaderRoot }}

              ></CardHeader>
              <CardContent style={{ backgroundColor: "white" }}>
                <MUIDataTable
                  title={""}
                  data={(!registros) ? [] :
                    registros.map(item => {
                      return [
                        (item.fechaRegistro) ? formatoFecha(item.fechaRegistro, "DD/MM/YYYY HH:MM") : 'Desconocido',
                        (item.tipoMovimiento) ? item.tipoMovimiento : 'Desconocido',
                        (item.detalle) ? item.detalle : 'Desconocido',
                        (item.monto) ? item.monto : 'Desconocido',
                        (item.nombreFuncionario && item.apellidoFuncionario) ? `${item.nombreFuncionario} ${item.apellidoFuncionario}` : 'Desconocido',
                        (item.fechaAnulado) ? item.fechaAnulado : 'N/A',
                        (item.nombreFuncionarioAnula && item.apellidoFuncionarioAnula) ? `${item.nombreFuncionarioAnula} ${item.apellidoFuncionarioAnula}` : 'N/A'
                      ]
                    })}
                  columns={[{ name: "fechaRegistro", label: "Creación" }, { name: "tipoMovimiento", label: "Tipo" }, { name: "detalle", label: "Detalle" },
                  { name: "monto", label: "Monto" }, { name: "nombreFuncionario", label: "Creador" }, { name: "fechaAnulado", label: "Anulado" }, { name: "nombreFuncionarioAnula", label: "Anulador" }]}
                  options={{
                    filterType: 'dropdown',
                    responsive:'standard',
                    elevation: 0,
                    isRowSelectable: registrosDeseleccionados,
                    rowsSelected: filasSeleccionados,
                    onRowSelectionChange: seleccionarRegistro,
                    onRowsDelete: abrirModalEliminar,
                    textLabels: tabla_traduccion
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Perfil_Paciente paciente={paciente} tipo={'cuenta'} />
        </Grid>
      </Container>

      {mostrarEliminar && <AlertaDialogo abierto={mostrarEliminar} titulo={"Seguro quieres anular los movimientos seleccionados?"}
        texto={"Tenga en cuenta que sólo puede anular movimientos de cuenta realizados en el día y que luego esta acción no tiene vuelta atrás"}
        id={registrosSeleccionados} funcionConfirmar={eliminarSeleccion} funcionCerrar={cerrarModalEliminar} />}

      {/* PopUp Alta de Pago */}
      {mostrarAltaPago && <AltaPago id={id} mostrarAlta={mostrarAltaPago} cerrarPopUPAlta={cerrarPopUPAltaPago} recargarDatos={obtenerCuentas} valores={[]} />}

      {/* PopUp Alta de Bonificacion */}
      {mostrarAltaBonificacion && <AltaBonificacion id={id} mostrarAlta={mostrarAltaBonificacion} cerrarPopUPAlta={cerrarPopUPAltaBonificacion} recargarDatos={obtenerCuentas} valores={[]} />}

      {/* PopUp Venta de Insumo */}
      {mostrarAltaVentaInsumo && <AltaVentaInsumo id={id} mostrarAlta={mostrarAltaVentaInsumo} cerrarPopUPAlta={cerrarPopUPAltaVentaInsumo} recargarDatos={obtenerCuentas} valores={[]} />}

    </>

  );
}

export default Cuenta;