import React, { useEffect } from "react";
import { useLocation, Switch, Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Search from "@material-ui/icons/Search";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";
import { obtenerRutas, obtenerRutasSegunRol, obtenerRutaTitulo } from "../controllers/Rutas.js";
import componentStyles from "assets/theme/layouts/admin.js";
import Login from "../views/autenticacion/login";
import { useSelector } from "react-redux";
import { evaluarForm } from "functions/formulario.js";
import { useAlert } from "react-alert";
import Carga from "components/Efectos/Carga.js";

const useStyles = makeStyles(componentStyles);

const Admin = (props) => {
  const history = useHistory();
  const alert = useAlert();
  const classes = useStyles();
  const ubicacion = useLocation();
  const usuario = useSelector((state) => state.usuario);
  const sesion = useSelector((state) => state.sesion);
  const carga = useSelector((state) => state.carga);
  const [busquedaPaciente, setBusquedaPaciente] = React.useState({});
  const permisos = useSelector((state) => state.usuario && state.usuario.permisos ? state.usuario.permisos.paciente : []);
  const camposRequeridos = [{ id: 'criterio', nombre: 'Búsqueda' }];

  const guardarDatos = async e => {
    await setBusquedaPaciente({ 'criterio': e.target.value });
  };

  const buscarPaciente = (busqueda) => {
    if (busqueda && busqueda.criterio && busqueda.criterio.length > 3) {
      const validarForm = evaluarForm(busqueda, camposRequeridos);
      if (!validarForm.resultado) {
        alert.error(validarForm.mensaje);
        return;
      }
      const url = `/app/pacientes/listado/${busqueda.criterio}`;
      history.push(url);
      history.go();
    } else {
      alert.error('El criterio de búsqueda debe tener más de 3 carácteres');
    }
  }


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [ubicacion]);

  if (!sesion.token)
    return (<Login />);

  return (
    <>
      <>
        <Sidebar
          {...props}
          routes={obtenerRutas(usuario.roles)}
          logo={{
            innerLink: "/app/inicio",
            imgSrc: require("../assets/img/brand/logo.png").default,
            imgAlt: "...",
          }}
          dropdown={<NavbarDropdown />}
          input={permisos && permisos.buscar &&
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="criterioSlideBar">
                Buscar paciente
              </InputLabel>
              <OutlinedInput
                id='criterioSlideBar'
                name='criterioSlideBar'
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      component={Search}
                      width="1.25rem!important"
                      height="1.25rem!important"
                    />
                  </InputAdornment>
                }
                labelWidth={110}
                onChange={(e) => guardarDatos(e)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    buscarPaciente(busquedaPaciente);
                    ev.preventDefault();
                  }
                }}
              />
            </FormControl>
          }
        />
        <Box position="relative" className={classes.mainContent}>
          {<AdminNavbar {...props} buscarPaciente={buscarPaciente} brandText={obtenerRutaTitulo(props.location)} />}
          <Switch>
            {obtenerRutasSegunRol(usuario.roles)}
            <Redirect from="*" to="/app/inexistente" />
          </Switch>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
          </Container>
        </Box>

        {/*Overlay de página cargando*/}
        <Carga mostrar={carga} />
      </>
    </>
  )

};

export default Admin;
