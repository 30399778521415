import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogTitle, Dialog, Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import FilledInput from "@material-ui/core/FilledInput";
import * as ControllerCuentas from "controllers/paciente/Cuenta"
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";
import { evaluarForm } from "functions/formulario";

export default function AltaBonificacion({ id, mostrarAlta, cerrarPopUPAlta, recargarDatos }) {
    const alert = useAlert();
    const [bonificacionNuevo, setBonificacionNuevo] = React.useState();
    const camposRequeridos = [{ id: 'detalle', nombre: 'Concepto' }, { id: 'monto', nombre: 'Monto' }];

    const guardarDatos = async e => {
        await setBonificacionNuevo({
            ...bonificacionNuevo,
            [e.target.name]: e.target.value
        });
    };

    const peticionPost = async () => {
        const validarForm = evaluarForm(bonificacionNuevo, camposRequeridos);
        if (!validarForm.resultado) {
            alert.error(validarForm.mensaje)
            return;
        }
        cerrarPopUPAlta();
        const r = await ControllerCuentas.peticionPostBonificacion(id, bonificacionNuevo);
        if (r.resultado) {
            alert.success(r.mensaje);
            recargarDatos();
        } else {
            alert.error(r.mensaje);
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            open={mostrarAlta}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrarPopUPAlta}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="form-dialog-title" >
                <Typography variant="h4">Confirmación de bonificacion</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>Concepto</FormLabel>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                    name="detalle"
                                    key="detalle"
                                    onChange={(e) => guardarDatos(e)}
                                    inputProps={{maxLength: 100}}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Monto</FormLabel>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="number"
                                    defaultValue="0"
                                    name="monto"
                                    key="monto"
                                    onChange={(e) => guardarDatos(e)}
                                    inputProps={{min: 0, max:999999999}}
                                />
                            </FormControl>
                        </FormGroup>                   
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                        <Button color="primary" variant="contained" onClick={() => peticionPost()}>
                            Confirmar
                        </Button>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}