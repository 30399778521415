import { queryGet } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Log/";
const cantidadPagina= 10;

export function peticionGetLogs(pagina) {
  let paginado="";
  if(pagina)
    paginado = `?pagina=${pagina}&cantidad=${cantidadPagina}`;
  return queryGet(url  + paginado);
}

export function peticionGetLog(id) {
  return queryGet(url + id);
}

export function peticionGetErrores(pagina) {
  let paginado="";
  if(pagina)
    paginado = `?pagina=${pagina}&cantidad=${cantidadPagina}`;
  return queryGet(url  + "error/" + paginado);
}

export function peticionGetError(id) {
  return queryGet(url + "error/" + id);
}
