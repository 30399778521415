const pasos = (id) => {
  if (id) {
    return [{ etiqueta: "Personales", url: "/app/equipo/datos/personales/" + id },
    { etiqueta: "Especialidades", url: "/app/equipo/datos/especialidades/" + id },
    { etiqueta: "Roles", url: "/app/equipo/datos/roles/" + id },
    { etiqueta: "Avatar", url: "/app/equipo/datos/avatar/" + id }];
  }
  return [{ etiqueta: "Personales", url: "" },
  { etiqueta: "Roles", url: "" },
  { etiqueta: "Especialidades", url: "" },
  { etiqueta: "Avatar", url: "" }];
};


export { pasos };