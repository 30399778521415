import { accesoPorRoles } from "datos/permisos";

const validarPermiso = (rolesUsuario, rolesRequeridos) => {
  if (rolesRequeridos.includes(0))
    return true;
  for (let i = 0; i < rolesUsuario.length; i++) {
    if (rolesRequeridos.includes(rolesUsuario[i]))
      return true;
  }
  return false;
};

const obtenerPermisos = (rolesUsuario) => {
  let permisos = {};
  Object.keys(accesoPorRoles).forEach(seccion => {
    let registro = {};
    Object.keys(accesoPorRoles[seccion]).map(accion => {
      registro[accion] = Object.values(accesoPorRoles[seccion][accion]).includes(0) || rolesUsuario.some(idRol => Object.values(accesoPorRoles[seccion][accion]).includes(idRol))
    })
    permisos[seccion] = registro;
  });
  return permisos;
};


export { validarPermiso, obtenerPermisos };