import { queryGet, queryPost, queryDelete, queryPut } from "functions/controllers";
const urlPaciente = process.env.REACT_APP_API_URL + "Paciente";
const urlCuenta = "movimiento";

export async function peticionGetCuentas(idPaciente) {
  return queryGet(obtenerUrl(idPaciente));
}

  export async function peticionPostCuenta (idPaciente, CuentaNueva) {
    return queryPost(obtenerUrl(idPaciente), CuentaNueva);
  }

  export async function peticionPostPago (idPaciente, CuentaNueva) {
    return queryPost(`${urlPaciente}/${idPaciente}/pago/`, CuentaNueva);
  }

  export async function peticionPostBonificacion(idPaciente, CuentaNueva) {
    return queryPost(`${urlPaciente}/${idPaciente}/bonificacion/`, CuentaNueva);
  }

  export async function peticionPutCuenta(idPaciente, cuentaModificada_datos) {
    return queryPut(obtenerUrl(idPaciente), cuentaModificada_datos);
  }

  export async function peticionDeleteCuenta(idPaciente, idElemento) {
    return queryDelete(obtenerUrl(idPaciente) + idElemento);
  }

  export async function peticionGetSaldo(idPaciente) {
    return queryGet(`${urlPaciente}/${idPaciente}/saldo/`);
  }

  function obtenerUrl(idPaciente) {
    return `${urlPaciente}/${idPaciente}/${urlCuenta}/`;
  }