import { queryGet, queryPost, queryPut } from "functions/controllers";
const urlPaciente = process.env.REACT_APP_API_URL + "Paciente";
const urlCitas = "reservas";

export async function peticionGetCitasPendientes(idPaciente) {
  return queryGet(obtenerUrl(idPaciente) + "pendientes");
}

export async function peticionGetCitasCanceladas(idPaciente) {
  return queryGet(obtenerUrl(idPaciente) + "canceladas");
}

export async function peticionGetCitasConcretadas(idPaciente) {
  return queryGet(obtenerUrl(idPaciente) + "concretadas");
}

export async function peticionPostCitas(idPaciente, citaNueva) {
  return queryPost(obtenerUrl(idPaciente), citaNueva);
}

export async function peticionPutCitas(idPaciente, citaModificada_datos) {
  return queryPut(obtenerUrl(idPaciente), citaModificada_datos);
}

function obtenerUrl(idPaciente) {
  return `${urlPaciente}/${idPaciente}/${urlCitas}/`;
}