import React from "react";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogContentText, DialogTitle, Dialog } from '@material-ui/core';
import * as ControllerPacientes from "controllers/Pacientes"
import { useAlert } from "react-alert";
import { evaluarForm } from "functions/formulario";
import { Transicion } from "components/Efectos/Transicion";
import { evaluarTelefono } from "functions/formulario";

export default function AltaContacto({ id, mostrarAltaContacto, cerrarPopUPAltaContacto, obtenerContactos }) {
    const alert = useAlert();
    let contactoNuevo = { nombre: "", telefono: "" };
    const camposRequeridos = [{ id: 'nombre', nombre: 'Nombre' },
    { id: 'telefono', nombre: 'Teléfono' },];

    const guardarDatos = async e => {
        const campoNombre = e.target.name;
        const campoValor = e.target.value;
        switch (campoNombre) {
            case "nombre":
                contactoNuevo = { ...contactoNuevo, nombre: campoValor }
                break;
            case "telefono":
                contactoNuevo = { ...contactoNuevo, telefono: campoValor }
                break;
            default:
                break;
        }
    };

    const peticionPost = async () => {
        const v = evaluarForm(contactoNuevo, camposRequeridos);
        cerrarPopUPAltaContacto();
        if (v.resultado) {

            if (contactoNuevo.hasOwnProperty('telefono') && contactoNuevo.telefono) {
                const validarTelefono = evaluarTelefono(contactoNuevo.telefono);
                if (!validarTelefono.resultado) {
                    alert.error(validarTelefono.mensaje);
                    return;
                }
            }

            const r = await ControllerPacientes.peticionPostContacto(id, [contactoNuevo]);
            if (r.resultado) {
                alert.success(r.mensaje);
                obtenerContactos();
            } else {
                alert.error(r.mensaje);
            }
        } else {
            alert.error(v.mensaje);
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            open={mostrarAltaContacto}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrarPopUPAltaContacto}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Alta de contacto</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Ingrese los datos del nuevo contacto del paciente.
                </DialogContentText>
                <FormGroup>
                    <FormLabel>Nombre:</FormLabel>
                    <FormControl
                        variant="filled"
                        component={Box}
                        width="80%"
                        marginBottom="1rem!important"
                    >
                        <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue=""
                            name="nombre"
                            key="nombre"
                            onChange={guardarDatos}
                            inputProps={{ maxLength: 100 }}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Teléfono:</FormLabel>
                    <FormControl
                        variant="filled"
                        component={Box}
                        width="80%"
                        marginBottom="1rem!important"
                    >
                        <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue=""
                            name="telefono"
                            key="telefono"
                            onChange={guardarDatos}
                            inputProps={{ maxLength: 50 }}
                        />
                    </FormControl>
                </FormGroup>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button color="secondary" variant="contained" onClick={cerrarPopUPAltaContacto}>
                        Cerrar
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => peticionPost()} >
                        Confirmar
                    </Button>
                </Box>

            </DialogContent>
        </Dialog>
    );
}