import { queryDeleteBody } from "functions/controllers";
import { queryGet, queryPost, queryDelete, queryPut, queryPostForm} from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Paciente/";
const cantidadPagina= 10;

export function peticionGetPacientes(pagina) {
  let paginado="";
  if(pagina)
    paginado = `?pagina=${pagina}&cantidad=${cantidadPagina}`;
  return queryGet(url  + paginado);
}

export async function peticionGetPaciente(id) {
  return queryGet(url + id);
}

export function peticionPostPaciente(elemento) {
  return queryPost(url, elemento);
}

export function peticionDeletePaciente(id) {
  return queryDelete(url  + id);
}

export function peticionPutPaciente(id, elemento) {
  return queryPut(url  + id, elemento);
}

export async function peticionPostPacienteAvatar(id, elemento) {
  return queryPostForm(url  + id + "/avatar", elemento);
}

export async function peticionGetPacienteAvatar(id) {
  return queryGet(url  + id + "/avatar");
}

export async function peticionGetPacientePatologias(id) {
  return queryGet(url + id + "/patologias");
}

export async function peticionGetPacienteMedicamentos(id) {
  return queryGet(url + id + "/medicamentos");
}

export async function peticionGetPacienteAlergias(id) {
  return queryGet(url + id + "/alergias");
}

export async function peticionDeletePacientePatologias(id, idElemento) {
  return queryDeleteBody(url + id + "/patologias/", idElemento);
}

export async function peticionDeletePacienteMedicamentos(id, idElemento) {
  return queryDeleteBody(url + id + "/medicamentos/", idElemento);
}

export async function peticionDeletePacienteAlergias(id, idElemento) {
  return queryDeleteBody(url + id + "/alergias/", idElemento);
}

export async function peticionPostPacientePatologias(id, idElemento) {
  return queryPost(url + id + "/patologias/", idElemento);
}

export async function peticionPostPacienteMedicamentos(id, idElemento) {
  return queryPost(url + id + "/medicamentos/", idElemento);
}

export async function peticionPostPacienteAlergias(id, idElemento) {
  return queryPost(url + id + "/alergias/", idElemento);
}

export async function peticionGetContactos(id) {
  return queryGet(url + id + "/contacto");
}

export async function peticionDeleteContacto(id, idElemento) {
  return queryDelete(url + id + "/contacto/" + idElemento);
}

export async function peticionPostContacto(id, elemento) {
  return queryPost(url + id + "/contacto",elemento);
}

export async function peticionGetDocumentos(id) {
  return queryGet(url + id + "/documento");
}

export async function peticionGetDocumento(id, idElemento) {
  return queryGet(url + id + "/documento/" + idElemento);
}

export async function peticionPostDocumento(id, elemento) {
  return queryPostForm(url + id + "/documento", elemento);
}

export async function peticionDeleteDocumento(id, idElemento) {
  return queryDelete(url + id + "/documento/" + idElemento);
}

export function peticionGetPacientesCriterio(elemento) {
  return queryPost(url + "buscar/",elemento);
}

export async function peticionGetTratamientos(id) {
  return queryGet(url + id + "/tratamientos");
}

export async function peticionGetTratamientosPieza(id, idElemento) {
  return queryGet(url + id + "/tratamiento/" + idElemento);
}