import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

function Carga({mostrar}) {
  return (
    <Backdrop style={{'zIndex':9999}}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={mostrar}
      >
      <CircularProgress style={{'color':'white'}} />
    </Backdrop>
  );
}

export default Carga;