import React from "react";


import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/icons.js";
import Boton from "components/Tabla/Boton";
import { useSelector } from "react-redux";

const useStyles = makeStyles(componentStyles);

const Opciones = () => {
  const classes = useStyles();
  const theme = useTheme();
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.configuracion : []);

  return (
    <>
      <Header />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        {/* Table */}
        <Grid container component={Box} marginBottom="39px">
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                className={classes.cardHeader}
                title="Listado de ajustes"
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
              ></CardHeader>
              <CardContent>
                <Grid
                  container
                  component={Box}
                  alignItems="center"
                >
                  {permisos && permisos.patologias &&
                  <Grid item xs={12} lg={3}>
                    <Boton url="/app/patologias/listado" nombre="Patologias" clase="ni ni-collection" />
                  </Grid>}
                  {permisos && permisos.medicamentos &&
                  <Grid item xs={12} lg={3}>
                    <Boton url="/app/medicamentos/listado" nombre="Medicamentos" clase="ni ni-badge" />
                  </Grid>}
                  {permisos && permisos.mutualistas &&
                  <Grid item xs={12} lg={3}>
                    <Boton url="/app/mutualistas/listado" nombre="Mutualistas" clase="ni ni-sound-wave" />
                  </Grid>}
                  {permisos && permisos.emergencias &&
                  <Grid item xs={12} lg={3}>
                    <Boton url="/app/emergencias/listado" nombre="Emergencias" clase="ni ni-ambulance" />
                  </Grid>}
                  {permisos && permisos.alergias &&
                  <Grid item xs={12} lg={3}>
                    <Boton url="/app/alergias/listado" nombre="Alergias" clase="ni ni-user-run" />
                  </Grid>}
                  {permisos && permisos.consultorios &&
                  <Grid item xs={12} lg={3}>
                    <Boton url="/app/consultorios/listado" nombre="Consultorios" clase="ni ni-shop" />
                  </Grid>}
                  {permisos && permisos.registros &&
                  <Grid item xs={12} lg={3}>
                    <Boton url="/app/configuracion/registros" nombre="Registros" clase="ni ni-book-bookmark" />
                  </Grid>}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Opciones;
