import React, { useEffect } from 'react';
import { PeriodontogramaTablaInferior } from './TablaInferior';
import { PeriodontogramaTablaSuperior } from './TablaSuperior';
import { useAlert } from "react-alert";
import { Box, Button, Container, FilledInput, FormControl, FormGroup, FormLabel, Grid, TextField, Typography } from '@material-ui/core';
import { AssignmentOutlined } from '@material-ui/icons';
import { calcularPiezasDisponibles } from 'functions/periodontograma';
import { calcularPorcentajePeriodontograma } from 'functions/periodontograma';
import { calcularProfundidad } from 'functions/periodontograma';
import { calcularInsercion } from 'functions/periodontograma';
import { Autocomplete } from '@material-ui/lab';
import * as ControllerConsultorios from 'controllers/Consultorios';
import * as ControllerUsuarios from 'controllers/Usuarios';

export function Periodontograma({ datos, guardar }) {
    const alert = useAlert();
    const [profesionales, setProfesionales] = React.useState([]);
    const [consultorios, setConsultorios] = React.useState([]);
    const [piezasSuperior, setPiezasSuperior] = React.useState([]);
    const [piezasInferior, setPiezasInferior] = React.useState([]);
    const encabezadoSuperior = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    const etiquetasEncabezadoSuperior = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28];
    const encabezadoInferior = [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32];
    const etiquetasEncabezadoInferior = [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];
    const [porcentajePlaca, setPorcentajePlaca] = React.useState(0);
    const [porcentajeSupuracion, setPorcentajeSupuracion] = React.useState(0);
    const [porcentajeSangrado, setPorcentajeSangrado] = React.useState(0);
    const [medidaInsercion, setMedidaInsercion] = React.useState(0);
    const [medidaProfundidad, setMedidaProfundidad] = React.useState(0);
    const [periodontograma, setPeriodontograma] = React.useState();
    const [cargaConsultorio, setCargaConsultorio] = React.useState({});
    const [cargaProfesional, setCargaProfesional] = React.useState({});
    const piezaVacia = {
        "idPieza": 0,
        "ausencia": false,
        "implante": false,
        "idMovilidad": 0,
        "detalle":"",
        "caras": [
            {
                "idCara": 3,
                "cara": { idCara: 3 },
                "furcacion1": 0,
                "furcacion2": 0,
                "sangrado1": false,
                "sangrado2": false,
                "sangrado3": false,
                "supuracion1": false,
                "supuracion2": false,
                "supuracion3": false,
                "placa1": false,
                "placa2": false,
                "placa3": false,
                "margen1": 0,
                "margen2": 0,
                "margen3": 0,
                "profundidad1": 0,
                "profundidad2": 0,
                "profundidad3": 0
            },
            {
                "idCara": 2,
                "cara": { idCara: 2 },
                "furcacion1": 0,
                "furcacion2": 0,
                "sangrado1": false,
                "sangrado2": false,
                "sangrado3": false,
                "supuracion1": false,
                "supuracion2": false,
                "supuracion3": false,
                "placa1": false,
                "placa2": false,
                "placa3": false,
                "margen1": 0,
                "margen2": 0,
                "margen3": 0,
                "profundidad1": 0,
                "profundidad2": 0,
                "profundidad3": 0
            }
        ]
    };

    const crear = () => {
        const elemento = {
            ...periodontograma,
            "piezas": piezasSuperior.concat(piezasInferior)
        };
        guardar(elemento);
    }

    const calcularResumen = () => {
        const cantidadPiezasDisponibles = calcularPiezasDisponibles(piezasInferior) + calcularPiezasDisponibles(piezasSuperior);
        let cantidadPlaca = 0;
        let cantidadSangrado = 0;
        let cantidadSupuracion = 0;
        let totalProfundidad = 0;
        let totalMargen = 0;
        const piezasTotales = piezasSuperior.concat(piezasInferior);

        for (let i = 0; i < piezasTotales.length; i++) {
            if (piezasTotales[i] && piezasTotales[i].caras) {
                cantidadPlaca = cantidadPlaca + piezasTotales[i].caras.reduce((suma, e) => { return suma + e.placa1 + e.placa2 + e.placa3; }, 0);
                cantidadSangrado = cantidadSangrado + piezasTotales[i].caras.reduce((suma, e) => { return suma + e.sangrado1 + e.sangrado2 + e.sangrado3; }, 0);
                cantidadSupuracion = cantidadSupuracion + piezasTotales[i].caras.reduce((suma, e) => { return suma + e.supuracion1 + e.supuracion2 + e.supuracion3; }, 0);
                totalMargen = totalMargen + piezasTotales[i].caras.reduce((suma, e) => { return suma + e.margen1 + e.margen2 + e.margen3; }, 0);
                totalProfundidad = totalProfundidad + piezasTotales[i].caras.reduce((suma, e) => { return suma + e.profundidad1 + e.profundidad2 + e.profundidad3; }, 0);
            }
        }
        setPorcentajePlaca(calcularPorcentajePeriodontograma(cantidadPlaca, cantidadPiezasDisponibles));
        setPorcentajeSangrado(calcularPorcentajePeriodontograma(cantidadSangrado, cantidadPiezasDisponibles));
        setPorcentajeSupuracion(calcularPorcentajePeriodontograma(cantidadSupuracion, cantidadPiezasDisponibles));
        setMedidaInsercion(calcularInsercion(totalProfundidad, totalMargen, cantidadPiezasDisponibles));
        setMedidaProfundidad(calcularProfundidad(totalProfundidad, cantidadPiezasDisponibles));
    };


    const guardarPiezas = (ubicacion, elementos) => {
        switch (ubicacion) {
            case 'superior':
                setPiezasSuperior(elementos);
                break;
            case 'inferior':
                setPiezasInferior(elementos);
                break;
            default:
                break;
        }
    }

    const generarEstructura = (ubicacion) => {
        const encabezado = ubicacion === 'superior' ? encabezadoSuperior : encabezadoInferior;
        return encabezado.map(i => { return { ...piezaVacia, "idPieza": i } });
    };

    const obtenerGrafico = (ubicacion, pieza, cara) => {
        const piezas = ubicacion === 'superior' ? piezasSuperior : piezasInferior;
        if (piezas && piezas.length > 0) {
            const datosPieza = piezas.find(i => (i.idPieza === parseInt(pieza)) || (i.pieza && i.pieza.idPieza === parseInt(pieza)));
            const datosPiezaCara = datosPieza && datosPieza.caras ? datosPieza.caras.find(i => (i.cara && i.cara.idCara === parseInt(cara)) || (i.cara && i.cara.idCara === parseInt(cara))) : [];
            if (datosPieza && datosPiezaCara) {
                return {
                    primario: [datosPiezaCara.margen1, datosPiezaCara.margen2, datosPiezaCara.margen3],
                    secundario: [datosPiezaCara.profundidad1, datosPiezaCara.profundidad2, datosPiezaCara.profundidad3]
                };
            }
        }
        return { primario: [0, 0, 0], secundario: [0, 0, 0] };
    };

    const obtenerGraficoImagen = (ubicacion, pieza, cara) => {
        const piezas = ubicacion === 'superior' ? piezasSuperior : piezasInferior;
        const caraLetra = parseInt(cara) === 3 ? "a" : "b";
        if (piezas && piezas.length > 0) {
            const datosPieza = piezas.find(i => i.idPieza === parseInt(pieza));
            if (datosPieza) {
                if (datosPieza.ausencia)
                    return `${pieza}${caraLetra}_ausente`
                if (datosPieza.implante)
                    return `${pieza}${caraLetra}_implante`
                return `${pieza}${caraLetra}`
            }
        }
        return `${pieza}${caraLetra}`;
    };

    const guardarDatosPiezaBloquear = (ubicacion, e) => {
        if (e && e.target && e.target.attributes) {
            const piezaBuscada = parseInt(e.target.attributes['data-pieza'].value);
            const atributo = e.target.attributes['data-atributo'].value;
            const valor = e.target.checked;

            bloquearPieza(ubicacion, piezaBuscada, atributo, valor);
        }
    };

    const obtenerValorPieza = (ubicacion, piezaBuscada, atributo, defecto) => {
        const piezas = ubicacion === 'superior' ? piezasSuperior : piezasInferior;
        const indexPieza = piezas && piezas.findIndex(i => i.idPieza === piezaBuscada);
        return (indexPieza > -1) ? piezas[indexPieza][atributo] : defecto;
    }

    const guardarDatosPiezaTexto = async (ubicacion, e) => {
        if (e && e.target && e.target.attributes) {
            const piezaBuscada = parseInt(e.target.attributes['data-pieza'].value);
            const atributo = e.target.attributes['data-atributo'].value;
            const valor = e.target.value;

            guardarDatosPieza(ubicacion, piezaBuscada, atributo, valor);
        }
    }

    const guardarDatosPiezaNumero = async (ubicacion, e) => {
        if (e && e.target && e.target.attributes) {
            const piezaBuscada = parseInt(e.target.attributes['data-pieza'].value);
            const atributo = e.target.attributes['data-atributo'].value;
            const valor = parseInt(e.target.value);

            guardarDatosPieza(ubicacion, piezaBuscada, atributo, valor);
        }
    }

    const guardarDatosPiezaCheck = async (ubicacion, e) => {
        if (e && e.target && e.target.attributes) {
            const piezaBuscada = parseInt(e.target.attributes['data-pieza'].value);
            const atributo = e.target.attributes['data-atributo'].value;
            const valor = e.target.checked;

            guardarDatosPieza(ubicacion, piezaBuscada, atributo, valor);
        }
    }

    const guardarDatosCaraCheck = async (ubicacion, e) => {
        if (e && e.target && e.target.attributes) {
            const piezaBuscada = parseInt(e.target.attributes['data-pieza'].value);
            const caraBuscada = parseInt(e.target.attributes['data-cara'].value);
            const atributo = e.target.attributes['data-atributo'].value;
            const valor = e.target.checked;

            guardarDatosCara(ubicacion, piezaBuscada, caraBuscada, atributo, valor);
        }
    }

    const guardarDatosCaraTexto = async (ubicacion, e) => {
        if (e && e.target && e.target.attributes) {
            const piezaBuscada = parseInt(e.target.attributes['data-pieza'].value);
            const caraBuscada = parseInt(e.target.attributes['data-cara'].value);
            const atributo = e.target.attributes['data-atributo'].value;
            const valor = e.target.value;

            guardarDatosCara(ubicacion, piezaBuscada, caraBuscada, atributo, valor);
        }
    }

    const guardarDatosCaraNumero = async (ubicacion, e) => {
        if (e && e.target && e.target.attributes) {
            const piezaBuscada = parseInt(e.target.attributes['data-pieza'].value);
            const caraBuscada = parseInt(e.target.attributes['data-cara'].value);
            const atributo = e.target.attributes['data-atributo'].value;
            const valor = parseInt(e.target.value);
            guardarDatosCara(ubicacion, piezaBuscada, caraBuscada, atributo, valor);
        }
    }

    const guardarDatosPieza = (ubicacion, piezaBuscada, atributo, valor) => {
        const piezas = ubicacion === 'superior' ? piezasSuperior : piezasInferior;
        const indexPieza = piezas && piezas.findIndex(i => i.idPieza === piezaBuscada);

        if (indexPieza > -1) {
            const resultadoPiezaActualizada = piezas.map((i, index) => index === indexPieza ?
                {
                    ...i,
                    [atributo]: valor
                }
                : i
            );
            guardarPiezas(ubicacion, resultadoPiezaActualizada);
        } else {
            const resultadoPiezaNueva = [
                ...piezas,
                {
                    ...piezaVacia,
                    "idPieza": piezaBuscada,
                    [atributo]: valor
                }
            ];
            guardarPiezas(ubicacion, resultadoPiezaNueva);
        }
    }

    const guardarDatosFurca = (ubicacion, piezaBuscada, caraBuscada, atributo) => {
        const piezas = ubicacion === 'superior' ? piezasSuperior : piezasInferior;
        const indexPieza = piezas && piezas.findIndex(i => i.idPieza === piezaBuscada);
        const indexPiezaCara = piezas[indexPieza] && piezas[indexPieza].caras ? piezas[indexPieza].caras.findIndex(i => i.cara && i.cara.idCara === caraBuscada) : -1;

        if (indexPieza > -1 && indexPiezaCara > -1) {
            const resultadoPiezaCaraActualizada = piezas.map((i, index) => index === indexPieza ?
                {
                    ...i,
                    "caras": i.caras.map((e, ind) => {
                        if (ind === indexPiezaCara) {
                            const valorNuevo = e[atributo] === 2 ? 0 : e[atributo] + 1;
                            return { ...e, [atributo]: valorNuevo }
                        }
                        return e;
                    })
                }
                : i
            );
            guardarPiezas(ubicacion, resultadoPiezaCaraActualizada);
        } else {
            if (indexPieza > -1) {
                const resultadoPiezaActualizada = piezas.map((i, index) => index === indexPieza ?
                    {
                        ...i,
                        "caras": [
                            ...i.caras,
                            {
                                ...piezaVacia.caras[0],
                                "idCara": caraBuscada,
                                "cara": { "idCara": caraBuscada },
                                [atributo]: 0
                            }
                        ]
                    }
                    : i
                );
                guardarPiezas(ubicacion, resultadoPiezaActualizada);
            } else {
                const resultadoPiezaNueva = [
                    ...piezas,
                    {
                        ...piezaVacia,
                        "idPieza": piezaBuscada,
                        "caras": piezaVacia.caras.map((e, index) => index === caraBuscada ?
                            { ...e, [atributo]: 0 }
                            : e)
                    }
                ];
                guardarPiezas(ubicacion, resultadoPiezaNueva);
            }
        }
    }

    const guardarDatosCara = (ubicacion, piezaBuscada, caraBuscada, atributo, valor) => {
        const piezas = ubicacion === 'superior' ? piezasSuperior : piezasInferior;
        const indexPieza = piezas && piezas.findIndex(i => i.idPieza === piezaBuscada);
        const indexPiezaCara = piezas[indexPieza] && piezas[indexPieza].caras ? piezas[indexPieza].caras.findIndex(i => i.cara && i.cara.idCara === caraBuscada) : -1;
        if (indexPieza > -1 && indexPiezaCara > -1) {
            const resultadoPiezaCaraActualizada = piezas.map((i, index) => index === indexPieza ?
                {
                    ...i,
                    "caras": i.caras.map((e, ind) => ind === indexPiezaCara ?
                        { ...e, [atributo]: valor }
                        : e)
                }
                : i
            );
            guardarPiezas(ubicacion, resultadoPiezaCaraActualizada);
        } else {
            if (indexPieza > -1) {
                const resultadoPiezaActualizada = piezas.map((i, index) => index === indexPieza ?
                    {
                        ...i,
                        "caras": [
                            ...i.caras,
                            {
                                ...piezaVacia.caras[0],
                                "idCara": caraBuscada,
                                "cara": { "idCara": caraBuscada },
                                [atributo]: valor
                            }
                        ]
                    }
                    : i
                );
                guardarPiezas(ubicacion, resultadoPiezaActualizada);
            } else {
                const resultadoPiezaNueva = [
                    ...piezas,
                    {
                        ...piezaVacia,
                        "idPieza": piezaBuscada,
                        "caras": piezaVacia.caras.map((e, index) => index === caraBuscada ?
                            { ...e, [atributo]: valor }
                            : e)
                    }
                ];
                guardarPiezas(ubicacion, resultadoPiezaNueva);
            }
        }
    }

    const bloquearPieza = (ubicacion, piezaBuscada, atributo, valor) => {
        const piezas = ubicacion === 'superior' ? piezasSuperior : piezasInferior;
        const indexPieza = piezas && piezas.findIndex(i => i.idPieza === piezaBuscada);
        if (indexPieza > -1) {
            const resultadoPiezaActualizada = piezas.map((i, index) => index === indexPieza ?
                {
                    ...piezaVacia,
                    idPieza: piezaBuscada,
                    [atributo]: valor
                }
                : i
            );
            guardarPiezas(ubicacion, resultadoPiezaActualizada);
        } else {
            const resultadoPiezaNueva = [
                ...piezas,
                {
                    ...piezaVacia,
                    "idPieza": piezaBuscada,
                    [atributo]: valor
                }]
            guardarPiezas(ubicacion, resultadoPiezaNueva);
        }
    }

    const obtenerImagenFurcacion = (valor) => {
        switch (valor) {
            case 1:
                return "circulo_medio";
                break;
            case 2:
                return "circulo_lleno";
                break;
            default:
                return "circulo_vacio";
                break;
        }
    }

    const guardarDatosReservaAutocomplete = (event, value) => {
        if (value !== "" && value.id && event.target.id !== "")
            setPeriodontograma({
                ...periodontograma,
                [event.target.id.split("-")[0]]: value.id
            });
    };

    const guardarDatosReserva = (e) => {
        setPeriodontograma({
            ...periodontograma,
            [e.target.name]: e.target.value
        });
    };

    const obtenerProfesionales = async () => {
        const r = await ControllerUsuarios.peticionGetUsuariosPorTipo(2);
        if (r.resultado) {
            setProfesionales(r.data ? r.data.map((i) => ({ id: i.id, label: `${i.nombre1} ${i.nombre2} ${i.apellido1} ${i.apellido2} (Doc: ${i.cedula})` })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    const obtenerConsultorios = async () => {
        const r = await ControllerConsultorios.peticionGetConsultorios();
        if (r.resultado) {
            setConsultorios(r.data && r.data.registros ? r.data.registros.map((i) => ({ id: i.idConsultorio, label: `${i.nombre}` })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    useEffect(() => {
        obtenerProfesionales();
        obtenerConsultorios();
    }, []);

    useEffect(() => {
        if (datos) {
            setCargaProfesional(datos.medico && profesionales ? profesionales.find((i) => i.id == datos.medico.idMedico) : {})
            setCargaConsultorio(datos.consultorio && consultorios ? consultorios.find((i) => i.label == datos.consultorio.nombre) : {})
            setPeriodontograma(datos);
            setPiezasSuperior(datos.piezas.filter((p,index) => index < 16))
            setPiezasInferior(datos.piezas.filter((p,index) => index > 15))
        } else {
            setPiezasSuperior(generarEstructura('superior'));
            setPiezasInferior(generarEstructura('inferior'));
        }
    }, [datos]);


    useEffect(() => {
        if (piezasSuperior, piezasInferior) {
            calcularResumen();
        }
    }, [piezasSuperior, piezasInferior]);


    return (
        <>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <FormGroup>
                        <FormLabel>Profesional*</FormLabel>
                        <FormControl>
                            <Autocomplete
                                id="idMedico"
                                options={profesionales}
                                getOptionLabel={(i) => i.label || ''}
                                freeSolo
                                disabled={periodontograma && periodontograma.medico ? true : false}
                                onChange={guardarDatosReservaAutocomplete}
                                key={datos ? datos.idMedico : "datos.idMedico"}
                                value={cargaProfesional || null}
                                renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormGroup>
                        <FormLabel>Consultorio*</FormLabel>
                        <FormControl>
                            <Autocomplete
                                id="idConsultorio"
                                options={consultorios}
                                getOptionLabel={(i) => i.label || ''}
                                freeSolo
                                disabled={periodontograma && periodontograma.consultorio ? true : false}
                                onChange={guardarDatosReservaAutocomplete}
                                key={datos ? datos.idConsultorio : "datos.idConsultorio"}
                                value={cargaConsultorio || null}
                                renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FormGroup>
                        <FormLabel>Notas</FormLabel>
                        <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                        >
                            <Box
                                paddingLeft="0.75rem"
                                paddingRight="0.75rem"
                                component={FilledInput}
                                autoComplete="off"
                                type="text"
                                name="detalle"
                                disabled={datos && datos.detalle ? true : false}
                                defaultValue={datos ? datos.detalle : ""}
                                key={datos ? datos.detalle : "datos.detalle"}
                                multiline rows={3}
                                onChange={(e) => guardarDatosReserva(e)}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>
            <PeriodontogramaTablaSuperior encabezado={encabezadoSuperior} etiquetas={etiquetasEncabezadoSuperior} piezas={piezasSuperior}
                guardarDatosCaraCheck={guardarDatosCaraCheck} guardarDatosFurca={guardarDatosFurca}
                guardarDatosPiezaNumero={guardarDatosPiezaNumero} guardarDatosPiezaBloquear={guardarDatosPiezaBloquear}
                guardarDatosCaraNumero={guardarDatosCaraNumero} guardarDatosPiezaTexto={guardarDatosPiezaTexto}
                obtenerGraficoImagen={obtenerGraficoImagen} obtenerGrafico={obtenerGrafico} obtenerImagenFurcacion={obtenerImagenFurcacion} />
            <PeriodontogramaTablaInferior encabezado={encabezadoInferior} etiquetas={etiquetasEncabezadoInferior} piezas={piezasInferior} guardarDatosCaraCheck={guardarDatosCaraCheck} guardarDatosFurca={guardarDatosFurca}
                guardarDatosPiezaNumero={guardarDatosPiezaNumero} guardarDatosPiezaBloquear={guardarDatosPiezaBloquear}
                guardarDatosCaraNumero={guardarDatosCaraNumero} guardarDatosPiezaTexto={guardarDatosPiezaTexto}
                obtenerGraficoImagen={obtenerGraficoImagen} obtenerGrafico={obtenerGrafico} obtenerImagenFurcacion={obtenerImagenFurcacion} />
            <Container component={Box} marginTop={"1rem"} >
                <Typography variant='h3'><AssignmentOutlined /> Resultado:</Typography>
                <Typography variant='h4'>Sangrado al sondaje: {porcentajeSangrado} %</Typography>
                <Typography variant='h4'>Porcentaje de placa: {porcentajePlaca} %</Typography>
                <Typography variant='h4'>Porcentaje de supuración: {porcentajeSupuracion} %</Typography>
                <Typography variant='h4'>Medida de prof. de sondaje: {medidaProfundidad} mm</Typography>
                <Typography variant='h4'>Medida de nivel de inserción: {medidaInsercion} mm</Typography>
            </Container>
            <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="end"
            >
                {!datos ?
                    <Grid item xs="auto">
                        <Box
                            justifyContent="flex-end"
                            display="flex"
                            flexWrap="wrap"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => { crear() }}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Grid>
                    : ""}
            </Grid>
        </>
    )
}

