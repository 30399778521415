import { queryGet, queryPut, queryPost } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Notificaciones/";
const cantidadPagina= 10;

export function peticionGetNotificacionesPendientes(dias) {
  return queryGet(url  + "pendientes?dias="+dias);
}

export function peticionPutNotificacion(elemento) {
  return queryPut(url  + "procesar/", elemento);
}

export function peticionPostEnviarWhatsapp(elemento) {
  return queryPost(url  + "whatsapp/enviar/", elemento);
}

export function peticionPostEnviarSMS(elemento) {
  return queryPost(url  + "sms/enviar/", elemento);
}