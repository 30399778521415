import { queryGet, queryPost, queryDelete } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Sistema/diagnostico/";

export function peticionGetDiagnosticos() {
  return queryGet(url);
}

export function peticionPostDiagnostico(elemento) {
  return queryPost(url, elemento);
}

export function peticionDeleteDiagnostico(id) {
  return queryDelete(url + id);
}

export function peticionPostBuscar(elemento) {
  return queryPost(url + "buscar/", elemento);
}
