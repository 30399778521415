import React from "react";
import { Visibility } from "@material-ui/icons";
import { IconButton, Link } from "@material-ui/core";

const Ver = props => {
    const url = props.url;
    return (
        <Link href={url} target="_blank">
            <IconButton>
                <Visibility />
            </IconButton>
        </Link>
    );
};

export default Ver;