import React, { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import * as ControllerUsuarios from 'controllers/Usuarios';
import { useAlert } from "react-alert";
import MenuTabla from "components/Tabla/MenuTabla";
import Historial from "components/Tabla/Historial";
import { obtenerFecha } from 'functions/fechas';
import { formatoFecha } from 'functions/fechas';
import AlertaDialogo from "components/Popups/AlertaDialogo";
import { useSelector } from "react-redux";
import ReiniciarClave from "views/usuario/popup/ReiniciarClave";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";
import { convertirTimezone } from "functions/fechas";


const useStyles = makeStyles(componentStyles);

const Usuarios = () => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [profesionales, setProfesionales] = React.useState();
  const [administrativos, setAdministrativos] = React.useState();
  const [modalReiniciar, setModalReiniciar] = React.useState(false);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [idReiniciar, setIdReiniciar] = React.useState();
  const [idEliminar, setIdEliminar] = React.useState();
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.usuario : []);

  const peticionEliminar = async (id) => {
    cerrarModalEliminar();
    if (id) {
      const r = await ControllerUsuarios.peticionDeleteUsuario(id);
      if (r.resultado) {
        switch (r.tipo) {
          case "profesional":
            setProfesionales(profesionales.filter(i => i.idUsuario !== id));
            break;
          case "administrativo":
            setAdministrativos(administrativos.filter(i => i.idUsuario !== id));
            break;
          default:
            break;
        }
        obtenerUsuarios();
        alert.success(r.mensaje);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const obtenerUsuarios = async () => {
    const r = await ControllerUsuarios.peticionGetUsuarios();
    if (r.resultado) {
      const filtroProfesionales=  r.data.registros.filter(i => i.tipoUsuario.idTipo === 2);
      const filtroAdministrativos = r.data.registros.filter(i => i.tipoUsuario.idTipo !== 2);
      setProfesionales(filtroProfesionales);
      setAdministrativos(filtroAdministrativos);
    } else {
      alert.error(r.mensaje);
    }
  }

  const eliminarRegistro = (id) => {
    setIdEliminar(id);
    setModalEliminar(true);
  }

  const reiniciarClave = (id) => {
    setIdReiniciar(id);
    setModalReiniciar(true);
  }

  const cerrarModalEliminar = () => {
    setIdEliminar();
    setModalEliminar(false);
  }

  const cerrarModalReiniciar = () => {
    setIdEliminar();
    setModalReiniciar(false);
  }

  const itemsMenu = [{ nombre: "Ver", "href": "/app/equipo/usuario/","permiso": permisos && permisos.obtener },
  { nombre: "Modificar", "href": "/app/equipo/datos/personales/","permiso": permisos && permisos.modificar },
  { nombre: "Reiniciar contraseña", "funcion": reiniciarClave,"permiso": permisos && permisos.reiniciar },
  { nombre: "Eliminar", "funcion": eliminarRegistro,"permiso": permisos && permisos.eliminar }];

  useLayoutEffect(() => {
    obtenerUsuarios();
  }, []);

  return (
    <>
      <Header />
      
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    Equipo médico
                  </Box>
                </Grid>
                {permisos && permisos.crear &&
                  <Grid item xs="auto">
                  <Box
                    justifyContent="flex-end"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      href="/app/equipo/datos/personales/"
                    >
                      Nuevo
                    </Button>
                  </Box>
                </Grid>}
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Nombre
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Documento
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Teléfono
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    E-mail
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Vto. Carnet
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!profesionales ? <TablaCarga espacio={7} /> :
                  profesionales.length < 1 ? <SinRegistros espacio={7} /> :
                    profesionales.map(profesional => {
                    return (
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          align="left">
                          <MenuTabla id={profesional.id} items={itemsMenu} />
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {profesional.nombre1 + " " + profesional.apellido1}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {profesional.cedula}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {`${profesional.celular} ${profesional.telefono ? ` / ${profesional.telefono}`:''}`}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {profesional.mail}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box
                            component="span"
                            fontSize=".875rem"
                            color={profesional.vencimientosalud < obtenerFecha("date") ? theme.palette.warning.main : theme.palette.success.main}
                            display="flex"
                            alignItems="center"
                          >
                            {formatoFecha(profesional.vencimientoCarnetSalud,"DD/MM/YYYY HH:MM")}
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Historial creacion={{ fecha: convertirTimezone(profesional.fechaRegistro,'GMT-3') }} modificacion={{ fecha: convertirTimezone(profesional.fechaUltimaModificacion,'GMT-3') }} />
                        </TableCell>
                      </TableRow>
                    )
                  })},
              </TableBody>
            </Box>
          </TableContainer>
        </Card>

        <Box
          component={Card}
          marginTop="3rem"
          classes={{ root: classes.cardRoot + " " + classes.cardRootDark }}
        >
          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    color="text.primary"
                    marginBottom="0!important"
                  >
                    Administrativos
                  </Box>
                </Grid>
                {permisos && permisos.crear &&
                <Grid item xs="auto">
                  <Box
                    justifyContent="flex-end"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      href="/app/equipo/datos/personales/"
                    >
                      Nuevo
                    </Button>
                  </Box>
                </Grid>}
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot + " " + classes.cardRootDark }}
          ></CardHeader>
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Nombre
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Categoría
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Documento
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Teléfono
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    E-mail
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Vto. Carnet
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!administrativos ? <TablaCarga espacio={8} /> :
                  administrativos.length < 1 ? <SinRegistros espacio={8} /> :
                    administrativos.map(administrativo => {
                    return (
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          align="right">

                          <MenuTabla id={administrativo.id} items={itemsMenu} />
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {administrativo.nombre1 + " " + administrativo.apellido1}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {administrativo.tipoUsuario.nombre}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {administrativo.cedula}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {administrativo.celular + ' / ' + administrativo.telefono}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {administrativo.mail}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box
                            component="span"
                            fontSize=".875rem"
                            color={administrativo.vencimientosalud < obtenerFecha("date") ? theme.palette.warning.main : theme.palette.success.main}
                            display="flex"
                            alignItems="center"
                          >
                            {formatoFecha(administrativo.vencimientoCarnetSalud,"DD/MM/YYYY HH:MM")}
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Historial creacion={{ fecha: convertirTimezone(administrativo.fechaRegistro,'GMT-3') }} modificacion={{ fecha: convertirTimezone(administrativo.fechaUltimaModificacion,'GMT-3') }} />
                        </TableCell>
                      </TableRow>
                    )
                  })},
              </TableBody>
            </Box>
          </TableContainer>
        </Box>
      </Container>
      {modalReiniciar && <ReiniciarClave id={idReiniciar} mostrar={modalReiniciar} cerrar={cerrarModalReiniciar} />}
      {modalEliminar && <AlertaDialogo titulo={"Seguro desea eliminar el usuario seleccionado?"} id={idEliminar} funcionConfirmar={peticionEliminar} abierto={modalEliminar} funcionCerrar={cerrarModalEliminar} />}
    </>
  );
};

export default Usuarios;
