const pasos = (id) => {
  if (id) {
    return [{ etiqueta: "Personales", url: "/app/pacientes/datos/personales/" + id },
    { etiqueta: "Clínicos", url: "/app/pacientes/datos/clinicos/" + id },
    { etiqueta: "Contactos", url: "/app/pacientes/datos/contactos/" + id },
    { etiqueta: "Avatar", url: "/app/pacientes/datos/avatar/" + id }];
  }
  return [{ etiqueta: "Personales", url: "" },
  { etiqueta: "Clínicos", url: "" },
  { etiqueta: "Contactos", url: "" },
  { etiqueta: "Avatar", url: "" }]
};

export { pasos };
