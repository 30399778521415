import { makeStyles } from "@material-ui/core";
import Perfil from "assets/theme/views/admin/profile.js";
import Tabla from "assets/theme/views/admin/tables.js";

const estiloPerfil = makeStyles(Perfil);
const estiloTabla = makeStyles(Tabla);

const previewFoto = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 50,      
    },
    preview: {
      marginTop: 50,
      display: "flex",
      flexDirection: "column",
      border:1
    },
    image: { maxWidth: "80%", maxHeight: 320 },
    delete: {
      cursor: "pointer",
      padding: 5,
      background: "red",
      color: "white",
      border: "none",
    },
  };

export {estiloPerfil, estiloTabla, previewFoto};