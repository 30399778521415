import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { colors } from "estilos/graficas";
import { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import { Line } from "react-chartjs-2";


const useStyles = makeStyles(componentStyles);

//Opciones
const configuracion = {
    options: {
        legend: {
            display: true,
            position: 'bottom',
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: colors.gray[900],
                        zeroLineColor: colors.gray[900],
                    },

                },
            ],
        },

    },
};

export default function GraficaLineas({ titulo, subtitulo, tituloGrafica1, tituloGrafica2, datos }) {
    const classes = useStyles();
    const theme = useTheme();
    const [selectorActivo, setSelectorActivo] = useState(1);
    const [graficaSeleccionada, setGraficaSeleccionada] = useState("grafica1");

    const selecccionarGrafica = (id) => {
        setSelectorActivo(id);
        setGraficaSeleccionada("grafica" + id);
    };

    return (
        <Card classes={{ root: classes.cardRoot + " " + classes.cardRootBgGradient, }}>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item xs="auto">
                            <Box
                                component={Typography}
                                variant="h6"
                                letterSpacing=".0625rem"
                                marginBottom=".25rem!important"
                                className={classes.textUppercase}
                            >
                                <Box component="span" color={theme.palette.gray[400]}>
                                    {subtitulo}
                                </Box>
                            </Box>
                            <Box
                                component={Typography}
                                variant="h2"
                                marginBottom="0!important"
                            >
                                <Box component="span" color={theme.palette.white.main}>
                                    {titulo}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs="auto">
                            <Box
                                justifyContent="flex-end"
                                display="flex"
                                flexWrap="wrap"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Box}
                                    marginRight="1rem!important"
                                    onClick={() => selecccionarGrafica(1)}
                                    classes={{
                                        root:
                                            selectorActivo === 1
                                                ? ""
                                                : classes.buttonRootUnselected,
                                    }}
                                >
                                    {tituloGrafica1}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => selecccionarGrafica(2)}
                                    classes={{
                                        root:
                                            selectorActivo === 2
                                                ? ""
                                                : classes.buttonRootUnselected,
                                    }}
                                >
                                    {tituloGrafica2}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>
            <CardContent>
                <Box position="relative" height="350px">
                    <Line
                        data={datos[graficaSeleccionada]}
                        options={configuracion.options}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};