import React, { useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import UserHeader from "components/Headers/UserHeader.js";
import { estiloPerfil } from "estilos/clases.js";
import { useSelector } from "react-redux";
import { useDispatch } from "../../../redux/store";
import * as ControllerPacientes from 'controllers/Pacientes';
import { useAlert } from "react-alert";
import Foto from "components/Formularios/Foto";
import Pasos from "components/Formularios/Pasos";
import { pasos } from "functions/pacientes";
import { evaluarForm } from "functions/formulario";
import { formatoFecha } from 'functions/fechas';
import { ObtenerPaises } from "functions/datosSistema";
import { ObtenerDocumentos } from "functions/datosSistema";
import { b64aBlob } from "functions/formulario";
import { evaluarCorreo } from "functions/formulario";
import { evaluarNacimiento } from "functions/formulario";
import { validarCI } from "functions/formulario";
import { evaluarTelefono } from "functions/formulario";
import { evaluarCelular } from "functions/formulario";

function Gestion(props) {
  let { id } = useParams();
  const theme = useTheme();
  const estilo = estiloPerfil();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [itemsPasos, setItemsPasos] = React.useState([]);
  const itemActivo = 0;
  const paises = useSelector((state) => state.paises);
  const documentos = useSelector((state) => state.tiposDocumentos);
  const [paciente, setPaciente] = React.useState({});
  const [avatar, setAvatar] = React.useState();
  let [pacienteActualizado, setPacienteActualizado] = React.useState({
    sexo: 'M', idPais: 214, idTipoDocumento: 1,
    idMutualista: 1,
    idEmergencia: 1,
    idInhabilitacion: 0,
  });
  const camposRequeridos = [{ id: 'nombre1', nombre: 'Primer nombre' }, { id: 'apellido1', nombre: 'Primer apellido' },
  { id: 'documento', nombre: 'Documento' }, { id: 'idTipoDocumento', nombre: 'Tipo de documento' },
  { id: 'idPais', nombre: 'País' }, { id: 'celular', nombre: 'Celular' },
  { id: 'departamento', nombre: 'Departamento' },{ id: 'direccion', nombre: 'Dirección' },
  { id: 'fechaNacimiento', nombre: 'Fecha de nacimiento' }, { id: 'mail', nombre: 'Correo' },
  { id: 'sexo', nombre: 'Sexo' },];

  const peticion = async (tipo) => {
    const validarForm = evaluarForm(pacienteActualizado, camposRequeridos);
    if (!validarForm.resultado) {
      alert.error(validarForm.mensaje);
      return;
    }

    if (pacienteActualizado.idTipoDocumento === 1 && pacienteActualizado.documento) {
      const validarCedula = validarCI(pacienteActualizado.documento.toString());
      if (!validarCedula.resultado) {
        alert.error(validarCedula.mensaje);
        return;
      }
    }

    if (pacienteActualizado.hasOwnProperty('mail') && pacienteActualizado.mail) {
      const validarCorreo = evaluarCorreo(pacienteActualizado.mail);
      if (!validarCorreo.resultado) {
        alert.error(validarCorreo.mensaje);
        return;
      }
    }

    if (pacienteActualizado.hasOwnProperty('fechaNacimiento') && pacienteActualizado.fechaNacimiento) {
      const validarNacimiento = evaluarNacimiento(pacienteActualizado.fechaNacimiento);
      if (!validarNacimiento.resultado) {
        alert.error(validarNacimiento.mensaje);
        return;
      }
    }

    if (pacienteActualizado.hasOwnProperty('telefono') && pacienteActualizado.telefono) {
      const validarTelefono = evaluarTelefono(pacienteActualizado.telefono);
      if (!validarTelefono.resultado) {
        alert.error(validarTelefono.mensaje);
        return;
      }
    }

    if (pacienteActualizado.hasOwnProperty('celular') && pacienteActualizado.celular) {
      const validarCelular = evaluarCelular(pacienteActualizado.celular);
      if (!validarCelular.resultado) {
        alert.error(validarCelular.mensaje);
        return;
      }
    }

    let r = { resultado: false };
    switch (tipo) {
      case "post":
        r = await ControllerPacientes.peticionPostPaciente(pacienteActualizado);
        break;
      case "put":
        r = await ControllerPacientes.peticionPutPaciente(id, pacienteActualizado);
        break;
      default:
        break;
    }
    if (r.resultado) {
      const idGenerado = r.data && r.data.idPaciente ? r.data.idPaciente : null;;
      history.push(`/app/pacientes/datos/clinicos/${idGenerado !== null ? idGenerado : id}`);
      alert.success(r.mensaje)
    } else {
      alert.error(r.mensaje);
    }
  }

  const guardarDatos = async e => {
    await setPacienteActualizado({
      ...pacienteActualizado,
      [e.target.name]: e.target.value
    });
  };

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
        setPacienteActualizado({
          ...r.data,
          idPais: r.data && r.data.pais ? r.data.pais.idPais : 214,
          idTipoDocumento: r.data && r.data.tipoDocumento ? r.data.tipoDocumento.idTipoDocumento : 1,
        })
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerAvatar = async () => {
    const r = await ControllerPacientes.peticionGetPacienteAvatar(id);
    if (r.resultado) {
      if (r.data) {
        const blob = b64aBlob(r.data.base64 ? r.data.base64 : "");
        setAvatar(blob);
      }
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerPaises = async () => {
    if (paises && paises.length === 0) {
      const r = await ObtenerPaises();
      dispatch({ "type": "CARGAR_PAISES", "data": r });
    }
  }

  const obtenerDocumentos = async () => {
    if (documentos && documentos.length === 0) {
      const r = await ObtenerDocumentos();
      dispatch({ "type": "CARGAR_DOCUMENTOS", "data": r });
    }
  }

  useLayoutEffect(() => {
    obtenerPaises();
    obtenerDocumentos();
    setItemsPasos(pasos(id));
    obtenerPaciente();
  }, []);

  useLayoutEffect(() => {
    if (paciente && paciente.idPaciente) {
      obtenerAvatar();
    }
  }, [paciente])

  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: estilo.containerRoot }}
      >
        <Grid container>
          <Foto avatar={avatar} />
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: estilo.gridItemRoot + " " + estilo.order2 }}
          >
            <Card
              classes={{
                root: estilo.cardRoot + " " + estilo.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {id && paciente && paciente.nombre1 ?
                          `Datos de ${paciente.nombre1} ${paciente.apellido1} (${paciente.documento})`
                          : "Datos de paciente nuevo"}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
              <Pasos items={itemsPasos} activo={itemActivo} />
              <CardContent>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Datos personales
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Primer Nombre*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="nombre1"
                            key={paciente ? paciente.nombre1 : 'paciente.nombre1'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={paciente ? paciente.nombre1 : ''}
                            inputProps={{maxLength: 20}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Segundo Nombre</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={paciente ? paciente.nombre2 : ''}
                            name="nombre2"
                            key={paciente ? paciente.nombre2 : 'paciente.nombre2'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 20}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Primer Apellido*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={paciente ? paciente.apellido1 : ''}
                            name="apellido1"
                            key={paciente ? paciente.apellido1 : 'paciente.apellido1'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 20}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Segundo Apellido</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={paciente ? paciente.apellido2 : ''}
                            name="apellido2"
                            key={paciente ? paciente.apellido2 : 'paciente.apellido2'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 20}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Tipo de documento*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={(paciente && paciente.tipoDocumento && paciente.tipoDocumento.idTipoDocumento) || 1} name="idTipoDocumento" key={paciente && paciente.tipoDocumento ? paciente.tipoDocumento.idTipoDocumento : 'paciente.idTipoDocumento'}
                            onChange={(e) => guardarDatos(e)}>
                            {documentos && documentos.map(p => {
                              return (
                                <MenuItem value={p.idTipoDocumento}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Documento*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={paciente ? paciente.documento : ''}
                            name="documento"
                            key={paciente ? paciente.documento : 'paciente.documento'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 20}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Sexo*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={(paciente && paciente.sexo) || "M"} name="sexo" key={paciente ? paciente.sexo : 'paciente.sexo'}
                            onChange={(e) => guardarDatos(e)}>
                            <MenuItem value="M">Masculino</MenuItem>
                            <MenuItem value="F">Femenino</MenuItem>
                            <MenuItem value="I">Indefinido</MenuItem>
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Nacimiento*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="date"
                            defaultValue={paciente && paciente.fechaNacimiento ? formatoFecha(paciente.fechaNacimiento, "YYYY-MM-DD") : ''}
                            name="fechaNacimiento"
                            key={paciente ? paciente.fechaNacimiento : 'paciente.fechaNacimiento'}
                            onChange={(e) => guardarDatos(e)}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Información de contacto
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Teléfono</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={paciente ? paciente.telefono : ''}
                            name="telefono"
                            key={paciente ? paciente.telefono : 'paciente.telefono'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 15}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Celular*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type={'text'}
                            defaultValue={paciente ? paciente.celular : ''}
                            name="celular"
                            key={paciente ? paciente.celular : 'paciente.celular'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 15}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Correo electrónico*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="email"
                            defaultValue={paciente ? paciente.mail : ''}
                            name="mail"
                            key={paciente ? paciente.mail : 'paciente.mail'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 50}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Dirección*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={paciente ? paciente.direccion : ''}
                            name="direccion"
                            key={paciente ? paciente.direccion : 'paciente.direccion'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 50}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Departamento*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            defaultValue={paciente ? paciente.departamento : ''}
                            name="departamento"
                            key={paciente ? paciente.departamento : 'paciente.departamento'}
                            onChange={(e) => guardarDatos(e)}
                            inputProps={{maxLength: 50}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>País*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={(paciente && paciente.pais && paciente.pais.idPais) || 214} name="idPais" key={paciente && paciente.pais ? paciente.pais.idPais : 'paciente.idPais'}
                            onChange={(e) => guardarDatos(e)}>
                            {paises && paises.map(p => {
                              return (
                                <MenuItem value={p.idPais}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/pacientes/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => { id ? peticion('put') : peticion('post') }}
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid >
      </Container >
    </>
  );
}

export default Gestion;
