const themeColors = require("assets/theme/colors.js").default;

export const mode = "light"; //(themeMode) ? themeMode : 'light';

export const fonts = {
    base: "Open Sans",
};

export const colors = {
    gray: themeColors.gray,
    theme: {
        default: themeColors.dark.main,
        primary: themeColors.primary.main,
        secondary: "#f4f5f7",
        info: themeColors.info.main,
        success: themeColors.success.main,
        danger: themeColors.error.main,
        warning: themeColors.warning.main,
    },
    black: themeColors.black.light,
    white: themeColors.white.main,
    transparent: themeColors.transparent.main,
};

