import * as React from 'react';
import { CardHeader, Step, StepButton, Stepper } from '@material-ui/core';
import { estiloPerfil } from 'estilos/clases';


export default function Pasos({items, activo}) {
  const estilo = estiloPerfil();
  return (
    <CardHeader
    style={{"overflowX":"auto"}}
    subheader={
      <Stepper nonLinear activeStep={activo}>
        {items.map((i) => (
          <Step key={i.etiqueta}>
            <StepButton color="inherit" href={i.url}>
              {i.etiqueta}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    }
    classes={{ root: estilo.cardHeaderRoot }}
    ></CardHeader>
  );
}