
function calcularPorcentajePeriodontograma(caras, piezasExploradas) {
  return Math.round((caras / (piezasExploradas * 6) * 100));
}

function calcularPiezasDisponibles(piezas) {
  return piezas.reduce((suma, p) => { return suma + (p.ausencia ? 0 : 1); }, 0);
}

function calcularProfundidad(profundidad, piezasExploradas) {
  let total = profundidad / (piezasExploradas * 3);
  return Math.round(total * Math.pow(10, 2)) / Math.pow(10, 2);
}

function calcularInsercion(profundidad, margen, piezasExploradas) {
  let total = (profundidad + margen) / (piezasExploradas * 3);
  return Math.round(total * Math.pow(10, 2)) / Math.pow(10, 2);
}

export { calcularPorcentajePeriodontograma, calcularPiezasDisponibles, calcularProfundidad, calcularInsercion };