import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { esJSON } from 'functions/formulario';


export default function Acordion({ titulo, texto, esJson}) {

    return (
        <Box marginTop={"1rem"}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id={titulo}
                >
                    <Typography variant="h4">{titulo}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {esJSON(texto) ? 
                        <div><pre>{JSON.stringify(JSON.parse(texto),null,2)}</pre></div>
                        : texto }
                    
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}