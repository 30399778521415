import { queryGet, queryPost, queryDelete, queryPut } from "functions/controllers";
const urlPaciente = process.env.REACT_APP_API_URL + "Paciente";
const urlFicha = "reservas/concretadas";


export async function peticionGetFichas(idPaciente) {
  return queryGet(obtenerUrl(idPaciente));
}

export async function peticionPostFicha(idPaciente, elementoNuevo) {
  return queryPost(obtenerUrl(idPaciente), elementoNuevo);
}

export async function peticionPutCuentas(idPaciente, elementoModificado) {
  return queryPut(obtenerUrl(idPaciente), elementoModificado);
}

export async function peticionDeleteFicha(idPaciente,idElemento) {
  return queryDelete(obtenerUrl(idPaciente) + idElemento);
}

function obtenerUrl(idPaciente) {
  return `${urlPaciente}/${idPaciente}/${urlFicha}/`;
}