import { obtenerFecha } from 'functions/fechas';
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //AUTENTICACION
        case "LOGIN":
            return { ...state, sesion: { ...action.sesion, login: obtenerFecha("date") } };
        case "LOGOUT":
            return initialState;
        case "CAMBIO_CLAVE":
            return { ...state, sesion: { ...state.sesion, forzarCambioDeClave: false } };

        //USUARIO
        case "CARGAR_USUARIO":
            return { ...state, usuario: action.usuario };

        //SISTEMA
        case "CARGAR_PAISES":
            return { ...state, paises: action.data };
        case "CARGAR_DOCUMENTOS":
            return { ...state, tiposDocumentos: action.data };
        case "CARGAR_PIEZAS":
            return { ...state, piezas: action.data };
        case "CARGAR_CARAS":
            return { ...state, caras: action.data };
        case "CARGAR_ROLES":
            return { ...state, roles: action.data };
        case "CARGAR_TIPOS_USUARIO":
            return { ...state, tiposUsuario: action.data };
        case "CARGAR_TIPOS_DOCUMENTACION":
            return { ...state, tiposDocumentacion: action.data };

        //CARGA
        case "AVISO_CARGANDO":
            return { ...state, carga: action.data };

        default:
            return state;
    }
}

export default reducer;