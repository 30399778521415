import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "assets/theme/theme.js";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import AdminLayout from "layouts/Admin.js";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './redux/store';
import { Provider as AlertProvider } from "react-alert";
import { AlertTemplate, AlertaOpciones } from "components/Popups/Alerta";

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...AlertaOpciones} >
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter >
            <Switch>
              <Route path="/app" render={(props) => <AdminLayout {...props} />} />
              <Redirect from="/" to="/app/inicio" />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  </AlertProvider >,
  document.querySelector("#root")
);
