import React from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Paper } from '@material-ui/core';
import 'estilos/periodontograma.css';
import GraficaLineasPiezaSuperior from 'components/Graficas/LineasPiezaSuperior';
import GraficaLineasPiezaInferior from 'components/Graficas/LineasPiezaInferior';

export function PeriodontogramaTablaInferior({ encabezado, piezas, etiquetas,
    guardarDatosCaraCheck, guardarDatosFurca, guardarDatosPiezaNumero, guardarDatosPiezaBloquear,
    guardarDatosCaraNumero, obtenerGrafico, obtenerGraficoImagen, guardarDatosPiezaTexto, obtenerImagenFurcacion }) {
    const ubicacion = 'inferior';

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell className="celdaTitulo">INFERIOR</TableCell>
                            {etiquetas.map(i =>
                                <TableCell className="celdaPiezas">{i}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableCell className="celda">
                            <TableRow className="fila">Ausencia</TableRow>
                            <TableRow className="fila">Implante</TableRow>
                            <TableRow className="fila">Movilidad</TableRow>
                            <TableRow className="fila">Furcación</TableRow>
                            <TableRow className="fila">Sangrado</TableRow>
                            <TableRow className="fila">Supuración</TableRow>
                            <TableRow className="fila">Placa</TableRow>
                            <TableRow className="fila">Margen Gingival</TableRow>
                            <TableRow className="fila">Profundidad Sondaje</TableRow>
                            <TableRow className="filaLarga">LINGUAL</TableRow>
                            <TableRow className="filaLarga">VESTIBULAR</TableRow>
                            <TableRow className="fila">Margen Gingival</TableRow>
                            <TableRow className="fila">Profundidad Sondaje</TableRow>
                            <TableRow className="fila">Placa</TableRow>
                            <TableRow className="fila">Supuración</TableRow>
                            <TableRow className="fila">Sangrado</TableRow>
                            <TableRow className="fila">Furcación</TableRow>
                            <TableRow className="fila">Nota</TableRow>
                        </TableCell>
                        {piezas && piezas.length > 0 ?
                            piezas.map((p, i) => {
                                const idPieza = p.pieza ? p.pieza.idPieza : p.idPieza;
                                return <>
                                    <TableCell className="celda">
                                        <TableRow >
                                            <input data-pieza={encabezado[i]} data-atributo={'ausencia'} onChange={(e) => guardarDatosPiezaBloquear(ubicacion,e)} type="checkbox" id={`ausencia${idPieza}a`} name={`ausencia${idPieza}`} onClick={(e) => guardarDatosPiezaBloquear(ubicacion,e)} size="small" checked={p.ausencia} />
                                        </TableRow>
                                        <TableRow >
                                            <input data-pieza={idPieza} data-atributo={'implante'} onChange={(e) => guardarDatosPiezaBloquear(ubicacion,e)} type="checkbox" id={`implante${idPieza}a`} name={`implante${idPieza}`} onClick={(e) => guardarDatosPiezaBloquear(ubicacion,e)} size="small" checked={p.implante} />
                                        </TableRow>
                                        <TableRow >
                                            <input data-pieza={idPieza} data-atributo={'idMovilidad'} className={"campoTexto"} onChange={(e) => guardarDatosPiezaNumero(ubicacion,e)} type="number" class={"campoTexto"} id={`idMovilidad${idPieza}a`} value={p.idMovilidad} />
                                        </TableRow>
                                        <TableRow >
                                            {((i > -1 && i < 3) || (i > 12 && i < 16)) ?
                                                <img class="campoFurca" id={`furcacion1${idPieza}a`} onClick={() => guardarDatosFurca(ubicacion,idPieza, 3, 'furcacion1')} alt="icono" src={require(`assets/img/icons/common/${obtenerImagenFurcacion(p.caras[0] && p.caras[0].furcacion1)}.png`).default} />
                                                : <span>-</span>}
                                        </TableRow>
                                        <TableRow >
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'sangrado1'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`sangrado1${idPieza}a`} name={`sangrado1${idPieza}`} checked={p.caras[0] && p.caras[0].sangrado1} /><span class="checkbox checkRojo"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'sangrado2'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`sangrado2${idPieza}a`} name={`sangrado2${idPieza}`} checked={p.caras[0] && p.caras[0].sangrado2} /><span class="checkbox checkRojo"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'sangrado3'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`sangrado3${idPieza}a`} name={`sangrado3${idPieza}`} checked={p.caras[0] && p.caras[0].sangrado3} /><span class="checkbox checkRojo"></span></label>
                                        </TableRow>
                                        <TableRow >
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'supuracion1'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`supuracion1${idPieza}a`} name={`supuracion1${idPieza}`} checked={p.caras[0] && p.caras[0].supuracion1} /><span class="checkbox checkAmarillo"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'supuracion2'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`supuracion2${idPieza}a`} name={`supuracion2${idPieza}`} checked={p.caras[0] && p.caras[0].supuracion2} /><span class="checkbox checkAmarillo"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'supuracion3'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`supuracion3${idPieza}a`} name={`supuracion3${idPieza}`} checked={p.caras[0] && p.caras[0].supuracion3} /><span class="checkbox checkAmarillo"></span></label>
                                        </TableRow>
                                        <TableRow >
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'placa1'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`placa1${idPieza}a`} name={`placa1${idPieza}`} checked={p.caras[0] && p.caras[0].placa1} /><span class="checkbox checkAzul"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'placa2'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`placa2${idPieza}a`} name={`placa2${idPieza}`} checked={p.caras[0] && p.caras[0].placa2} /><span class="checkbox checkAzul"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={3} data-atributo={'placa3'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`placa3${idPieza}a`} name={`placa3${idPieza}`} checked={p.caras[0] && p.caras[0].placa3} /><span class="checkbox checkAzul"></span></label>
                                        </TableRow>
                                        <TableRow >
                                            <input data-pieza={idPieza} data-cara={3} data-atributo={'margen1'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoRojo" type="number" id={`margen1${idPieza}a`} name={`margen1${idPieza}`} defaultValue={0} value={p.caras[0] && p.caras[0].margen1} />
                                            <input data-pieza={idPieza} data-cara={3} data-atributo={'margen2'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoRojo" type="number" id={`margen2${idPieza}a`} name={`margen2${idPieza}`} defaultValue={0} value={p.caras[0] && p.caras[0].margen2} />
                                            <input data-pieza={idPieza} data-cara={3} data-atributo={'margen3'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoRojo" type="number" id={`margen3${idPieza}a`} name={`margen3${idPieza}`} defaultValue={0} value={p.caras[0] && p.caras[0].margen3} />
                                        </TableRow>
                                        <TableRow >
                                            <input data-pieza={idPieza} data-cara={3} data-atributo={'profundidad1'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoAzul" type="number" id={`profundidad1${idPieza}a`} name={`profundidad1${idPieza}`} defaultValue={0} value={p.caras[0] && p.caras[0].profundidad1} />
                                            <input data-pieza={idPieza} data-cara={3} data-atributo={'profundidad2'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoAzul" type="number" id={`profundidad2${idPieza}a`} name={`profundidad2${idPieza}`} defaultValue={0} value={p.caras[0] && p.caras[0].profundidad2} />
                                            <input data-pieza={idPieza} data-cara={3} data-atributo={'profundidad3'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoAzul" type="number" id={`profundidad3${idPieza}a`} name={`profundidad3${idPieza}`} defaultValue={0} value={p.caras[0] && p.caras[0].profundidad3} />
                                        </TableRow>
                                        <TableRow >
                                            <GraficaLineasPiezaSuperior id={`imagen${idPieza}a`} datos={obtenerGrafico(ubicacion,`${idPieza}`, 3)} imagen={obtenerGraficoImagen(ubicacion,`${idPieza}`, 3)} />
                                        </TableRow>
                                        <TableRow >
                                            <GraficaLineasPiezaInferior id={`imagen${idPieza}b`} datos={obtenerGrafico(ubicacion,`${idPieza}`, 2)} imagen={obtenerGraficoImagen(ubicacion,`${idPieza}`, 2)} />
                                        </TableRow>
                                        <TableRow >
                                            <input data-pieza={idPieza} data-cara={2} data-atributo={'profundidad1'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoAzul" type="number" id={`profundidad1${idPieza}a`} name={`profundidad1${idPieza}`} defaultValue={0} value={p.caras[1] && p.caras[1].profundidad1} />
                                            <input data-pieza={idPieza} data-cara={2} data-atributo={'profundidad2'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoAzul" type="number" id={`profundidad2${idPieza}a`} name={`profundidad2${idPieza}`} defaultValue={0} value={p.caras[1] && p.caras[1].profundidad2} />
                                            <input data-pieza={idPieza} data-cara={2} data-atributo={'profundidad3'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoAzul" type="number" id={`profundidad3${idPieza}a`} name={`profundidad3${idPieza}`} defaultValue={0} value={p.caras[1] && p.caras[1].profundidad3} />
                                        </TableRow>
                                        <TableRow >
                                            <input data-pieza={idPieza} data-cara={2} data-atributo={'margen1'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoRojo" type="number" id={`margen1${idPieza}a`} name={`margen1${idPieza}`} defaultValue={0} value={p.caras[1] && p.caras[1].margen1} />
                                            <input data-pieza={idPieza} data-cara={2} data-atributo={'margen2'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoRojo" type="number" id={`margen2${idPieza}a`} name={`margen2${idPieza}`} defaultValue={0} value={p.caras[1] && p.caras[1].margen2} />
                                            <input data-pieza={idPieza} data-cara={2} data-atributo={'margen3'} onChange={(e) => guardarDatosCaraNumero(ubicacion,e)} class="campoTextoRojo" type="number" id={`margen3${idPieza}a`} name={`margen3${idPieza}`} defaultValue={0} value={p.caras[1] && p.caras[1].margen3} />
                                        </TableRow>
                                        <TableRow >
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'placa1'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`placa1${idPieza}a`} name={`placa1${idPieza}`} checked={p.caras[1] && p.caras[1].placa1} /><span class="checkbox checkAzul"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'placa2'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`placa2${idPieza}a`} name={`placa2${idPieza}`} checked={p.caras[1] && p.caras[1].placa2} /><span class="checkbox checkAzul"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'placa3'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`placa3${idPieza}a`} name={`placa3${idPieza}`} checked={p.caras[1] && p.caras[1].placa3} /><span class="checkbox checkAzul"></span></label>
                                        </TableRow>
                                        <TableRow >
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'supuracion1'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`supuracion1${idPieza}a`} name={`supuracion1${idPieza}`} checked={p.caras[1] && p.caras[1].supuracion1} /><span class="checkbox checkAmarillo"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'supuracion2'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`supuracion2${idPieza}a`} name={`supuracion2${idPieza}`} checked={p.caras[1] && p.caras[1].supuracion2} /><span class="checkbox checkAmarillo"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'supuracion3'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`supuracion3${idPieza}a`} name={`supuracion3${idPieza}`} checked={p.caras[1] && p.caras[1].supuracion3} /><span class="checkbox checkAmarillo"></span></label>
                                        </TableRow>
                                        <TableRow >
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'sangrado1'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`sangrado1${idPieza}a`} name={`sangrado1${idPieza}`} checked={p.caras[1] && p.caras[1].sangrado1} /><span class="checkbox checkRojo"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'sangrado2'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`sangrado2${idPieza}a`} name={`sangrado2${idPieza}`} checked={p.caras[1] && p.caras[1].sangrado2} /><span class="checkbox checkRojo"></span></label>
                                            <label><input data-pieza={idPieza} data-cara={2} data-atributo={'sangrado3'} onChange={(e) => guardarDatosCaraCheck(ubicacion,e)} type="checkbox" class='campoCheckbox' id={`sangrado3${idPieza}a`} name={`sangrado3${idPieza}`} checked={p.caras[1] && p.caras[1].sangrado3} /><span class="checkbox checkRojo"></span></label>
                                        </TableRow>
                                        <TableRow >
                                            {((i > -1 && i < 3) || (i > 12 && i < 16)) ?
                                                <img class="campoFurca" id={`furcacion1${idPieza}a`} onClick={() => guardarDatosFurca(ubicacion,idPieza, 2, 'furcacion1')} alt="icono" src={require(`assets/img/icons/common/${obtenerImagenFurcacion(p.caras[1] && p.caras[1].furcacion1)}.png`).default} />
                                                : <span>-</span>}
                                        </TableRow>
                                        <TableRow >
                                            <input maxLength={2} data-pieza={idPieza} data-atributo={'detalle'} onChange={(e) => guardarDatosPiezaTexto(ubicacion,e)} type="text" class={"campoTexto"} id={`detalle${idPieza}b`} value={p.nota} />
                                        </TableRow>
                                    </TableCell>
                                </>
                            })
                            : <></>}
                    </TableBody>
                </Table>
            </TableContainer >
        </>
    )
}

