import { queryPut } from "functions/controllers";
import { queryGet } from "functions/controllers";
import { queryPost } from "functions/controllers";
var crypto = require('crypto-browserify');

const url = process.env.REACT_APP_API_URL + "Usuario/";

export async function loginUsuario(credenciales) {
  credenciales = encriptarClave(credenciales);
  return queryPost(url + 'login', credenciales);
}

export function encriptarClave(credenciales) { 
  credenciales.clave = credenciales.cedula + ":" + encriptarSHA1(credenciales.clave);
  credenciales.clave = encriptarSHA1(credenciales.clave); 
  return credenciales;
}

export function encriptarSHA1(texto) { 
  return crypto.createHash('sha1').update(texto).digest('hex').toUpperCase(); 
}

export function peticionGetSesion(id) {
  return queryGet(url + "sesion");
}

export function peticionCerrarSesion(id) {
  return queryPut(url  + "logout");
}
