import { queryPatch } from "functions/controllers";
import { queryGet, queryPost, queryDelete, queryPut } from "functions/controllers";
import { obtenerFechaMesesDif, formatoFecha } from 'functions/fechas';
const url = process.env.REACT_APP_API_URL + "Agenda/";

export function peticionGetCitas() {
  const fechaDesde= obtenerFechaMesesDif(-3);
  const fechaHasta= obtenerFechaMesesDif(3);
  const elemento={"fechaDesde":formatoFecha(fechaDesde,"YYYY-MM-DD"),
                  "fechaHasta":formatoFecha(fechaHasta, "YYYY-MM-DD")}
  return queryPost(url + "reservas", elemento);
}

export function peticionGetCitasRango(elemento) {
  return queryPost(url + "reservas", elemento);
}

export function peticionPostCita(elemento) {
  return queryPost(url + elemento.idConsultorio, elemento);
}

export function peticionGetCita(id) {
  return queryGet(url + id);
}

export function peticionDeleteCita(id) {
  return queryDelete(url  + id);
}

export function peticionPatchCita(id, elemento) {
  return queryPatch(url + id, elemento);
}

export function peticionCancelarCita(id, elemento) {
  return queryPut(url + id + "/cancelar", elemento);
}

export function peticionPutFinalizar(id, elemento) {
  return queryPut(url + id + "/finalizar", elemento);
}

