import { validarPermiso } from "functions/permisos";
import { Route } from "react-router-dom";
import rutas from "routes.js";
import Restringido from "views/usuario/restringido";

function obtenerRutas(rolesUsuario) {
  const idRolesUsuario = rolesUsuario ? rolesUsuario.map(r => r.idRol) : [];
  return rutas.filter((r) => {
    if (validarPermiso(idRolesUsuario, r.allowedRoles))
      return r;
  });
}

function obtenerRutasSegunRol(rolesUsuario) {
  const idRolesUsuario = rolesUsuario ? rolesUsuario.map(r => r.idRol) : [];

  return rutas.map((prop, key) => {
    if (prop.layout !== "/app")
      return null;

    if (validarPermiso(idRolesUsuario, prop.allowedRoles))
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );

    return (
      <Route
        path={prop.layout + prop.path}
        component={Restringido}
        key={key}
      />
    );
  });
}

function obtenerRutaTitulo(ubicacion) {
  let pathnameAbsolut = ubicacion.pathname;
  for (let i = 0; i < rutas.length; i++) {
    if (pathnameAbsolut.indexOf(rutas[i].layout + rutas[i].pathRoot) !== -1 || pathnameAbsolut.indexOf(rutas[i].layout + rutas[i].path) !== -1) {
      return rutas[i].headerTitle ? rutas[i].headerTitle : rutas[i].name;
    }
  }
  return "Sección no encontrada";
}


export { obtenerRutas, obtenerRutasSegunRol, obtenerRutaTitulo };