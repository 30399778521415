export const agenda_traduccion = {
    'es-UY': {
      allDay: 'Día',
      discardButton:'Descartar',
      deleteButton: 'Eliminar',
      cancelButton:'Cancelar',
      confirmDeleteMessage: '¿Estás seguro de eliminar esta cita?',
      confirmCancelMessage: '¿Descartar cambios recientes?',
      today: 'hoy',
      current:'Ésta cita',
      currentAndFollowing: 'Ésta y las siguientes citas',
      all:	'Todas las citas',
      menuEditingTitle: 'Editar cita recurrente',
      menuDeletingTitle: 'Eliminar cita recurrente',
      commitButton: 'Confirmar',
      detailsLabel:	'Detalles',
      allDayLabel:	'Todo el día',
      titleLabel:	'Título',
      commitCommand:	'Guardar',
      moreInformationLabel: 'Mas información',
      repeatLabel:	'Repetir',
      notesLabel:	'Notas',
      never:	'Nunca',
      daily:	'Diario',
      weekly:'Semanal',
      monthly:	'Mensual',
      yearly:	'Anual',
      repeatEveryLabel:'Repetir cada',
      daysLabel:	'dia(s)',
      endRepeatLabel:	'Finializar repetir',
      onLabel:	'En',
      afterLabel:	'Después',
      occurrencesLabel:	'ocurrencia(s)',
      weeksOnLabel:	'semana(s)',
      monthsLabel:	'mes(es)',
      ofEveryMonthLabel:	'de cada mes',
      theLabel:	'El',
      firstLabel: 'Primer',
      secondLabel:	'Segundo',
      thirdLabel:	'Tercer',
      fourthLabel:	'Cuarto',
      lastLabel:	'ültimo',
      yearsLabel:	'año(s)',
      ofLabel:	'de',
      everyLabel:	'Cada',
    }
  };

  export const agenda_formulario_traduccion = {
    moreInformationLabel: "",
    commitCommand:"Guardar",
    notesLabel:"",
    detailsLabel:"Reserva"
  };