import React, { useLayoutEffect } from "react";


import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useAlert } from "react-alert";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import * as ControllerPeriodontogramas from 'controllers/paciente/Periodontograma';
import MenuTabla from "components/Tabla/MenuTabla";
import AlertaDialogo from "components/Popups/AlertaDialogo";
import { formatoFecha } from 'functions/fechas';
import { useParams } from "react-router-dom";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";
import { convertirTimezone } from "functions/fechas";

const useStyles = makeStyles(componentStyles);

const Periodontogramas = (props) => {
  let { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [periodontogramas, setPeriodontogramas] = React.useState();
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [idEliminar, setIdEliminar] = React.useState();

  const peticionDelete = async (idElemento) => {
    cerrarModalEliminar();
    if (idElemento) {
      const r = await ControllerPeriodontogramas.peticionDeletePeriodontograma(id, idElemento);
      if (r.resultado) {
        setPeriodontogramas(periodontogramas.filter(i => i.idPeriodontograma !== idElemento));
        alert.success(r.mensaje)
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const eliminarRegistro = (idElemento) => {
    setIdEliminar(idElemento);
    setModalEliminar(true);
  }

  const cerrarModalEliminar = () => {
    setIdEliminar();
    setModalEliminar(false);
  }

  const itemsMenu = [{ nombre: "Ver", "href": `/app/pacientes/periodontogramas/grafico/${id}/`},
  { nombre: "Eliminar", "funcion": eliminarRegistro }];

  const obtenerPeriodontogramas = async () => {
    const r = await ControllerPeriodontogramas.peticionGetPeriodontogramas(id);
    if (r.resultado) {
      setPeriodontogramas(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  useLayoutEffect(() => {
    obtenerPeriodontogramas();
  }, []);

  return (
    <>
      <Header />
      
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>


          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    Listado
                  </Box>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    justifyContent="flex-end"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      href={`/app/pacientes/periodontogramas/grafico/${id}/`}
                    >
                      Nuevo
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>

          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Fecha
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Detalle
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Profesional
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Consultorio
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!periodontogramas ? <TablaCarga espacio={5} /> :
                  periodontogramas.length < 1 ? <SinRegistros espacio={5} /> :
                    periodontogramas.map(periodontograma => {
                    return (
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          align="right">
                          <MenuTabla id={periodontograma.idPeriodontograma} items={itemsMenu} />
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {periodontograma.fechaRegistro? formatoFecha(convertirTimezone(periodontograma.fechaRegistro,'GMT-3'),"DD/MM/YYYY HH:MM"): 'Desconocido'}
                        </TableCell>     
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {periodontograma.detalle}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {periodontograma.medico ? `${periodontograma.medico.nombre1} ${periodontograma.medico.apellido1}`:'Desconocido'}
                        </TableCell> 
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {periodontograma.consultorio ? `${periodontograma.consultorio.nombre}`:'Desconocido'}
                        </TableCell>                     
                      </TableRow>
                    )
                  })},
              </TableBody>
            </Box>
          </TableContainer>
        </Card>

      </Container>
      {modalEliminar && <AlertaDialogo titulo={"Seguro desea eliminar el periodontograma seleccionado?"} id={idEliminar} funcionConfirmar={peticionDelete} abierto={modalEliminar} funcionCerrar={cerrarModalEliminar} />}
    </>
  );
};

export default Periodontogramas;
