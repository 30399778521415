import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import { CardContent } from "@material-ui/core";
import { ReportProblemOutlined } from "@material-ui/icons";

const useStyles = makeStyles(componentStyles);

const Inexistente = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Header />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }} height="100%">


          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    <ReportProblemOutlined />  ATENCIÓN
                  </Box>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    justifyContent="flex-end"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      href="/app/inicio"
                    >
                      Ir a inicio
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>
          <CardContent>
            <Grid container component={Box} padding={'1em'}
              direction="column"
              alignItems="center"
              justify="center">
              <Box
                component={Typography}
                variant="h5"
              >
                Intentó ingresar a una sección que no existe o no se puede acceder en este momento, si cree que es un error intente nuevamente más tarde.
              </Box>
              <Box>
                <img style={{ maxWidth: "100%", height: "auto" }} src={require("assets/img/theme/error404.png").default} alt="La sección no existe" />
              </Box>

            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Inexistente;
