import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import * as ControllerPacientes from 'controllers/Pacientes';
import * as ControllerFichas from 'controllers/paciente/Ficha';
import { useAlert } from "react-alert";
import MUIDataTable from "mui-datatables";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Odontograma } from "components/Odontograma/Grafico";
import HeadPaciente from "components/Perfil/Head_Paciente";
import { convertirTimezone } from "functions/fechas";
import { formatoFecha } from "functions/fechas";
import { tabla_traduccion } from "traducciones/tabla_traduccion";
import { useDispatch, useSelector } from "react-redux";
import { ObtenerPiezas } from "functions/datosSistema";

const useStyles = makeStyles(componentStyles);

function OdontogramaPaciente() {
  let { id } = useParams();
  const classes = useStyles();
  const alert = useAlert();
  const dispatch = useDispatch();
  const piezasCargadas = useSelector((state) => state.piezas);
  const [paciente, setPaciente] = React.useState([]);
  const [registros, setRegistros] = React.useState([]);
  const [piezaSeleccionada, setPiezaSeleccionada] = React.useState();
  const [seleccionadoTodo, setSeleccionadoTodo] = React.useState(false);

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const seleccionarTodas = () => {
    (!seleccionadoTodo) ? seleccionarPieza(0) : seleccionarPieza();
    setSeleccionadoTodo(!seleccionadoTodo);
  }

  const seleccionarPieza = (numeroPieza) => {
    setRegistros([]);
    setSeleccionadoTodo(false);
    setPiezaSeleccionada(numeroPieza);
  }


  const obtenerDatosPieza = async () => {
    const r = await ControllerPacientes.peticionGetTratamientosPieza(id, piezaSeleccionada);
    if (r.resultado) {
      setRegistros(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerFichas = async () => {
    const r = await ControllerFichas.peticionGetFichas(id);
    if (r.resultado) {
      for (let i = 0; i < r.data.length; i++) {
        if (r.data[i] && r.data[i].tratamientos) {
          r.data[i].tratamientos.map(p => (
            setRegistros([
              ...registros,
              {
                "detalle": r.data[i].detalle,
                "fechaRegistro": r.data[i].reserva.fechaFin,
                "consultorio": r.data[i].consultorio,
                "medico": r.data[i].medico,
                ...p
              }])
          ));
        }
      }
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerNombrePieza = (idPieza) => {
    const pieza = piezasCargadas.find(x=> x.idPieza == idPieza);
    return pieza? `${pieza.numero} ${pieza.nombre}` : 'No se puede obtener nombre de pieza seleccionada'; 
  }

  const definirTexto = () => {
    if (seleccionadoTodo) {
      return "todas las piezas";
    }
    if (piezaSeleccionada) {
      return obtenerNombrePieza(piezaSeleccionada);
    }
    return "no se seleccionó ninguna pieza";
  }

  const obtenerPiezas = async () => {
    if (piezasCargadas && piezasCargadas.length === 0) {
      const r = await ObtenerPiezas();
      if (r) {
        dispatch({ "type": "CARGAR_PIEZAS", "data": r });
      }
    }
  }

  useLayoutEffect(() => {
    if (piezaSeleccionada === 0)
      obtenerFichas()
    if (piezaSeleccionada > 0)
      obtenerDatosPieza();
  }, [piezaSeleccionada]);

  useLayoutEffect(() => {
    obtenerPaciente();
    obtenerPiezas();
  }, []);

  return (
    <>
      <UserHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-7rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container component={Box} marginBottom={"2rem"}>
          <HeadPaciente paciente={paciente} tipo={'salud'} />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Historial detallado
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        href={"/app/pacientes/ficha/" + id}
                        target="_blank"
                      >
                        Ver ficha
                      </Button>
                    </Grid>
                  </Grid>
                } classes={{ root: classes.cardHeaderRoot }}

              ></CardHeader>
              <CardContent style={{ backgroundColor: "white" }}>
                <Grid container>
                  <Grid item xs={12} lg={3}>
                    <Grid container direction="column" alignItems="center">
                      <Odontograma confirmar={seleccionarPieza} seleccionTodo={seleccionadoTodo} />
                      <FormControlLabel
                        control={<Switch size="small" checked={seleccionadoTodo} onChange={seleccionarTodas} />}
                        label="Ver todo"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <Typography><strong>Selección:</strong> {definirTexto()}</Typography>
                    <MUIDataTable
                      title={""}
                      data={(!registros) ? [] :
                        registros.map(item => {
                          return [
                            (item.fechaRegistro) ? formatoFecha(convertirTimezone(item.fechaRegistro, 'GMT-3'), 'DD/MM/YYYY HH:MM') : 'Indefinido',
                            (item.tratamiento) ? item.tratamiento.nombre : 'Indefinido',
                            (item.tratamiento) ? item.tratamiento.categoria.nombre : 'Indefinido',
                            (item.pieza) ? `${item.pieza.numero} ${item.pieza.nombre}` : 'Indefinido',
                            (item.cara) ? item.cara.nombre : 'Indefinido',
                            (item.diagnostico) ? item.diagnostico.nombre : 'Indefinido',
                            (item.medico) ? item.medico.nombre1 + " " + item.medico.apellido1 : 'Indefinido',
                            (item.consultorio) ? item.consultorio.nombre : 'Indefinido',
                          ]
                        })
                      }
                      columns={
                        [{ name: "fechaInicio", label: "Fecha" }, { name: "nombre", label: "Tratamiento" },
                        { name: "categoria", label: "Categoría" }, { name: "pieza", label: "Pieza" },
                        { name: "cara", label: "Cara" }, { name: "diagnostico", label: "Diagnóstico" },
                        { name: "medico", label: "Médico" }, { name: "consultorio", label: "Consultorio" }]}
                      options={{
                        filterType: 'dropdown',
                        selectableRowsHideCheckboxes: true,
                        responsive: 'standard',
                        elevation: 0,
                        textLabels: tabla_traduccion
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>

  );
}

export default OdontogramaPaciente;