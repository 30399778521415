import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { DialogTitle, Grid, Typography, Paper } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { Transicion } from 'components/Efectos/Transicion';
import { formatoFecha } from 'functions/fechas'
import { useLayoutEffect } from "react";
import { useAlert } from "react-alert";
import * as ControllerAgenda from 'controllers/Agenda';
import * as ControllerTratamientos from 'controllers/Tratamientos';
import * as ControllerDiagnosticos from 'controllers/Diagnosticos';
import { ReportProblemOutlined } from '@material-ui/icons';
import componentStyles from "assets/theme/views/admin/profile.js";
import { ListaTrabajosReserva } from "components/Agenda/ListaTrabajosReserva";
import { useEffect } from 'react';
import { ObtenerCaras, ObtenerPiezas } from "functions/datosSistema";
import { AltaTrabajoReserva } from './AltaTrabajoReserva';
import { useDispatch, useSelector } from "react-redux";
import { convertirTimezone } from 'functions/fechas';

const useStyles = makeStyles(componentStyles);

export const Finalizacion = ({ visible, cerrar, id }) => {
    const classes = useStyles();
    const alert = useAlert();
    const dispatch = useDispatch();
    const piezasCargadas = (useSelector((state) => state.piezas));
    const carasCargadas = (useSelector((state) => state.caras));
    const [piezas, setPiezas] = React.useState([]);
    const [caras, setCaras] = React.useState([]);
    const [reserva, setReserva] = React.useState([]);
    const [reservaModificada, setReservaModificada] = React.useState([]);
    const [tratamientos, setTratamientos] = React.useState([]);
    const [diagnosticos, setDiagnosticos] = React.useState([]);
    const [trabajos, setTrabajos] = React.useState([]);

    const obtenerReserva = async () => {
        dispatch({ "type": "AVISO_CARGANDO", "data": true });
        const r = await ControllerAgenda.peticionGetCita(id);
        if (!r.resultado) {
            alert.error(r.mensaje);
            dispatch({ "type": "AVISO_CARGANDO", "data": false });
            return;
        }
        setReserva(r.data);
        setReservaModificada(r.data);
        dispatch({ "type": "AVISO_CARGANDO", "data": false });
    }

    const finalizarCita = async () => {
        if (reservaModificada && reservaModificada.planificado.length > 0) {
            const reservaFinalizada = {
                "realizado":
                    trabajos.map(t => ({
                        idPieza: t.idPieza.id,
                        idCara: t.idCara.id,
                        idDiagnostico: t.idDiagnostico.id,
                        idTratamiento: t.idTratamiento.id
                    }))
                ,
                "detalle": reservaModificada.detalle,
                "idMedicoAtiende": reservaModificada.medico.idMedico
            }
            const r = await ControllerAgenda.peticionPutFinalizar(id, reservaFinalizada);
            if (r.resultado) {
                cerrar();
                //Trigger evento de aviso a Calendario para recargar datos
                dispatchEvent(new Event("cambioAgenda"))
                alert.success(r.mensaje);
            } else {
                alert.error(r.mensaje);
            }
        } else {
            alert.error("Debe ingresar los tratameintos realizados en la cita");
        }
    }

    const eliminarTrabajo = (posicion) => {
        const trabajosFiltrado = trabajos.filter((i, p) => p !== posicion);
        setTrabajos(trabajosFiltrado);
        setReservaModificada({
            ...reservaModificada,
            'planificado': trabajosFiltrado
        }
        )
    }

    const crearTrabajo = (nuevoTrabajo) => {
        if (nuevoTrabajo && nuevoTrabajo.idCara && nuevoTrabajo.idDiagnostico && nuevoTrabajo.idTratamiento && nuevoTrabajo.idPieza) {
            const nuevoTrabajoPorId = {
                idPieza: nuevoTrabajo.idPieza.id,
                idCara: nuevoTrabajo.idCara.id,
                idDiagnostico: nuevoTrabajo.idDiagnostico.id,
                idTratamiento: nuevoTrabajo.idTratamiento.id
            };
            setReservaModificada({
                ...reservaModificada,
                'planificado': reservaModificada.planificado ? [...reservaModificada.planificado, nuevoTrabajoPorId] : [nuevoTrabajoPorId]
            }
            )
            setTrabajos([
                ...trabajos,
                nuevoTrabajo]
            );
        } else {
            alert.error("Debe ingresar valores en todos los campos");
        }
    }

    const obtenerTratamientos = async () => {
        const r = await ControllerTratamientos.peticionGetTratamientos();
        if (r.resultado) {
            setTratamientos(r.data.registros ? r.data.registros.map((i) => ({ id: i.idTratamiento, label: i.nombre, grupo: i.categoria && i.categoria.nombre })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    const obtenerDiagnosticos = async () => {
        const r = await ControllerDiagnosticos.peticionGetDiagnosticos();
        if (r.resultado) {
            setDiagnosticos(r.data ? r.data.map((i) => ({ id: i.idDiagnostico, label: i.nombre })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    const obtenerPiezas = async () => {
        if (piezasCargadas && piezasCargadas.length === 0) {
            const r = await ObtenerPiezas();
            if (r) {
                dispatch({ "type": "CARGAR_PIEZAS", "data": r });
                setPiezas(r.map((i) => ({ id: i.idPieza, label: `${i.numero} ${i.nombre}`, grupo: i.grupo, numero: i.numero })));
            }
        } else {
            setPiezas(piezasCargadas.map((i) => ({ id: i.idPieza, label: `${i.numero} ${i.nombre}`, grupo: i.grupo, numero: i.numero })));
        }
    }

    const obtenerCaras = async () => {
        if (carasCargadas && carasCargadas.length === 0) {
            const r = await ObtenerCaras();
            if (r) {
                dispatch({ "type": "CARGAR_CARAS", "data": r });
                setCaras(r.map((i) => ({ id: i.idCara, label: i.nombre })));
            }
        } else {
            setCaras(carasCargadas.map((i) => ({ id: i.idCara, label: i.nombre })));
        }
    }

    useLayoutEffect(() => {
        if (id) {
            obtenerPiezas();
            obtenerCaras();
            obtenerTratamientos();
            obtenerDiagnosticos();
            obtenerReserva();
        }
    }, []);


    useEffect(() => {
        if (id && reserva) {
            setTrabajos(reserva && reserva.planificado ? reserva.planificado.map((i) => {
                return {
                    "idPieza": piezas.find(x => x.numero === i.pieza.numero) || {},
                    "idCara": caras.find(x => x.id === i.cara.idCara) || {},
                    "idDiagnostico": diagnosticos.find(x => x.id === i.diagnostico.idDiagnostico) || {},
                    "idTratamiento": tratamientos.find(x => x.id === i.tratamiento.idTratamiento) || {}
                }
            }) : []);
        }
    }, [reserva]);

    return (
        <Dialog
            maxWidth="md"
            open={visible}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrar}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="form-dialog-title" >
                <Typography variant="h2">Confirmación de asistencia</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div className={classes.plLg4}>
                        <Box marginBottom="1.5rem">
                            <Grid Container >
                                <Typography variant="h3">Información:</Typography>
                                <Grid item xs={12} lg={12}>
                                    <Typography variant="h4"><strong>Paciente:</strong>  {reserva.paciente ? reserva.paciente.nombre1 + ' ' + reserva.paciente.apellido1 : 'Desconocido'} </Typography>
                                    <Typography variant="h4"><strong>Cita:</strong> {reserva.fechaInicio && reserva.fechaFin ? formatoFecha(convertirTimezone(reserva.fechaInicio, 'GMT-3'), "DD/MM/YYYY HH:MM") + ' - ' + formatoFecha(convertirTimezone(reserva.fechaFin, 'GMT-3'), "DD/MM/YYYY HH:MM") : 'Desconocido'} </Typography>
                                    <Typography variant="h4"><strong>Doctor:</strong>  {reserva.medico ? reserva.medico.nombre1 + ' ' + reserva.medico.apellido1 : 'Desconocido'} </Typography>
                                    <Typography variant="h4"><strong>Consultorio:</strong>  {reserva.consultorio ? reserva.consultorio.nombre : 'Desconocido'} </Typography>
                                    <Typography variant="h4"><strong>Nota:</strong>  {reserva.detalle ? reserva.detalle : 'No se registró ninguna nota'} </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid Container>
                            <Typography variant="h3">Acciones realizadas:</Typography>
                            <Grid item xs={12} lg={12}>
                                <Paper style={{"overflowX":"auto"}}>
                                    <Grid
                                        container
                                        component={Box}
                                        padding={'0em 1em 0em 1em'}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        
                                            <ListaTrabajosReserva datos={trabajos} eliminarTrabajo={eliminarTrabajo} />
                                        
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <AltaTrabajoReserva piezas={piezas} caras={caras} diagnosticos={diagnosticos} tratamientos={tratamientos} funcionConfirmar={crearTrabajo} />
                            </Grid>
                        </Grid>
                        <Grid Container style={{ "marginTop": "1rem" }} >
                            <Grid item xs={2} lg={2}>
                                <Typography variant="h4"><ReportProblemOutlined /> Aviso:</Typography>
                            </Grid>
                            <Grid item xs={10} lg={10}>
                                <Typography variant="h4">Tenga en cuenta que luego de confirmar se agregarán las acciones realizadas a la ficha del paciente y la deuda generada a su cuenta.</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContentText>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button color="secondary" variant="contained" onClick={() => cerrar()}>
                        Cerrar
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => finalizarCita()}>
                        Finalizar
                    </Button>
                </Box>

            </DialogContent>
        </Dialog>
    );
}