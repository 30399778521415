import React from "react";
import Box from "@material-ui/core/Box";
import { useAlert } from "react-alert";
import * as ControllerPacientes from 'controllers/Pacientes';
import { useLayoutEffect } from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { acortarTexto } from "functions/formulario";

const InfoSalud = ({ paciente }) => {
  const alert = useAlert();
  const [patologias, setPatologias] = React.useState([]);
  const [alergias, setAlergias] = React.useState([]);
  const [medicamentos, setMedicamentos] = React.useState([]);

  const obtenerPatologias = async () => {
    const r = await ControllerPacientes.peticionGetPacientePatologias(paciente.idPaciente);
    if (r.resultado) {
      setPatologias(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerAlergias = async () => {
    const r = await ControllerPacientes.peticionGetPacienteAlergias(paciente.idPaciente);
    if (r.resultado) {
      setAlergias(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerMedicamentos = async () => {
    const r = await ControllerPacientes.peticionGetPacienteMedicamentos(paciente.idPaciente);
    if (r.resultado) {
      setMedicamentos(r.data);
    } else {
      alert.error(r.mensaje);
    }
  }

  useLayoutEffect(() => {
    if (paciente && paciente.idPaciente) {
      obtenerAlergias();
      obtenerPatologias();
      obtenerMedicamentos();
    }
  }, [])

  return (
    <>
      <Box
        component="p"
        fontWeight="800"
        lineHeight="1.7"
        marginBottom="0rem"
        fontSize="1rem"
        textAlign="center"
      >
        Alergias:
      </Box>
      <Box
        component="p"
        fontWeight="300"
        lineHeight="1.7"
        marginBottom="1rem"
        fontSize="1rem"
        textAlign="center"
      >
        {(alergias && alergias.length > 0) ?
          alergias.map(i => {
            return (
              <Tooltip title={`${i.alergia.descripcion ? `Descripción: ${acortarTexto(i.alergia.descripcion, 120)}` : ''}`}>
                <Typography variant="body2"> {`- ${i.alergia.nombre}`}</Typography>
              </Tooltip>
            )
          }
          )
          : "Ninguna"}
      </Box>
      <Box
        component="p"
        fontWeight="800"
        lineHeight="1.7"
        marginBottom="0rem"
        fontSize="1rem"
        textAlign="center"
      >
        Medicamentos:
      </Box>
      <Box
        component="p"
        fontWeight="300"
        lineHeight="1.7"
        marginBottom="1rem"
        fontSize="1rem"
        textAlign="center"
      >
        {(medicamentos && medicamentos.length > 0) ?
          medicamentos.map(i => {
            return (
              <Tooltip title={`${i.medicamento.principioActivo ? `Principio activo: ${i.medicamento.principioActivo}` : ''}`}>
                <Typography variant="body2"> {`- ${i.medicamento.nombre}`}</Typography>
              </Tooltip>
            )
          }
          )
          : "Ninguno"}
      </Box>
      <Box
        component="p"
        fontWeight="800"
        lineHeight="1.7"
        padding={0}
        fontSize="1rem"
        textAlign="center"
      >
        Patologías
      </Box>
      <Box
        component="p"
        fontWeight="300"
        lineHeight="1.7"
        padding={0}
        marginBottom="1rem"
        fontSize="1rem"
        textAlign="center"
      >
        {(patologias && patologias.length > 0) ?
          patologias.map(i => {
            return (
              <Tooltip title={`${i.patologia.descripcion ? `Descripción: ${acortarTexto(i.patologia.descripcion, 40)}` : ''}`}>
                <Typography variant="body2"> {`- ${i.patologia.nombre}`}</Typography>
              </Tooltip>
            )
          }
          )
          : "Ninguna"}
      </Box>
    </>
  );
};

export default InfoSalud;