
const evaluarForm = (objeto, camposRequeridos) => {
  let r = { mensaje: "", resultado: true };
  let camposInvalidos = [];
  if (objeto) {
    camposRequeridos.forEach(c => {
      if (!objeto.hasOwnProperty(c.id) || objeto[c.id] == null || objeto[c.id].length <= 0 || objeto[c.id] === " ") {
        r.resultado = false;
        camposInvalidos.push(c.nombre);
      }
    });
    if (!r.resultado)
      r.mensaje = `Ingrese todos los campos solicitados (falta: ${camposInvalidos.join(", ")})`;
    return r;
  }
  return { mensaje: "Ingrese todos los campos solicitados", resultado: false };
}


const evaluarCorreo = (correo) => {
  let r = { mensaje: "", resultado: true };
  const validacion = correo.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  if (!validacion)
    r = { mensaje: "El correo ingresado no es válido", resultado: false };
  return r;
}

const evaluarNacimiento = (fecha) => {
  let r = { mensaje: "", resultado: true };
  const fechaIngresada= new Date(fecha);
  const fechaActual= new Date();
  if (fechaIngresada > fechaActual)
    r = { mensaje: "La fecha de nacimiento no puede ser posterior al día actual", resultado: false };
  return r;
}


const validarStringNumerico = (texto) => {
  return !isNaN(texto);
}


const evaluarTelefono = (numero) => {
  let r = { mensaje: "", resultado: true };
  const validacion= numero.match(/\+[0-9]+/i);
  if (!validacion)
    r = { mensaje: "El número de teléfono ingresado es inválido, recuerde agregar característica del país (ej: +5986010101)", resultado: false };
  return r;
}


const evaluarCelular = (numero) => {
  let r = { mensaje: "", resultado: true };
  const validacion= numero.match(/\+[0-9]+/i);
  if (!validacion)
    r = { mensaje: "El número de celular ingresado es inválido, recuerde agregar característica del país (ej: +59899100200)", resultado: false };
  return r;
}

const b64aBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function validarDigitos(ci) {
  let a = 0;
  let i = 0;
  if (ci.length <= 6) {
    for (let i = ci.length; i < 7; i++) {
      ci = '0' + ci;
    }
  }
  for (let i = 0; i < 7; i++) {
    a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
  }
  if (a % 10 === 0) {
    return 0;
  } else {
    return 10 - a % 10;
  }
}

function esJSON(texto) {
  try {
    JSON.parse(texto);
  } catch (e) {
    return false;
  }
  return true;
}

const validarCI = (cedula) => {
  let r = { mensaje: "", resultado: true };
  if(isNaN(cedula))
    return { mensaje: "Debe ingresar sólo los dígitos de la cédula", resultado: false };
  let digito = cedula[cedula.length - 1];
  const validacion =(digito == validarDigitos(cedula));
  if (!validacion)
    r = { mensaje: "La cédula ingresada no es válida", resultado: false };
  return r;
}

function acortarTexto(texto, caracteres) {
  const caracteresTexto = texto.length;
  const textoCortado = texto.slice(0, caracteres)
  return caracteresTexto > caracteres ? `${textoCortado}...` : textoCortado;
}


export { evaluarForm, evaluarCelular, evaluarTelefono, evaluarCorreo, evaluarNacimiento, esJSON, acortarTexto, validarStringNumerico, b64aBlob, validarCI };