import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { formatoFecha } from "functions/fechas";

const Historial = props => {
    const texto = `Creación: ${formatoFecha(props.creacion.fecha,"DD/MM/YYYY HH:MM")}\n
    Modificación: ${(props.modificacion.fecha <= props.creacion.fecha) || props.modificacion.fecha === null ? 'no hubo' : formatoFecha(props.modificacion.fecha,"DD/MM/YYYY HH:MM")}`;
    return (
        <Tooltip title={texto}>
            <IconButton>
                <InfoOutlined />
            </IconButton>
        </Tooltip>
    );
};

export default Historial;