import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from '@material-ui/core/Link';

import componentStyles from "assets/theme/views/admin/icons.js";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

const Boton = ({ url, nombre, clase }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid item
      lg={3}
      md={6}
      xs={12}
      component={Box}
      paddingLeft="15px"
      paddingRight="15px"
      maxWidth={"100% !important"}
    >
      <Link href={url}>
        <Box
          component="button"
          fontFamily="inherit"
          fontSize="16px"
          fontWeight="400"
          lineHeight="1.25"
          display="inline-block"
          width={"100% !important"}
          margin=".5rem 0"
          padding="24px"
          textAlign="left"
          color={theme.palette.gray[800]}
          border="0"
          borderRadius="4px"
          className={classes.button}
          data-clipboard-text="album-2"
          type="button"
        >
          <div>
            <i className={clase} />
            <span>{nombre}</span>
          </div>
        </Box>
      </Link>
    </Grid>
  );
};

export default Boton;
