import React from "react";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import Person from "@material-ui/icons/Person";
import Settings from "@material-ui/icons/Settings";
import componentStyles from "assets/theme/components/navbar-dropdown.js";
import { useDispatch, useSelector } from "react-redux";
import CambiarClave from "views/autenticacion/popup/CambiarClave";
import { useAlert } from "react-alert";
import { obtenerRegistro } from "functions/browserDB";
import { checkRegistro } from "functions/browserDB";
import { borrarBrowserDB } from "functions/browserDB";
import * as ControllerAutenticacion from 'controllers/Autenticacion';

const useStyles = makeStyles(componentStyles);

const MenuUsuario = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [mostrarMenu, setMostrarMenu] = React.useState(null);
  const sesion = useSelector((state) => state.sesion);
  const usuario = useSelector((state) => state.usuario);
  const [mostrarCambiarClave, setMostrarCambiarClave] = React.useState(false);
  const [avatarCargado, setAvatarCargado] = React.useState('');
  const isMenuOpen = Boolean(mostrarMenu);


  const abrirPopUPCambiarClave = () => {
    setMostrarMenu(null);
    setMostrarCambiarClave(true);
  };

  const cerrarPopUPCambiarClave = () => {
    setMostrarCambiarClave(false);
  };

  const handleProfileMenuOpen = (event) => {
    setMostrarMenu(event.currentTarget);
  };

  const cerrarMenu = () => {
    setMostrarMenu(null);
  };

  async function cerrarSesion() {
    const r = await ControllerAutenticacion.peticionCerrarSesion();
    setMostrarMenu(null);
    borrarBrowserDB();
    dispatch({ "type": "LOGOUT" });
    alert.success("Sesión cerrada correctamente")
  }

  async function obtenerAvatar() {
    if (checkRegistro('avatar')) {
      const r = await obtenerRegistro('avatar');
      setAvatarCargado(r);
    }
  }

  useEffect(() => {
    if (sesion.forzarCambioDeClave)
      abrirPopUPCambiarClave();
    obtenerAvatar();
  }, [sesion]);


  useEffect(() => {
    //Pendiente de evento avatar cargado en localforage
    window.addEventListener('avatarCargado', () => {
      obtenerAvatar();
    })
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={mostrarMenu}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={cerrarMenu}
    >
      <Typography
        variant="h6"
        component="h6"
        classes={{ root: classes.menuTitle }}
      >
        Opciones:
      </Typography>
      <a href={"/app/usuario/perfil/"} style={{ textDecoration: 'none', color: 'black' }}>
        <Box
          display="flex!important"
          alignItems="center!important"
          component={MenuItem}
          onClick={cerrarMenu}

        >

          <Box
            component={Person}
            width="1.25rem!important"
            height="1.25rem!important"
            marginRight="1rem"
          />
          <span >Mi perfil</span>
        </Box>
      </a>
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={abrirPopUPCambiarClave}
      >
        <Box
          component={Settings}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span >Cambiar contraseña</span>
      </Box>
      <Divider component="div" classes={{ root: classes.dividerRoot }} />
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={cerrarSesion}
      >
        <Box
          component={DirectionsRun}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"

        />
        <span >Cerrar sesión</span>
      </Box>
    </Menu >
  );

  return (
    <>
      <Button
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        classes={{
          label: classes.buttonLabel,
          root: classes.buttonRoot,
        }}
      >
        <Avatar
          alt={usuario && usuario.nombre ? usuario.nombre : ''}
          src={avatarCargado ? URL.createObjectURL(avatarCargado) :
            require("assets/img/theme/avatar.png").default}
          classes={{
            root: classes.avatarRoot,
          }}
        />

        <Hidden smDown>{(usuario) ? usuario.nombre : "Usuario"}</Hidden>
      </Button>

      {/* PopUp Cambio de clave */}
      {mostrarCambiarClave && <CambiarClave mostrarCambiarClave={mostrarCambiarClave} cerrar={cerrarPopUPCambiarClave} />}

      {renderMenu}
    </>
  );
}

export default MenuUsuario;