import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import * as ControllerMutualistas from 'controllers/Mutualistas';
import { useAlert } from "react-alert";
import { evaluarForm } from "functions/formulario";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select } from "@material-ui/core";
import { ObtenerPaises } from "functions/datosSistema";

const useStyles = makeStyles(componentStyles);

function Mutualista() {
  let { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const [mutualista, setMutualista] = React.useState([]);
  const [mutualistaActualizado, setMutualistaActualizado] = React.useState({idPais:214});
  const paises = useSelector((state) => state.paises);
  const camposRequeridos = [{ id: 'nombre', nombre: 'nombre' },{ id: 'telefono', nombre: 'Teléfono' },{ id: 'idPais', nombre: 'País' }];

  const peticion = async (tipo) => {
    const validarForm = evaluarForm(mutualistaActualizado, camposRequeridos);
    let r = { resultado: false };
    if (validarForm.resultado) {
      switch (tipo) {
        case "post":
          r = await ControllerMutualistas.peticionPostMutualista(mutualistaActualizado);
          break;
        case "put":
          r = await ControllerMutualistas.peticionPutMutualista(id, mutualistaActualizado);
          break;
        default:
          break;
      }
      if (r.resultado) {
        alert.success(r.mensaje);
        history.push("/app/mutualistas/listado");
      } else {
        alert.error(r.mensaje);
      }
      return;
    }
    alert.error(validarForm.mensaje);
  }

  const guardarDatos = async e => {
    await setMutualistaActualizado({
      ...mutualistaActualizado,
      [e.target.name]: e.target.value
    });
  };

  async function obtenerObjeto() {
    if (id) {
      const r = await ControllerMutualistas.peticionGetMutualista(id);
      if (r.resultado) {
        setMutualista(r.data);
        setMutualistaActualizado(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const obtenerPaises = async () => {
    if (paises && paises.length === 0) {
      const r = await ObtenerPaises();
      dispatch({ "type": "CARGAR_PAISES", "data": r });
    }
  };

  useEffect(async () => {
    obtenerPaises();
    obtenerObjeto();
  }, []);

  return (
    <>
      <UserHeader />
      
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>

          <Grid
            item
            xs={12}
            xl={8}
            justifyContent="center"
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Info de mutualista
                      </Box>
                    </Grid>

                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
              <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Nombre*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="nombre"
                            key={mutualista ? mutualista.nombre : 'mutualista.nombre'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={mutualista ? mutualista.nombre : ''}
                            inputProps={{maxLength: 20}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Detalle</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="detalle"
                            key={mutualista ? mutualista.detalle : 'mutualista.detalle'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={mutualista ? mutualista.detalle : ''}
                            inputProps={{maxLength: 100}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Teléfono*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="telefono"
                            key={mutualista ? mutualista.telefono : 'mutualista.telefono'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={mutualista ? mutualista.telefono : ''}
                            inputProps={{maxLength: 20}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Dirección</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="direccion"
                            key={mutualista ? mutualista.direccion : 'mutualista.direccion'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={mutualista ? mutualista.direccion : ''}
                            inputProps={{maxLength: 50}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Departamento</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="departamento"
                            key={mutualista ? mutualista.departamento : 'mutualista.departamento'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={mutualista ? mutualista.departamento : ''}
                            inputProps={{maxLength: 50}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>País*</FormLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select defaultValue={(mutualista && mutualista.idPais) || 214} name="idPais" key={mutualista ? mutualista.idPais : 'mutualista.idPais'}
                            onChange={(e) => guardarDatos(e)}>
                            {paises && paises.map(p => {
                              return (
                                <MenuItem value={p.idPais}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/mutualistas/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => { id ? peticion("put") : peticion("post") }}
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default Mutualista;
