import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogTitle, Dialog, Select, MenuItem, Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import FilledInput from "@material-ui/core/FilledInput";
import * as ControllerCuentas from "controllers/paciente/Cuenta"
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";
import { evaluarForm } from "functions/formulario";
import { useEffect } from "react";

export default function AltaPago({ id, mostrarAlta, cerrarPopUPAlta, recargarDatos }) {
    const alert = useAlert();
    const [pagoNuevo, setPagoNuevo] = React.useState({ "monto": 0 });
    const [metodoDePago, setMetodoDePago] = React.useState({ "idTipoPago": 0, "cuotas":1 });
    const [mostrarCamposTarjeta, setMostrarCamposTarjeta] = React.useState(false);
    const camposRequeridosTarjeta = [{ id: 'voucher', nombre: 'Voucher' }, { id: 'idTipoPago', nombre: 'Método de pago' },
    { id: 'cuotas', nombre: 'Cuotas' }, { id: 'emisor', nombre: 'Emisor de tarjeta' },];
    const camposRequeridos = [{ id: 'detalle', nombre: 'Concepto' }, { id: 'monto', nombre: 'Monto' }];

    const guardarDatos = async e => {
        await setPagoNuevo({
            ...pagoNuevo,
            [e.target.name]: e.target.value
        });
    };

    const guardarDatosTarjeta = async e => {
        await setMetodoDePago({
            ...metodoDePago,
            [e.target.name]: e.target.value
        });
    };


    const peticionPost = async () => {
        const validarForm = evaluarForm(pagoNuevo, camposRequeridos);
        if (!validarForm.resultado) {
            alert.error(validarForm.mensaje)
            return;
        }

        if (esTarjeta()) {
            const validarFormTarj = evaluarForm(metodoDePago, camposRequeridosTarjeta);
            if (!validarFormTarj.resultado) {
                alert.error(validarFormTarj.mensaje)
                return;
            }
        }


        const r = await ControllerCuentas.peticionPostPago(id, 
            !esTarjeta() ? pagoNuevo
            : {...pagoNuevo, "metodoDePago": metodoDePago});
        if (r.resultado) {
            cerrarPopUPAlta();
            alert.success(r.mensaje);
            recargarDatos();
        } else {
            alert.error(r.mensaje);
        }
    };

    const renderFormulario = () => {
        setMostrarCamposTarjeta(esTarjeta());
    };

    const esTarjeta = () => {
        return (metodoDePago && metodoDePago.idTipoPago && metodoDePago.idTipoPago > 0) ? true : false;
    }

    useEffect(() => {
        renderFormulario();
    }, [metodoDePago]);

    return (
        <Dialog
            maxWidth="xs"
            open={mostrarAlta}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrarPopUPAlta}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="form-dialog-title" >
                <Typography variant="h4">Confirmación de pago</Typography>
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>Concepto</FormLabel>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                    name="detalle"
                                    key="detalle"
                                    onChange={(e) => guardarDatos(e)}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Monto</FormLabel>
                            <FormControl
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="number"
                                    defaultValue="0"
                                    name="monto"
                                    key="monto"
                                    onChange={(e) => guardarDatos(e)}
                                    inputProps={{ max: 9999999999 }}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormLabel>Modalidad</FormLabel>
                        <FormControl
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                        >
                            <Select
                                labelId="modalidad"
                                variant="outlined"
                                id="idTipoPago"
                                name="idTipoPago"
                                onChange={(e) => guardarDatosTarjeta(e)}
                                placeholder="Método"
                                displayEmpty
                                defaultValue={"0"}
                            >
                                <MenuItem key="0" value={0}>Efectivo</MenuItem>
                                <MenuItem key="1" value={2}>Débito</MenuItem>
                                <MenuItem key="2" value={1}>Crédito</MenuItem>
                                <MenuItem key="3" value={3}>Prepaga</MenuItem>
                            </Select>
                        </FormControl>
                        {!mostrarCamposTarjeta ?
                            '' :
                            <>
                                <FormGroup>
                                    <FormLabel>Cuotas</FormLabel>
                                    <FormControl
                                        variant="filled"
                                        component={Box}
                                        width="100%"
                                        marginBottom="1rem!important"
                                    >
                                        <Box
                                            paddingLeft="0.75rem"
                                            paddingRight="0.75rem"
                                            component={FilledInput}
                                            autoComplete="off"
                                            type="number"
                                            defaultValue="1"
                                            name="cuotas"
                                            key="cuotas"
                                            onChange={(e) => guardarDatosTarjeta(e)}
                                            inputProps={{ max: 100, min: 1 }}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Voucher #</FormLabel>
                                    <FormControl
                                        variant="filled"
                                        component={Box}
                                        width="100%"
                                        marginBottom="1rem!important"
                                    >
                                        <Box
                                            paddingLeft="0.75rem"
                                            paddingRight="0.75rem"
                                            component={FilledInput}
                                            autoComplete="off"
                                            type="text"
                                            name="voucher"
                                            key="voucher"
                                            onChange={(e) => guardarDatosTarjeta(e)}
                                            inputProps={{ maxLength: 100 }}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Emisor de tarjeta</FormLabel>
                                    <FormControl
                                        variant="filled"
                                        component={Box}
                                        width="100%"
                                        marginBottom="1rem!important"
                                    >
                                        <Box
                                            paddingLeft="0.75rem"
                                            paddingRight="0.75rem"
                                            component={FilledInput}
                                            autoComplete="off"
                                            type="text"
                                            name="emisor"
                                            key="emisor"
                                            onChange={(e) => guardarDatosTarjeta(e)}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </FormControl>
                                </FormGroup>
                            </>
                        }
                    </Grid>

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                        <Button color="primary" variant="contained" onClick={() => peticionPost()}>
                            Confirmar
                        </Button>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}