import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Header from "components/Headers/Header.js";
import * as ControllerDashboard from 'controllers/Dashboard';
import componentStyles from "assets/theme/views/admin/dashboard.js";
import { useAlert } from "react-alert";
import Ver from "components/Tabla/Ver";
import ReactWordcloud from "react-wordcloud";
import GraficaLineas from "components/Graficas/Lineas";
import { useLayoutEffect } from "react";
import { obtenerMes } from 'functions/fechas';
import { GraficaBarras } from "components/Graficas/Barras";
import { opcionesGlobales } from "functions/graficas";
import { parseOptions } from "functions/graficas";
import { Grafica } from "components/Graficas/Grafica";
import { generarDatosGraficaLineas } from "functions/graficas";
import { generarDatosGraficaBarras } from "functions/graficas";
import Tarjeta from "components/Dashboard/Tarjeta";
import { CardContent } from "@material-ui/core";
import { AttachMoneyOutlined, CardGiftcardOutlined, Close, CreditCard, EventAvailableOutlined, FormatListNumberedOutlined, GroupAddOutlined, LocalHospitalOutlined, ShoppingCartOutlined } from "@material-ui/icons";
import ReactTimeAgo from "react-time-ago/commonjs/ReactTimeAgo";
import CierreCaja from "./popup/cierreCaja";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { useSelector } from "react-redux";
import SinRegistros from "components/Tabla/SinRegistros";
import TablaCarga from "components/Tabla/Carga";
import { GraficaTorta } from "components/Graficas/Torta";
import { generarDatosGraficaTorta } from "functions/graficas";
import { obtenerEtiquetaSexo } from "functions/general";

const useStyles = makeStyles(componentStyles);

function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.reporte : []);
  const [KPIdeudores, setKPIdeudores] = useState();
  const [KPIGenero, setKPIGenero] = useState();
  const [KPImotivosCancelacion, setKPImotivosCancelacion] = useState([]);
  const [KPIcitas, setKPIcitas] = useState(
    generarDatosGraficaLineas(["Ene", "Feb", "Mar", "Abr", "May", "Jun"],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      'Realizadas',
      'Canceladas',
    ));
  const [citasRealizadas, setCitasRealizadas] = useState([]);
  const [citasCanceladas, setCitasCanceladas] = useState([]);
  const [KPImontos, setKPImontos] = useState({
    labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun"],
    datasets: [{
      label: 'Insumos',
      data: [0, 0, 0, 0, 0, 0],
    }],
  });
  const [montoInsumos, setMontoInsumos] = useState([]);
  const [montoTratamientos, setMontoTratamientos] = useState([]);
  const [tarjCitasRealizadas, setTarjCitasRealizadas] = useState(0);
  const [tarjCitasPlanificadas, setTarjCitasPlanificadas] = useState(0);
  const [tarjTratamientos, setTarjTratamientos] = useState(0);
  const [tarjPacientes, setTarjPacientes] = useState(0);
  const [tarjIngresosTarjeta, setTarjIngresosTarjeta] = useState(0);
  const [tarjIngresosEfectivo, setTarjIngresosEfectivo] = useState(0);
  const [tarjInsumosvendidos, setTarjInsumosVendidos] = useState(0);
  const [tarjInsumosStock, setTarjInsumosStock] = useState(0);
  const [tarjInsumosSistema, setTarjInsumosSistema] = useState(0);
  const [tarjBonificaciones, setTarjBonificaciones] = useState(0);
  const [modalCierre, setModalCierre] = React.useState(false);
  const [speedDialCierre, setSpeedDialCierre] = React.useState(false);

  const cierreModal = () => {
    setModalCierre(false);
  }

  const abrirModal = () => {
    setModalCierre(true);
  }

  if (window.Chart) {
    parseOptions(Grafica, opcionesGlobales());
  }

  const obtenerKPITiempoReal = async () => {
    const r = await ControllerDashboard.peticionGetKPITiempoReal();
    if (r.resultado) {
      if (r.data && r.data.length > 0) {
        setTarjCitasRealizadas(r.data[1].cantidad);
        setTarjCitasPlanificadas(r.data[0].cantidad);
        setTarjTratamientos(r.data[9].cantidad);
        setTarjPacientes(r.data[6].cantidad);
        setTarjIngresosEfectivo(r.data[7].cantidad);
        setTarjInsumosVendidos(r.data[5].cantidad);
        setTarjInsumosStock(r.data[3].cantidad);
        setTarjInsumosSistema(r.data[4].cantidad);
        setTarjBonificaciones(r.data[2].cantidad);
        setTarjIngresosTarjeta(r.data[8].cantidad);
      }
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerKPIDeudores = async () => {
    const r = await ControllerDashboard.peticionGetKPIDeudores();
    if (r.resultado) {
      setKPIdeudores(r.data);
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerKPIGenero = async () => {
    const r = await ControllerDashboard.peticionGetKPIGenero();
    if (r.resultado) {
      setKPIGenero(
        generarDatosGraficaTorta(r.data.map(i => obtenerEtiquetaSexo(i.sexo)),
          r.data.map(i => i.cantidad),
          'Canceladas',
        ));
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerKPIMotivosCancelacion = async () => {
    const r = await ControllerDashboard.peticionGetKPIMotivosCancelacion();
    if (r.resultado) {
      setKPImotivosCancelacion(r.data.map(i => i.motivo && i.cantidad ? { text: i.motivo, value: i.cantidad } : {}));
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerCitasRealizadas = async () => {
    const r = await ControllerDashboard.peticionGetKPICitasRealizadas();
    if (r.resultado) {
      setCitasRealizadas(r.data.reverse());
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerCitasCanceladas = async () => {
    const r = await ControllerDashboard.peticionGetKPICitasCanceladas();
    if (r.resultado) {
      setCitasCanceladas(r.data.reverse());
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerMontoInsumos = async () => {
    const r = await ControllerDashboard.peticionGetKPIMontoInsumos();
    if (r.resultado) {
      setMontoInsumos(r.data.reverse());
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerMontoTratamientos = async () => {
    const r = await ControllerDashboard.peticionGetKPIMontoTratamientos();
    if (r.resultado) {
      setMontoTratamientos(r.data.reverse());
    } else {
      alert.error(r.mensaje);
    }
  };

  const actualizarGraficaCitas = () => {
    if (citasRealizadas && citasCanceladas && citasRealizadas.length > 0 && citasCanceladas.length > 0)
    setKPIcitas(
      generarDatosGraficaLineas(citasRealizadas.map(i => obtenerMes(i.mes)),
        citasRealizadas.map(i => i.cantidad),
        citasCanceladas.map(i => i.cantidad),
        'Realizadas',
        'Canceladas',
      ));
  };

  const actualizarGraficaMontos = () => {
    if (montoInsumos && montoTratamientos && montoInsumos.length > 0 && montoTratamientos.length > 0)
      setKPImontos(
        generarDatosGraficaBarras(montoTratamientos.map(i => obtenerMes(i.mes)),
          montoTratamientos.map(i => i.monto),
          montoInsumos.map(i => i.monto),
          'Tratamientos',
          'Insumos',
        ));
  };

  useLayoutEffect(() => {
    obtenerKPITiempoReal();
    obtenerKPIDeudores();
    obtenerKPIGenero();
    obtenerKPIMotivosCancelacion();
    obtenerCitasCanceladas();
    obtenerCitasRealizadas();
    obtenerMontoInsumos();
    obtenerMontoTratamientos();
  }, []);

  useLayoutEffect(() => {
    actualizarGraficaCitas();
  }, [citasCanceladas, citasRealizadas]);

  useLayoutEffect(() => {
    actualizarGraficaMontos();
  }, [montoInsumos, montoTratamientos]);

  return (
    <>
      <Header />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-10rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} xl={3} component={Box} classes={{ root: classes.gridItemRoot }}>
            <Tarjeta valor={`${tarjCitasRealizadas}/${tarjCitasPlanificadas}`} titulo={"CITAS REALIZADAS"} leyenda={`AGENDADAS`} icono={EventAvailableOutlined} />
          </Grid>
          <Grid item xs={12} xl={3} component={Box} classes={{ root: classes.gridItemRoot }}>
            <Tarjeta valor={tarjTratamientos} titulo={"TRATAMIENTOS"} leyenda={"REALIZADOS"} icono={LocalHospitalOutlined} color={"bgWarning"} />
          </Grid>
          <Grid item xs={12} xl={3} component={Box} classes={{ root: classes.gridItemRoot }}>
            <Tarjeta valor={`$${tarjIngresosTarjeta}`} titulo={"INGRESOS"} leyenda={"POR TARJETA"} icono={CreditCard} color={"bgPrimaryLight"} />
          </Grid>
          <Grid item xs={12} xl={3} component={Box} classes={{ root: classes.gridItemRoot }}>
            <Tarjeta valor={`$${tarjIngresosEfectivo}`} titulo={"INGRESOS"} leyenda={"EFECTIVO"} icono={AttachMoneyOutlined} color={"bgError"} />
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth={false}
        component={Box}
        marginTop="2rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >
            <GraficaLineas titulo={'Citas'} subtitulo={'Performance histórico'} datos={KPIcitas} tituloGrafica1={'Totales'} tituloGrafica2={'Canceladas'} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <GraficaBarras titulo={'Ventas'} subtitulo={'Haberes histórico'} datos={KPImontos} />
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="2rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} xl={3} component={Box} classes={{ root: classes.gridItemRoot }}>
            <Tarjeta valor={`+${tarjPacientes}`} titulo={"PACIENTES"} leyenda={"NUEVOS"} icono={GroupAddOutlined} color={"bgInfo"} />
          </Grid>
          <Grid item xs={12} xl={3} component={Box} classes={{ root: classes.gridItemRoot }}>
            <Tarjeta valor={`${tarjInsumosStock}/${tarjInsumosSistema}`} titulo={"INSUMOS"} leyenda={`CON STOCK`} icono={FormatListNumberedOutlined} color={"bgError"} />
          </Grid>
          <Grid item xs={12} xl={3} component={Box} classes={{ root: classes.gridItemRoot }}>
            <Tarjeta valor={`${tarjInsumosvendidos}`} titulo={"INSUMOS"} leyenda={`VENDIDOS`} icono={ShoppingCartOutlined} color={"bgInfo"} />
          </Grid>
          <Grid item xs={12} xl={3} component={Box} classes={{ root: classes.gridItemRoot }}>
            <Tarjeta valor={`$${tarjBonificaciones}`} titulo={"BONIFICACIONES"} leyenda={"REALIZADAS"} icono={CardGiftcardOutlined} color={"bgWarning"} />
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="2rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} lg={3}>
            <GraficaTorta titulo={'Género'} subtitulo={'Segregación de pacientes'} datos={KPIGenero} />
          </Grid>
          <Grid item xs={12} lg={6} >
            <Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        TOP | DEUDORES ACTUALES
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead, }}>
                        Nombre
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead, }}>
                        Deuda
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead, }}>
                        Fecha último pago
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead, }}>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!KPIdeudores ? <TablaCarga espacio={4} /> :
                      KPIdeudores.length < 1 ? <SinRegistros espacio={4} /> :
                        KPIdeudores.map(i => {
                          return (
                            <TableRow>
                              <TableCell
                                classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead, }}
                                component="th"
                                variant="head"
                                scope="row"
                              >
                                {i.paciente && i.paciente.nombre1 && i.paciente.apellido1 ? i.paciente.nombre1 + " " + i.paciente.apellido1 : 'Desconocido'}
                              </TableCell>
                              <Box
                                component={TableCell}
                                className={classes.tableCellRoot}
                                marginBottom="-2px"
                              >
                                <Box
                                  component={AttachMoney}
                                  width="1rem!important"
                                  height="1rem!important"
                                  color={theme.palette.error.main}
                                />
                                {i.monto}
                              </Box>
                              <TableCell
                                classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead, }}
                                component="th"
                                variant="head"
                                scope="row"
                              >
                                {(i.fechaUltimoPago ? <ReactTimeAgo date={Date.parse(i.fechaUltimoPago)} locale="es-AR" /> : "no hubo aún")}
                              </TableCell>
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                <Ver url={'/app/pacientes/cuenta/' + i.paciente.idPaciente} />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3} >
            <Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        MOTIVOS CANCELACIÓN
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                {!KPImotivosCancelacion || KPImotivosCancelacion && KPImotivosCancelacion.length < 1 ?
                  <Typography align="center">No se encontraron registros</Typography>
                  :
                  <div style={{ height: "16rem", width: "10rem" }}>
                    <ReactWordcloud words={KPImotivosCancelacion} options={{
                      rotations: 3,
                      rotationAngles: [0, 20, 30],
                      fontSizes: [20, 40, 50, 80],
                    }} />
                  </div>}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <CierreCaja mostrar={modalCierre} cerrar={cierreModal} valor={60} />
      {permisos && permisos.cierre &&
        <div style={{ display: 'block', padding: 0 }}>
          <SpeedDial
            ariaLabel="SpeedDial Component Demo"
            style={{
              right: '2rem',
              bottom: '5rem',
              position: 'absolute',
            }}
            icon={<SpeedDialIcon openIcon={<Close />} />}
            onClose={() => {
              setSpeedDialCierre(false);
            }}
            onOpen={() => { setSpeedDialCierre(true); }}
            open={speedDialCierre}
          >
            <SpeedDialAction
              icon={<AttachMoney />}
              key={"cerrarCaja"}
              onClick={() => {
                setSpeedDialCierre(false);
                abrirModal();
              }}
              tooltipTitle={"Cerrar Caja"}
            />
          </SpeedDial>
        </div>}

    </>
  );
}

export default Dashboard;
