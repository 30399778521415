import { queryGet, queryPost, queryDelete, queryPut } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Sistema/medicamento/";

export function peticionGetMedicamentos() {
  return queryGet(url);
}

export function peticionPostMedicamento(elemento) {
  return queryPost(url, elemento);
}

export function peticionPutMedicamento(id, elemento) {
  return queryPut(url + id, elemento);
}

export function peticionDeleteMedicamento(id) {
  return queryDelete(url  + id);
}

export function peticionGetMedicamento(id) {
  return queryGet(url + id);
}
