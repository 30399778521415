import React from 'react';
import { ArcadaSuperior } from './ArcadaSuperior';
import { ArcadaInferior } from './ArcadaInferior';
import { useLayoutEffect } from 'react';
import { Container,  ThemeProvider } from '@material-ui/core';
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({});

export function Odontograma({ confirmar, seleccionTodo }) {
  const [limpiarSuperior, setLimpiarSuperior] = React.useState(false);
  const [limpiarInferior, setLimpiarInferior] = React.useState(false);


  const limpiar = (grafico) => {
    switch (grafico) {
      case "inferior":
        setLimpiarSuperior(true);
        setLimpiarInferior(false);
        break;
      case "superior":
        setLimpiarInferior(true);
        setLimpiarSuperior(false);
        break;
      case "todo":
        setLimpiarInferior(true);
        setLimpiarSuperior(true);
        break;
      default:
        break;
    }
  }

  const seleccion = (area, grafico) => {
    limpiar(grafico);
    confirmar(area.name);
  }

  useLayoutEffect(() => {
    if (seleccionTodo)
      limpiar('todo');
  }, [seleccionTodo]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container style={{"width":"200px","height":"171px"}}>
          <ArcadaSuperior seleccion={seleccion} limpiar={limpiarSuperior} />
        </Container>
        <Container style={{"width":"200px","height":"171px"}}>
          <ArcadaInferior seleccion={seleccion} limpiar={limpiarInferior} />
        </Container>
      </ThemeProvider>
    </>
  );
}