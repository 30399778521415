import React from 'react';
import Box from "@material-ui/core/Box";
import { Grid, TextField, Paper, IconButton } from '@material-ui/core';
import { Healing, LibraryAdd, LocalHospitalOutlined, MyLocation } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useRef } from "react";

export const AltaTrabajoReserva = ({ piezas, caras, diagnosticos, tratamientos, funcionConfirmar }) => {
    const campoCara = useRef(null);
    const campoDiagnostico = useRef(null);
    const campoTratamiento = useRef(null);
    const [nuevoTrabajo, setNuevoTrabajo] = React.useState({});

    const guardarDatosTrabajo = (event, value) => {
        if (value !== "" && event.target.id !== "")
            setNuevoTrabajo({
                ...nuevoTrabajo,
                [event.target.id.split("-")[0]]: { 'id': value.id, 'label': value.label }
            });
    };


    return (
        <>
            <Paper>
                <Grid
                    container
                    component={Box}
                    padding={'0em 1em 0em 1em'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={1} lg={1}>
                        <MyLocation />
                    </Grid>
                    <Grid item xs={11} lg={6}>
                        <Autocomplete
                            id="idPieza"
                            options={piezas}
                            getOptionLabel={(i) => `${i.label}`}
                            groupBy={(i) => `${i.grupo}`}
                            freeSolo
                            onChange={guardarDatosTrabajo}
                            clearOnBlur={true}
                            renderInput={(params) => <TextField {...params} placeholder="Pieza" margin="normal" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={11} lg={5}>
                        <Autocomplete
                            id="idCara"
                            options={caras}
                            getOptionLabel={(i) => `${i.label}`}
                            onChange={guardarDatosTrabajo}
                            freeSolo
                            ref={campoCara}
                            renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" placeholder="Cara" />}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    component={Box}
                    padding={'0em 1em 0em 1em'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={1} lg={1}>
                        <LocalHospitalOutlined />
                    </Grid>
                    <Grid item xs={11} lg={11}>
                        <Autocomplete
                            id="idDiagnostico"
                            options={diagnosticos}
                            getOptionLabel={(i) => `${i.label}`}
                            onChange={guardarDatosTrabajo}
                            ref={campoDiagnostico}
                            freeSolo
                            renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" placeholder="Diagnóstico" />}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    component={Box}
                    padding={'0em 1em 0em 1em'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={1} lg={1}>
                        <Healing />
                    </Grid>
                    <Grid item xs={11} lg={11}>
                        <Autocomplete
                            id="idTratamiento"
                            name="idTratamiento"
                            options={tratamientos}
                            getOptionLabel={(i) => `${i.label}`}
                            onChange={guardarDatosTrabajo}
                            ref={campoTratamiento}
                            freeSolo
                            renderInput={(params) => <TextField {...params} size="small" margin="normal" variant="outlined" placeholder="Tratamiento" />}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    padding={'0em 1em 0em 1em'}
                    component={Box}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                    >
                        <IconButton onClick={(e) => funcionConfirmar(nuevoTrabajo)}>
                            <LibraryAdd />
                        </IconButton>
                    </Box>
                </Grid>
            </Paper>
        </>
    );
}