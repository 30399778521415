import { formatoFecha } from 'functions/fechas';

const recordatorioCuerpo = (paciente, fecha, medico, consultorio) => {
  return `Hola ${paciente.nombre1} ${paciente.apellido1}!\n
  📆 Te recordamos tu cita el día ${formatoFecha(fecha,"DD/MM/YYYY a las HH:MM")} con ${medico.nombre1} ${medico.apellido1} en ${consultorio.nombre}.\n
  Si no puedes asistir recuerda avisar siempre con antelación al ${consultorio.celular}.\n
  Dirección: ${consultorio.direccion}\n
  https://www.google.com/maps/search/?api=1&query=${consultorio.direccion.replaceAll(" ","-")}&zoom=20`;
};

const recordatorioAsunto = (consultorio) => {
  return `${consultorio.nombre} - Recordatorio de cita`;
};


export { recordatorioCuerpo, recordatorioAsunto };