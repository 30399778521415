import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogContentText, DialogTitle, Dialog, Select, MenuItem } from '@material-ui/core';
import * as ControllerPacientes from "controllers/Pacientes"
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";

export default function AltaMedicamento({ id, mostrarAlta, cerrarPopUPAlta, recargarDatos, valores }) {
    const alert = useAlert();
    let idElementoNuevo;

    const guardarDatos = async e => {
        idElementoNuevo =e.target.value;        
    };

    const peticionPost = async () => {
        cerrarPopUPAlta();
        if (idElementoNuevo != "") {
            const r = await ControllerPacientes.peticionPostPacienteMedicamentos(id, [{"idMedicamento":idElementoNuevo}]);            
            if(r.resultado){
                alert.success(r.mensaje);
                recargarDatos();
             }else{
                alert.error(r.mensaje);
             }
        } else {
            alert.error("Seleccione una opción");
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            open={mostrarAlta}
            TransitionComponent={Transicion}
            keepMounted
            onClose={cerrarPopUPAlta}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Alta de medicamento</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Seleccione la opción a agregar.
                </DialogContentText>
                <FormGroup>
                    <FormLabel>Medicamento:</FormLabel>
                    <FormControl variant="outlined" fullWidth>
                          <Select name="select" key="select"
                            onChange={(e) => guardarDatos(e)}>
                            {valores && valores.map(p => {
                              return (
                                <MenuItem value={p.idMedicamento}>{p.nombre}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                </FormGroup>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button color="secondary" variant="contained" onClick={cerrarPopUPAlta}>
                        Cerrar
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => peticionPost()} >
                        Confirmar
                    </Button>
                </Box>

            </DialogContent>
        </Dialog>
    );
}