import { queryGet, queryPost, queryDelete, queryPut } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Consultorio/";
const cantidadPagina= 10;

export function peticionGetConsultorios(pagina) {
  let paginado="";
  if(pagina)
    paginado = `?pagina=${pagina}&cantidad=${cantidadPagina}`;
  return queryGet(url  + paginado);
}

export function peticionPostConsultorio(elemento) {
  return queryPost(url, elemento);
}

export function peticionDeleteConsultorio(id) {
  return queryDelete(url  + id);
}

export function peticionPutConsultorio(id, elemento) {
  return queryPut(url  + id, elemento);
}

export function peticionGetConsultorio(id) {
  return queryGet(url + id);
}

export function peticionPostBuscar(elemento) {
  return queryPost(url + "buscar/", elemento);
}
