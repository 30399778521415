import { createStore } from 'redux';
import { useDispatch, useSelector } from "react-redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducer';
 
const persistConfig = {
  key: 'root',
  storage,
}
 
const persistedReducer = persistReducer(persistConfig, reducer);
 
const store = createStore(persistedReducer);
const persistor = persistStore(store);

export {store, persistor, useDispatch, useSelector};

