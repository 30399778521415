import { queryDeleteBody } from "functions/controllers";
import { queryPostForm } from "functions/controllers";
import { queryGet, queryPost, queryDelete, queryPut } from "functions/controllers";
const url = process.env.REACT_APP_API_URL + "Usuario/";

export function peticionGetUsuarios() {
  return queryGet(url + "?pagina=1&cantidad=999999");
}

export function peticionGetUsuariosPorTipo(tipo) {
  return queryGet(url + "tipo/" + tipo);
}

export function peticionPostUsuario(elemento) {
  return queryPost(url, elemento);
}

export function peticionDeleteUsuario(id) {
  return queryDelete(url  + id);
}

export function peticionPutUsuario(id, elemento) {
  return queryPut(url  + id, elemento);
}

export function peticionGetUsuarioAvatar(id) {
  return queryGet(url  + id + "/avatar");
}

export function peticionPutUsuarioAvatar(id, elemento) {
  return queryPut(url  + "file/" + id, elemento);
}

export function peticionPostUsuarioAvatar(id, elemento) {
  return queryPostForm(url  + id + "/avatar", elemento);
}

export function peticionGetUsuario(id) {
  return queryGet(url + id);
}

export function peticionCambioContraseña(elemento) {
  return queryPost(url + "cambiarClave", elemento);
}

export function peticionReiniciarClave(id) {
  return queryPut(url + id + "/reiniciarclave");
}

export function peticionGetEspecialidades() {
  return queryGet(url + "especialidades");
}

export function peticionDeleteUsuarioEspecialidad(id, elemento) {
  return queryDeleteBody(url + id + "/especialidad/", elemento);
}

export function peticionPostUsuarioEspecialidad(id, elemento) {
  return queryPost(url + id + "/especialidad/", elemento);
}

export function peticionDeleteUsuarioRol(id, elemento) {
  return queryDeleteBody(url + id + "/rol", elemento);
}

export function peticionPostUsuarioRol(id, elemento) {
  return queryPost(url + id + "/rol/", elemento);
}

export function peticionGetTiposUsuario() {
  return queryGet(url + "tipo");
}