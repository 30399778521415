const rolesPredefinidos = {
    todos: 0,
    administrador: 1,
    auditor: 2,
    doctor: 3,
    administrativo: 4,
    cajero: 5
};

const accesoPorRoles = {
    reserva: {
        crear: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo],
        eliminar: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo],
        modificar: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo],
        finalizar: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo, rolesPredefinidos.doctor, rolesPredefinidos.cajero],
        cancelar: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo, rolesPredefinidos.cajero],
        buscar: [rolesPredefinidos.todos]
    },
    paciente: {
        buscar: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor, rolesPredefinidos.administrativo],
        obtener: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor, rolesPredefinidos.administrativo],
        crear: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo],
        eliminar: [rolesPredefinidos.administrador, rolesPredefinidos.administrativo],
        modificar:[rolesPredefinidos.administrador, rolesPredefinidos.administrativo],
        cuenta: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.cajero, rolesPredefinidos.administrativo],
        ficha: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor, rolesPredefinidos.administrativo],
        periodontograma: [rolesPredefinidos.administrador,rolesPredefinidos.doctor, rolesPredefinidos.auditor, rolesPredefinidos.administrativo],
        odontograma: [rolesPredefinidos.administrador,rolesPredefinidos.doctor, rolesPredefinidos.administrativo],
        archivo: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor, rolesPredefinidos.administrativo],
        saldo: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.cajero, rolesPredefinidos.administrativo],
        salud: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor, rolesPredefinidos.administrativo],
        reservas: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor, rolesPredefinidos.administrativo],
    },
    cuenta: {
        bonificar:  [rolesPredefinidos.cajero],
        vender:  [rolesPredefinidos.cajero],
        anular:  [rolesPredefinidos.cajero],
        pagar:  [rolesPredefinidos.cajero],
    },
    consultorio: {
        crear: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        eliminar: [rolesPredefinidos.administrador],
        modificar: [rolesPredefinidos.administrador],
        buscar: [rolesPredefinidos.todos]
    },
    insumo: {
        crear: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        eliminar: [rolesPredefinidos.administrador],
        modificar: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo],
        buscar: [rolesPredefinidos.todos]
    },
    log: {
        listar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.administrador]
    },
    error: {
        listar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.administrador]
    },
    notificacion: {
        listar: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo],
        procesar: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo]
    },
    periodontograma: {
        listar: [rolesPredefinidos.administrador,rolesPredefinidos.doctor,rolesPredefinidos.auditor],
        crear: [rolesPredefinidos.administrador,rolesPredefinidos.doctor],
        eliminar: [rolesPredefinidos.administrador,rolesPredefinidos.doctor],
        obtener: [rolesPredefinidos.administrador,rolesPredefinidos.doctor,rolesPredefinidos.auditor],
    },
    reporte: {
        cierre: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.administrativo,rolesPredefinidos.cajero],
    },
    rol: {
        listar: [rolesPredefinidos.administrador],
        crear: [rolesPredefinidos.administrador],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.administrador],
        buscar: [rolesPredefinidos.administrador]
    },
    sistema: {
        paises: [rolesPredefinidos.todos],
        tiposDocumentacion: [rolesPredefinidos.todos],
        documentos: [rolesPredefinidos.todos],
        piezas: [rolesPredefinidos.todos],
        caras: [rolesPredefinidos.todos]
    },
    mutualista: {
        listar: [rolesPredefinidos.todos],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        crear: [rolesPredefinidos.administrador],
        modificar: [rolesPredefinidos.administrador]
    },
    emergencia: {
        listar: [rolesPredefinidos.todos],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        crear: [rolesPredefinidos.administrador],
        modificar: [rolesPredefinidos.administrador]
    },
    alergia: {
        listar: [rolesPredefinidos.todos],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        crear: [rolesPredefinidos.administrador],
        modificar: [rolesPredefinidos.administrador]
    },
    patologia: {
        listar: [rolesPredefinidos.todos],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        crear: [rolesPredefinidos.administrador],
        modificar: [rolesPredefinidos.administrador]
    },
    medicamento: {
        listar: [rolesPredefinidos.todos],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        crear: [rolesPredefinidos.administrador],
        modificar: [rolesPredefinidos.administrador]
    },
    diagnostico: {
        listar: [rolesPredefinidos.todos],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        crear: [rolesPredefinidos.administrador],
        buscar: [rolesPredefinidos.administrador]
    },
    tratamiento: {
        listar: [rolesPredefinidos.todos],
        modificar: [rolesPredefinidos.administrador,rolesPredefinidos.administrativo],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        crear: [rolesPredefinidos.administrador],
        buscar: [rolesPredefinidos.administrador]
    },
    usuario: {
        listar: [rolesPredefinidos.todos],
        modificar: [rolesPredefinidos.administrador],
        reiniciar: [rolesPredefinidos.administrador],
        eliminar: [rolesPredefinidos.administrador],
        obtener: [rolesPredefinidos.todos],
        crear: [rolesPredefinidos.administrador]
    },
    documento:{
        obtener:[rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.doctor,rolesPredefinidos.administrativo],
        subir:[rolesPredefinidos.administrador, rolesPredefinidos.administrativo],
        eliminar:[rolesPredefinidos.administrador, rolesPredefinidos.administrativo],
    },
    administracion: {
        insumos: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.administrativo, rolesPredefinidos.cajero],
        tratamientos: [rolesPredefinidos.administrador,rolesPredefinidos.auditor,rolesPredefinidos.administrativo, rolesPredefinidos.cajero],
        diagnosticos: [rolesPredefinidos.administrador,rolesPredefinidos.auditor],
    },
    configuracion: {
        patologias: [rolesPredefinidos.administrador],
        medicamentos: [rolesPredefinidos.administrador],
        alergias: [rolesPredefinidos.administrador],
        emergencias: [rolesPredefinidos.administrador],
        mutualistas: [rolesPredefinidos.administrador],
        consultorios: [rolesPredefinidos.administrador],
        registros: [rolesPredefinidos.administrador,rolesPredefinidos.auditor],
    },
};

export { rolesPredefinidos, accesoPorRoles };