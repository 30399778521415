import React, { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useAlert } from "react-alert";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import * as ControllerInsumos from 'controllers/Insumos';
import MenuTabla from "components/Tabla/MenuTabla";
import Historial from "components/Tabla/Historial";
import AlertaDialogo from "components/Popups/AlertaDialogo";
import { useSelector } from "react-redux";
import Buscador from "components/Tabla/Buscador";
import TablaCarga from "components/Tabla/Carga";
import SinRegistros from "components/Tabla/SinRegistros";
import { convertirTimezone } from "functions/fechas";
import { acortarTexto } from "functions/formulario";



const useStyles = makeStyles(componentStyles);

const Insumos = () => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [insumos, setInsumos] = React.useState();
  const [pagina, setPagina] = React.useState(1);
  const [cantidadPaginas, setCantidadPaginas] = React.useState(0);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [idEliminar, setIdEliminar] = React.useState();
  const permisos = useSelector((state) => state.usuario ? state.usuario.permisos.insumo : []);

  const peticionDelete = async (id) => {
    cerrarModalEliminar();
    if (id) {
      const r = await ControllerInsumos.peticionDeleteInsumo(id);
      if (r.resultado) {
        setInsumos(insumos.filter(i => i.idInsumo !== id));
        alert.success(r.mensaje)
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  const cambioPagina = (a, b) => {
    setPagina(b)
  }

  const eliminarRegistro = (id) => {
    setIdEliminar(id);
    setModalEliminar(true);
  }

  const cerrarModalEliminar = () => {
    setIdEliminar();
    setModalEliminar(false);
  }

  const itemsMenu = [{ nombre: "Modificar", "href": "/app/insumos/insumo/", "permiso": permisos && permisos.modificar },
  { nombre: "Eliminar", "funcion": eliminarRegistro, "permiso": permisos && permisos.eliminar }];

  const obtenerInsumos = async (paginaIngresada) => {
    const r = await ControllerInsumos.peticionGetInsumos(paginaIngresada ? paginaIngresada : pagina);
    if (r.resultado) {
      setInsumos(r.data.registros);
      setCantidadPaginas(r.data.paginado.cantidadDePaginas);
    } else {
      alert.error(r.mensaje);
    }
  }

  const buscarPorNombre = async (valor) => {
    const r = await ControllerInsumos.peticionPostBuscar(valor);
    if (!r.resultado) {
      alert.error(r.mensaje);
      return;
    }
    setInsumos(r.data);
    setCantidadPaginas(1);
  }

  useLayoutEffect(() => {
    obtenerInsumos(pagina);
  }, [pagina]);

  return (
    <>
      <Header />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>


          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >

                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    marginBottom="0!important"
                  >
                    Listado
                  </Box>
                </Grid>
                {permisos && permisos.crear &&
                  <Grid item xs="auto">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        href="/app/insumos/insumo"
                      >
                        Nuevo
                      </Button>
                    </Box>
                  </Grid>}
              </Grid>
            }
            classes={{ root: classes.cardHeaderRoot }}
          ></CardHeader>
          <Buscador etiqueta="Nombre" buscar={buscarPorNombre} reiniciar={() => obtenerInsumos()} />
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Nombre
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Categoría
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Precio
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Stock
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                    Descripción
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!insumos ? <TablaCarga espacio={7} /> :
                  insumos.length < 1 ? <SinRegistros espacio={7} /> :
                    insumos.map(insumo => {
                    return (
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          align="right">
                          <MenuTabla id={insumo.idInsumo} items={itemsMenu} />
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {insumo.nombre}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {insumo.categoria.nombre}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {'$ '}{insumo.precio}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {insumo.stock}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {acortarTexto(insumo.descripcion,80)}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Historial creacion={{ fecha: convertirTimezone(insumo.fechaRegistro,'GMT-3') }} modificacion={{ fecha: convertirTimezone(insumo.fechaUltimaModificacion,'GMT-3') }} />
                        </TableCell>
                      </TableRow>
                    )
                  })},
              </TableBody>
            </Box>
          </TableContainer>
          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination count={cantidadPaginas} onChange={cambioPagina} color="primary" variant="outlined" />
          </Box>
        </Card>

      </Container>

      {modalEliminar && <AlertaDialogo titulo={"Seguro desea eliminar el insumo seleccionado?"} id={idEliminar} funcionConfirmar={peticionDelete} abierto={modalEliminar} funcionCerrar={cerrarModalEliminar} />}
    </>
  );
};

export default Insumos;
