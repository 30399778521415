import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import componentStyles from "assets/theme/views/admin/profile.js";
import * as ControllerPatologias from 'controllers/Patologias';
import { useAlert } from "react-alert";
import { evaluarForm } from "functions/formulario";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles(componentStyles);

function Patologia() {
  let { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const history = useHistory();
  const [patologia, setPatologia] = React.useState([]);
  const [patologiaActualizado, setPatologiaActualizado] = React.useState([]);
  const camposRequeridos = [{ id: 'nombre', nombre: 'Nombre' },
  { id: 'descripcion', nombre: 'Descripción' },];

  const peticion = async (tipo) => {
    const validarForm = evaluarForm(patologiaActualizado, camposRequeridos);
    let r = { resultado: false };
    if (validarForm.resultado) {
      switch (tipo) {
        case "post":
          r = await ControllerPatologias.peticionPostPatologia(patologiaActualizado);
          break;
        case "put":
          r = await ControllerPatologias.peticionPutPatologia(id, patologiaActualizado);
          break;
        default:
          break;
      }
      if (r.resultado) {
        alert.success(r.mensaje);
        history.push("/app/patologias/listado");
      } else {
        alert.error(r.mensaje);
      }
      return;
    }
    alert.error(validarForm.mensaje);
  }

  const guardarDatos = async e => {
    await setPatologiaActualizado({
      ...patologiaActualizado,
      [e.target.name]: e.target.value
    });
  };

  async function obtenerObjeto() {
    if (id) {
      const r = await ControllerPatologias.peticionGetPatologia(id);
      if (r.resultado) {
        setPatologia(r.data);
        setPatologiaActualizado(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  }

  useEffect(async () => {
    obtenerObjeto();
  }, []);

  return (
    <>
      <UserHeader />
      
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>

          <Grid
            item
            xs={12}
            xl={8}
            justifyContent="center"
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Info de patologia
                      </Box>
                    </Grid>

                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Nombre*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="nombre"
                            key={patologia ? patologia.nombre : 'patologia.nombre'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={patologia ? patologia.nombre : ''}
                            inputProps={{maxLength: 30}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Descripción*</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            name="descripcion"
                            key={patologia ? patologia.descripcion : 'patologia.descripcion'}
                            onChange={(e) => guardarDatos(e)}
                            defaultValue={patologia ? patologia.descripcion : ''}
                            inputProps={{maxLength: 500}}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/patologias/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => { id ? peticion("put") : peticion("post") }}
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default Patologia;
