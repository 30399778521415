import React from "react";
import Box from "@material-ui/core/Box";
import { useAlert } from "react-alert";
import * as ControllerCuenta from 'controllers/paciente/Cuenta';
import { useLayoutEffect } from "react";

const InfoSaldo = ({ paciente }) => {
    const alert = useAlert();
    const [saldo, setSaldo] = React.useState(0);

    const obtenerSaldo = async () => {
        if (paciente && paciente.idPaciente) {
            const r = await ControllerCuenta.peticionGetSaldo(paciente.idPaciente);
            if (r.resultado) {
                if (r.data && r.data.monto)
                    setSaldo(r.data.monto);
            } else {
                alert.error("Error obteniendo saldo: " + r.mensaje);
            }
        }
    }

    useLayoutEffect(() => {
        if (paciente && paciente.idPaciente) {
            obtenerSaldo();
        }
    }, [])

    return (
        <>
            <Box
                component="p"
                fontWeight="300"
                lineHeight="1.5"
                fontSize="1rem"
                textAlign="center"
            >
                <strong>Deuda actual: </strong>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center">
                <h3 style={{ color: `${saldo > 0 ? 'red' : 'green'}` }}>{saldo ? `$ ${saldo}` : '-'}</h3>
            </Box>
        </>
    );
};

export default InfoSaldo;