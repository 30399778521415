import React, { useState } from "react";
import FilledInput from "@material-ui/core/FilledInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogContentText, DialogTitle, Dialog, Typography, InputAdornment, IconButton, Grid } from '@material-ui/core';
import * as ControllerUsuarios from "controllers/Usuarios"
import ValidacionForm from "components/Mensaje/ValidacionForm";
import { encriptarSHA1 } from "controllers/Autenticacion";
import { useAlert } from "react-alert";
import { Transicion } from "components/Efectos/Transicion";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import * as ControllerAutenticacion from 'controllers/Autenticacion';
import { borrarBrowserDB } from "functions/browserDB";

export default function CambiarClave({ mostrarCambiarClave, cerrar }) {
    const alert = useAlert();
    const dispatch = useDispatch();
    const [mensajeValidacion, setMensajeValidacion] = useState();
    const [mostrarClaveActual, setMostrarClaveActual] = useState(false);
    const [mostrarClaveNueva, setMostrarClaveNueva] = useState(false);
    const [mostrarClaveRepetida, setMostrarClaveRepetida] = useState(false);
    const claveExpirada = useSelector((state) => state.sesion.forzarCambioDeClave);
    const [claves, setClaves] = useState({
        claveRepetida: '',
        claveNueva: '',
        claveActual: ''
    });

    const visualizacionPassword = (campo) => {
        switch (campo) {
            case 'claveRepetida':
                setMostrarClaveRepetida(!mostrarClaveRepetida);
                break;
            case 'claveNueva':
                setMostrarClaveNueva(!mostrarClaveNueva);
                break;
            case 'claveActual':
                setMostrarClaveActual(!mostrarClaveActual);
                break;
            default:
                break;
        }
    }

    const guardarDatos = (e) => {
        setClaves({
            ...claves,
            [e.target.name]: e.target.value
        })
    };

    const validarClaveNueva = () => {
        if (claves.claveNueva != claves.claveRepetida)
            return { resultado: false, mensaje: "Las contraseñas nuevas ingresadas no coinciden" }
        if (claves.claveNueva.length < 6)
            return { resultado: false, mensaje: "La contraseña nueva debe ser de 6 o más caracteres" }
        if (claves.claveActual === claves.claveNueva)
            return { resultado: false, mensaje: "La contraseña nueva no puede coincidir con la actual" }
        return { resultado: true, mensaje: "" }
    }

    const enviarCambioClave = async () => {
        setMensajeValidacion("");
        const resultadoValidacion = validarClaveNueva();
        if (!resultadoValidacion.resultado) {
            setMensajeValidacion(resultadoValidacion.mensaje);
            return;
        }
        const r = await ControllerUsuarios.peticionCambioContraseña({
            "claveNueva": claves.claveNueva,
            "claveActual": encriptarSHA1(claves.claveActual)
        });
        if (r.resultado) {
            dispatch({ "type": "CAMBIO_CLAVE" });
            alert.success(r.mensaje);
            cerrar();
        } else {
            alert.error(r.mensaje);
        }
    };

    async function cerrarSesion() {
        const r = await ControllerAutenticacion.peticionCerrarSesion();
        borrarBrowserDB();
        dispatch({ "type": "LOGOUT" });
        alert.success("Sesión cerrada correctamente")
    }

    return (
        <Dialog
            maxWidth="xs"
            open={mostrarCambiarClave}
            TransitionComponent={Transicion}
            keepMounted
            onClose={!claveExpirada && cerrar}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title"><Typography variant="h3">Cambio de contraseña {claveExpirada && 'obligatorio'}</Typography></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Ingrese los datos solicitados para actualizarla.
                </DialogContentText>
                <FormGroup>
                    <ValidacionForm mensajeValidacion={mensajeValidacion} />
                </FormGroup>
                <Grid container style={{ "marginTop": "2rem", "justifyContent": "center" }}>
                    <FormGroup>
                        <FormLabel>Contraseña actual:</FormLabel>
                        <FilledInput
                            inputProps={{ maxLength: 100 }}
                            onChange={guardarDatos}
                            autoComplete="off"
                            name="claveActual"
                            key="claveActual"
                            type={mostrarClaveActual ? "text" : "password"}
                            placeholder="Contraseña actual"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Visualizar contraseña"
                                        onClick={() => visualizacionPassword('claveActual')}
                                        edge="end"
                                    >
                                        {mostrarClaveActual ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Contraseña nueva:</FormLabel>
                        <FilledInput
                            onChange={guardarDatos}
                            autoComplete="off"
                            name="claveNueva"
                            key="claveNueva"
                            inputProps={{maxLength: 100}}
                            type={mostrarClaveNueva ? "text" : "password"}
                            placeholder="Nueva cotraseña"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Visualizar contraseña"
                                        onClick={() => visualizacionPassword('claveNueva')}
                                        edge="end"
                                    >
                                        {mostrarClaveNueva ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Repetir nueva contraseña:</FormLabel>
                        <FilledInput
                            onChange={guardarDatos}
                            inputProps={{maxLength: 100}}
                            autoComplete="off"
                            name="claveRepetida"
                            key="claveRepetida"
                            type={mostrarClaveRepetida ? "text" : "password"}
                            placeholder="Repetir nueva cotraseña"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Visualizar contraseña"
                                        onClick={() => visualizacionPassword('claveRepetida')}
                                        edge="end"
                                    >
                                        {mostrarClaveRepetida ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormGroup>
                </Grid>
                <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button color="secondary" variant="contained" onClick={!claveExpirada ? cerrar : cerrarSesion}>
                        {!claveExpirada ? "Cerrar" : "Cerrar sesión"}
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => enviarCambioClave()} >
                        Confirmar
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}